import { Box, Checkbox, FormControlLabel, FormGroup, FormLabel, SwipeableDrawer, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { track, useContent, useUpdateSmsPreferenceStatus } from '../logic';
import { LoadingButton } from '@mui/lab';
import { SmsPreferenceStatus } from '../types';
import { Help } from './shared';

export function SmsPreferenceStatusPrompt({ SmsPreferenceStatus, orderKey }: {
  orderKey: string;
  SmsPreferenceStatus: SmsPreferenceStatus;
}) {
  const drawerHeight = 80
  let { search } = useLocation()
  const { ClosingsText } = useContent()

  const [open, setOpen] = useState(true)
  const [consentState, setConsentState] = useState({
    phoneNumber: SmsPreferenceStatus.phoneNumber || '',
    consent: SmsPreferenceStatus.consent ?? true,
  });

  const { loading, error, refresh } = useUpdateSmsPreferenceStatus({ orderKey, search, consentState})

  useEffect(() => {
    if (error) {
      track('error', 'useUpdateSmsPreferenceStatus', {}, {
        message: 'error saving sms prefs',
        component: 'SmsPreferenceStatusPrompt',
        error: JSON.stringify(error),
      })
    }
  }, [error])

  const submitCommPreferences = (e: any) => {
    e.preventDefault()
    refresh()
    setOpen(false)
  }

  const handleCommSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentState((prev) => ({
      ...prev,
      consent: event.target.checked,
    }))
  };

  return <SwipeableDrawer
    anchor='bottom'
    open={open}
    // Required callbacks
    onOpen={() => console.log('SmsPreferenceStatusPrompt: onOpen')}
    onClose={() => console.log('SmsPreferenceStatusPrompt: onClose')}
    sx={{
      '.MuiPaper-root': {
        height: `calc(100% - ${drawerHeight + 5}px)`,
        borderRadius: '30px 30px 0 0',
      }
    }}
  >
    <form onSubmit={submitCommPreferences}>
      <Box sx={{
        height: '100%', maxWidth: '400px',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        flexDirection: 'column',
        // margin to prevent hiding content if content gets too tall for drawer height
        m: `${drawerHeight + 5}px auto`,
        pl: 1, pr: 1
      }}>
        <Box sx={{ p: 2, mt: 3, mb: 3, ml: 1, mr: 1 }}>
          <FormLabel sx={{ fontSize: '20px' }}>SMS Settings</FormLabel>
          <FormGroup sx={{ mt: 3 }}>
            <FormControlLabel
              control={
                <Checkbox checked={consentState.consent} onChange={handleCommSettingsChange}/>
              }
              label={<Typography>I'd like to receive text message updates about my {ClosingsText} at {SmsPreferenceStatus.phoneNumber}</Typography>}
            />
          </FormGroup>
        </Box>
        <LoadingButton
          loading={loading}
          variant="contained" sx={{ width: '220px', mb: 6 }}
          type="submit"
        >
          Continue
        </LoadingButton>
      </Box>
    </form>
    <Help />
  </SwipeableDrawer>
}
