import { TrackJS } from 'trackjs'

// TODO: now that GA4 is removed, these args could be normalized. Limiting scope
// for now since it is release day.
export function track(action: string, label='', options:  object={}, customData: object={}) {
  const urlParams = new URLSearchParams(window.location.search);
  const role = urlParams.get('role');

  const event = {
    action,
    label,
    role,
    ...options,
    ...customData,
  }

  console.log('[ErrorTracking] track()', event)

  if (action === 'error' && TrackJS.isInstalled()) {
    TrackJS.track(event)
  }
}
