import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import signingHelper from '../../../assets/signingHelper.gif'
import { HelpButton } from "./Help";


export function SignatureHelp({ open, setOpen, isSmall }: {
  open: boolean;
  setOpen: Function;
  isSmall: boolean
}) {
  return (
    <Dialog
      open={open}
      fullScreen={isSmall ? true : false}
      onClose={() => {
        setOpen(false)
      }}
      sx={{
        height: '100%',
        padding: { xs: '8px' },
        '& .MuiDialog-paper': { borderRadius: '20px', height: '100%' }
      }}
      fullWidth={true}
      maxWidth='xl'
    >
      <DialogTitle sx={{ pb: 1 }}>E-signing help</DialogTitle>
      <DialogContent>
        <Typography
          variant='h6'
          sx={{
            color: 'primary.main', fontWeight: 600,
            fontSize: { xs: '1rem', sm: '1.25rem' },
          }}
        >Option 1</Typography>
        <List sx={{ pt: 0 }}>
          <ListItem sx={{ pb: 0, pt: '0px' }}>
            <ListItemText
              primary='Click "GET STARTED"'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
          <ListItem sx={{ pb: 0, pt: '0px' }}>
            <ListItemText
              primary='Wait for auto-scrolling, follow prompts'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
          <ListItem sx={{ pb: 0, pt: '0px' }}>
            <ListItemText
              primary='Click "COMPLETE"'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        </List>
        <Typography
          variant='h6'
          sx={{
            color: 'primary.main', fontWeight: 600, mt: 1,
            fontSize: { xs: '1rem', sm: '1.25rem' },
          }}
        >Option 2</Typography>
        <List sx={{ pt: 0 }}>
          <ListItem sx={{ pb: 0, pt: '0px' }}>
            <ListItemText
              primary='Scroll through your document'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
          <ListItem sx={{ pb: 0, pt: '0px' }}>
            <ListItemText
              primary='Click all highlighted boxes'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
          <ListItem sx={{ pb: 0, pt: '0px' }}>
            <ListItemText
              primary='Click "COMPLETE"'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
          <ListItem sx={{ justifyContent: 'center', mt: 1 }}>
            <Box
              src={signingHelper}
              alt='how to complete your e-signing'
              component='img'
              sx={{
                border: '2px solid',
                borderColor: 'primary.main',
                width: '100%',
                maxWidth: { xs: '265px', sm: '400px'}
              }}
            />
          </ListItem>
        </List>

        <Box sx={{ mt: 1, p: 1,  borderRadius: '10px', backgroundColor: '#F3F3F3'}}>
          <Typography
            variant='h6'
            color='error'
            sx={{
              fontWeight: 600, display: 'flex', alignItems: 'center',
              fontSize: { xs: '1rem', sm: '1.25rem' },
            }}

          >
            <ErrorOutlineIcon fontSize='medium' color='error' sx={{ mr: '4px', }} />
            Common Issues
          </Typography>
          <List sx={{ pt: 0 }}>
            <ListItem sx={{ pt: 0 }}>
              <ListItemText
                primary='The signing page is blank'
                primaryTypographyProps={{ sx: { mb: 1 } }}
                secondary='Try using a different browser or enable third party cookies in your browser settings and reload the page.'
                // secondaryTypographyProps={{ sx: { ml: 1 } }}
              />
            </ListItem>
          </List>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          alignItems: 'center',
          mb: 1, mr: 1,
        }}
      >
        <HelpButton />
        <Button
          onClick={() => setOpen(!open)}
          variant='contained'
          sx={{
            minWidth: '136px',
            ml: 2,
          }}
        >Close</Button>
      </DialogActions>
    </Dialog>
  )
}
