import { useEffect } from 'react'
import { useOrganization } from '../../components/OrganizationProvider'

/**
 * Updates document.title for the given pageTitle when non-blank.
 * Prepends the org's display name.
 */
export function usePageTitle(pageTitle: string | undefined) {
  const org = useOrganization()
  
  useEffect(() => {
    if (pageTitle) {
      const title = `${org.CompanyDisplayName ?? ''} - ${pageTitle}`
      document.title = title
    }
  }, [pageTitle, org])
}