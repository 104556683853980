import {
  Box,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  Typography
} from '@mui/material'

import { LoadingScreen } from './LoadingScreen'
import { useContent } from '../../logic'
// import { GestureOutlined, ModeEditOutlineOutlined, ThumbUpAltOutlined } from '@mui/icons-material'

export function LoadingSignatureIframe() {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100% '}}>
      <Box>
        <Box sx={{ ml: -3, mr: -3 }}>
          <LoadingScreen />
        </Box>
        <Typography variant='h6' sx={{ mt: 3, mb: 2 }} >Your document is being prepared for e-signing...</Typography>

        {/* TODO: hide for now and assess this for accuracy with dropbox sign. We don't know the
            signing type yet when we're polling for the signing so the isDropboxSigning
            check here is premature.
         */}
        {/* {!isDropboxSigning && <>
          <Typography variant='subtitle1' sx={{ mb: 1 }} >Complete signatures in a 3-step process:</Typography>
          <List sx={{ maxWidth: '350px' }}>
            <ListItem >
              <ListItemIcon>
                <GestureOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Select your signature"
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <ThumbUpAltOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Agree to e-signature terms &amp; conditions"
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
            <ListItem >
              <ListItemIcon>
                <ModeEditOutlineOutlined />
              </ListItemIcon>
              <ListItemText
                primary="Complete your signing"
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
          </List>
        </>} */}

      </Box>
    </Box>
  )
}


// Signing dialog closed by user. Show this when polling to see if they completed their signing
export function LoadingSigningStatuses() {
  const { TaskText } = useContent()

  return (
    <>
      <Box sx={{}}>
        <LoadingScreen />
      </Box>
      <Typography variant='h6' sx={{ mt: 3, mb: 2 }} >We are checking the status of your {TaskText}...</Typography>
    </>
  )
}
