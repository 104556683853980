import { useEffect, useState } from 'react';
import set from 'lodash/set';
import { useOrderDataContext } from '../OrderDataProvider';
import { track, useContent, useGetNotifications } from '../../logic';
import { GetOrderResponse, PortalNotificationReference } from '../../types';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TimelineItem, TimelineSeparator, Timeline, TimelineConnector, TimelineDot, TimelineContent } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CloseIcon from '@mui/icons-material/Close';
import { getNotificationsInfo } from '../../utils';
import { ChevronRight } from '@mui/icons-material';
import { LinkWithSearchParams } from './LinkWithSearchParams';

const notificationsPollingDelay = 3 * 1000;
const defaultNotificationsPollMax = 8
const addEventInterval = 5000


export const usePollNotifications = () => {
  const [isPolling, setIsPolling] = useState(false)
  const [pollCount, setPollCount] = useState(0);
  const [lastPoll, setLastPoll] = useState(0)
  const { setGlobalOrderData } = useOrderDataContext();
  const { closingId } = useParams();
  const { search } = useLocation();
  const { data: notifications, refresh, error, loading, resetState } = useGetNotifications(closingId ?? '', search);

  function resetPolling() {
    setIsPolling(false)
    setPollCount(0)
    setLastPoll(0)
    resetState({ loading: false })
  }

  function pollNotifications() {
    const now = Date.now()
    const elapsed = now - lastPoll
    const remainingDelay = notificationsPollingDelay - elapsed
    const pollingMaxReached = pollCount >= defaultNotificationsPollMax

    if (pollingMaxReached) {
      resetPolling()
    } else {
      setTimeout(() => {
        refresh()
        setLastPoll(Date.now())
        setPollCount((prevCount) => prevCount + 1)
      }, Math.max(remainingDelay, 0))
    }
  }


  useEffect(() => {
    if (isPolling && !loading) {
      pollNotifications()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isPolling]);

  useEffect(() => {
    if (notifications && !loading) {
      setGlobalOrderData((prev: GetOrderResponse) => {
        const globalOrderData = { ...prev };
        set(globalOrderData, 'Notifications', notifications);
        return globalOrderData;
      });
    }

  }, [loading, notifications, setGlobalOrderData]);


  if (error) {
    track('error', 'useGetNotifications', {}, {
      message: JSON.stringify(error),
      component: 'usePollNotifications',
    });
  }

  return { isPolling, setIsPolling, pollCount, resetPolling, notifications }
};


export const useNotificationsDialog = () => {
  const { closingId, id } = useParams();

  const { isPolling, setIsPolling, resetPolling, notifications } = usePollNotifications();
  const [open, setOpen] = useState(false);
  const theme = useTheme() as any
  const primaryColor = theme.palette.primary.main
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const uiContent = useContent()
  const { TaskText, TasksText } = uiContent
  const [eventCount, setEventCount] = useState(0)
  const { globalOrderData } = useOrderDataContext()

  // IMPROVE: there's some dupe code here with NotificationPagination. Could be pulled into a hook.
  const notificationsRefs = notifications || globalOrderData?.Notifications || []
  const notificationsInfo = getNotificationsInfo(notificationsRefs, uiContent)
  const incompleteTasks: PortalNotificationReference[] = notificationsInfo.incompleteTasks.filter((task) => task.NotificationId !== id)
  const hasIncompleteTask = incompleteTasks.length > 0
  const mostRecentIncompleteTask = incompleteTasks[0]

  const timelineEvents = [
    {
      title: 'Sending...',
      description: `Your ${TaskText} details are being sent to our system`,
      icon: <SendIcon />,
    },
    {
      title: 'Processing...',
      description: `We are checking your ${TaskText} details`,
      icon: <PlaylistAddCheckIcon />,

      // why doesn't this one work?
      // icon: <ChecklistRtlIcon />,
    },
    {
      title: 'Still processing...',
      description: 'Thank you for your patience!',
      icon: <PlaylistAddCheckIcon />,
    },
    {
      title: 'Searching...',
      description: `Checking for new ${TasksText}`,
      icon: <ManageSearchIcon />,
    },
    {
      title: `${hasIncompleteTask ? 'Sending...' : 'Finishing up...'}`,
      description: `${hasIncompleteTask ? `Another ${TaskText} is on its way` : `Your ${TasksText} are complete!`}`,
      icon: <AssignmentReturnIcon />,
    },
  ];


  const handleClose = () => {
    setOpen(false);
    setIsPolling(false);
    resetPolling();
  };

  const handleOpen = () => {
    setOpen(true);
    setIsPolling(true);
  }

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setEventCount((prev) => {
          if (prev < timelineEvents.length) {
            return prev + 1
          } else {
            clearInterval(timer)
            return prev
          }
        })
      }, addEventInterval)
      return () => clearInterval(timer)
    } else {
      setEventCount(0)
    }
  }, [open, timelineEvents.length])


  const showActionButton = (eventCount === timelineEvents.length) && !isPolling

  return {
    pollNotificationsDialog: (
      <>
        {/* TODO: adjust dialog width smaller on big screens. Also make the TimelineConnectors longer on tall screens  */}
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen={isSmall ? true : false}
          disableEscapeKeyDown={true}
          sx={{
            height: '100%',
            padding: { xs: '8px' },
            '& .MuiDialog-paper': { borderRadius: '20px', height: '100%' }
          }}
          fullWidth={true}
          maxWidth='xl'
        >
          <DialogTitle sx={{
            'borderBottom': `1px solid ${primaryColor}`,
            display: 'flex', alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            Processing Your {TaskText}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Timeline position="alternate" sx={{ pt: 2 }}>
              {timelineEvents.map((event, index) => {
                if (index > eventCount ) {
                  return null;
                } else {
                  return (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                          {index === eventCount ? <CircularProgress sx={{ color: 'white' }} size={24}/> : event.icon}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography sx={{ fontWeight: 'bold' }} component="span">
                          {event.title}
                        </Typography>
                        <Typography variant="body2">{event.description}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )
                }
              })}
            </Timeline>
            {showActionButton && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {hasIncompleteTask ? (
                  <Button
                    fullWidth={true}
                    variant="contained"
                    startIcon={<Box sx={{flex: 1, ml: 2}}></Box>}
                    endIcon={<ChevronRight/>}
                    component={LinkWithSearchParams}
                    to={`/closings/${closingId}/notifications/${mostRecentIncompleteTask?.NotificationId}`}
                    sx={{
                      mt: 1, maxWidth: '300px', justifyContent: 'space-between',
                      '&:hover': { backgroundColor: 'primary.dark', color: 'white' },
                    }}
                  >
                    Go to your next {TaskText}
                  </Button>
                ) : (
                  <Button
                    onClick={handleClose}
                    fullWidth={true}
                    variant="contained"
                    startIcon={<Box sx={{flex: 1, ml: 2}}></Box>}
                    endIcon={<ChevronRight/>}
                    sx={{
                      mt: 1, maxWidth: '300px', justifyContent: 'space-between',
                      '&:hover': { backgroundColor: 'primary.dark', color: 'white' },
                    }}
                  >
                    Close
                  </Button>
                )}
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </>
    ),
    handleOpen
  };
};
