import { Box, Button, Card, IconButton, SvgIcon, Typography, useTheme } from '@mui/material'
import { LogicAddress, PortalOrder } from '../../types'
import { CalendarEvent, formatClosingDate, getCalEndDate, getCalStartDate, toTitleCase } from '../../utils'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ReactComponent as Calendar } from '../../../assets/calendar.svg';
import { AddToCalendar, AddToCalendarDialog } from './AddToCalendar';
import { ADDRESS_CHARS_REGEX } from './ClosingHeader';
import { useOrderDataContext } from '../OrderDataProvider';
import park from '../../../assets/pretty-pretty-park.svg';
import { SanitizedHtml } from './SanitizedHtml';
import { useContent } from '../../logic';


export function ClosingAddressUi({ address, hasFullAddress, hasGranularAddress }: {
	address: LogicAddress;
  hasGranularAddress: boolean;
  hasFullAddress: boolean;
}) {
  if (!hasFullAddress && !hasGranularAddress) {
    return <Typography align="left" fontWeight={400} sx={{ fontStyle: 'italic' }}>Address to be determined</Typography>
  }

  return (
    <Typography variant="body2" fontWeight={400} align="left">
      { hasGranularAddress ?
        (<>
          {address.Street}
          <br/>
          {`${address.City}, ${address.State} ${address.Zip}`}
        </>) :
        address.Full.split('\n').map((line, index) => ((
          <span key={index}>
            {line}
            <br/>
          </span>
        )))
      }
    </Typography>
  )
}

export function ClosingTime({ order }: {
	order: PortalOrder;
}) {
  const time = formatClosingDate({order})

  if (!time) {
    return (
      <Typography align="left" sx={{ fontStyle: 'italic' }}>Date to be determined</Typography>
    )
  }
  return (
    <Typography align="left" fontSize="16px" sx={{ fontWeight: {xs: 400, sm: 700 }}}>
      {time}
    </Typography>
  )
}


export function ClosingDetailsHeader ({ order, closingDetailsExpanded, setClosingDetailsExpanded, hasParseableTime, hasGranularAddress, hasFullAddress, time, event, setAddToCalendarOpen, addToCalendarOpen}: {
  order: PortalOrder;
  closingDetailsExpanded: boolean;
  setClosingDetailsExpanded: Function;
  hasParseableTime: boolean;
  hasGranularAddress: boolean;
  hasFullAddress: boolean;
  time: string;
  event: CalendarEvent;
  setAddToCalendarOpen: Function;
  addToCalendarOpen: boolean;
}) {
  const theme = useTheme() as any
  const primaryColor = theme.palette.primary.main
  const darkColor = theme.palette.primary.dark
  const { SigningDetailsText } = useContent()

  const closingAddress = order.ClosingAddress
  const allClosingDetailsHTML = order.ClosingDetails?.AllClosingDetailsHTML
  const hasAnyDetails = (hasFullAddress || hasGranularAddress) || time || allClosingDetailsHTML
  const expanded = hasAnyDetails && closingDetailsExpanded

  return (
    <>
      <Box sx={{ mt: 2, borderTop: expanded ? '1px solid #F3F3F3' : '', ml: -2, mr: -2 }}>
        <Box
          sx={{
            display: expanded ? 'flex' : 'none',
            justifyContent: 'space-between', mt: 3, width: '76%',
            textAlign: 'center', ml: 'auto', mr: 'auto',
          }}
        >
          <Box sx={{
            display: 'flex', flexDirection: 'column', alignItems: 'left',
            '.sanitized-html': { textAlign: 'left' }
          }}>
            <Typography align="left" sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '22px', mb: 1 }}>
              {toTitleCase(SigningDetailsText)}
            </Typography>
            {allClosingDetailsHTML ?
              <SanitizedHtml html={allClosingDetailsHTML} /> :
              <>
                <ClosingTime order={order} />
                <ClosingAddressUi address={closingAddress} hasGranularAddress={hasGranularAddress} hasFullAddress={hasFullAddress} />
              </>
            }
          </Box>
          { (hasParseableTime && !allClosingDetailsHTML) ?
            <IconButton
              onClick={() => setAddToCalendarOpen(true)}
              sx={{
                '&:hover': {
                  backgroundColor: 'initial',
                  svg: {
                    fill: darkColor
                  }
                }
              }}
            >
              <SvgIcon component={Calendar} viewBox="1 0 41 41" sx={{
                fill: primaryColor, width: '52px', height: '41px',
                '.plus': { fill: darkColor }
              }}/>
            </IconButton> : ''
          }
        </Box>
        <Box sx={{ height: '90px', display: 'flex' }}>
          {hasAnyDetails ?
            <Button
              endIcon={closingDetailsExpanded ? <ExpandLess/> : <ExpandMore/>}
              sx={{
                width: '80%', height: '40px', bgcolor: 'white', margin: 'auto',
                borderRadius: '100px', display: 'flex', justifyContent: 'space-between',
                '&:hover': { bgcolor: 'white' },
                '.MuiButton-endIcon': { color: 'primary.main', flex: 1, display: 'flex', justifyContent: 'right' },
              }}
              onClick={() => setClosingDetailsExpanded(!closingDetailsExpanded)} variant="outlined"
            >
              <Box sx={{ flex: 1 }}></Box>
              <Box sx={{  }}>{toTitleCase(SigningDetailsText)}</Box>
            </Button> : ''
          }
        </Box>
      </Box>

      <AddToCalendarDialog
        event={event}
        setAddToCalendarOpen={setAddToCalendarOpen}
        addToCalendarOpen={addToCalendarOpen}
      />
    </>
  )
}


export function ClosingDetailsSection({ sectionConfig }: {
  sectionConfig: { name: string };
}) {
  const { globalOrderData } = useOrderDataContext()
  if (!globalOrderData) return null;

  const order = globalOrderData!.Order
  const propertyAddress = order.PropertyAddress
  const closingAddress = order.ClosingAddress

  const hasFullAddress = ADDRESS_CHARS_REGEX.test(closingAddress.Full || '')

  const hasGranularAddress =
    ADDRESS_CHARS_REGEX.test(closingAddress.Street || '') &&
    ADDRESS_CHARS_REGEX.test(closingAddress.City || '') &&
    ADDRESS_CHARS_REGEX.test(closingAddress.State || '') &&
    ADDRESS_CHARS_REGEX.test(closingAddress.Zip || '')
  ;

  const time = formatClosingDate({ order })
  const calStartDate = getCalStartDate(order)
  const hasParseableTime = !!calStartDate

  const allClosingDetailsHTML = order.ClosingDetails?.AllClosingDetailsHTML

  const hasAddressOrTime = (hasFullAddress || hasGranularAddress) || time

  const propertyAddressName = propertyAddress?.Street || propertyAddress?.Full

  const calEvent = {
    name: propertyAddressName ? `Signing - ${propertyAddressName}` : 'Signing',
    details: '',
    location: closingAddress?.Full,
    startsAt: calStartDate,
    endsAt: getCalEndDate(calStartDate),
  }

  return (
    <Card
      raised
      sx={{
        bgcolor: 'primary.main', color: 'primary.contrastText',
        borderRadius: '0px', p:2,
        flexShrink: 0, position: 'relative',
      }}
    >
      <Box
        sx={{
          height: '100%',
          '&:before': {
            content: '""',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0, left: 0,
            backgroundImage: `url(${park})`,
            mixBlendMode: 'luminosity',
            backgroundSize: 'cover',
            backgroundPosition: `0% 100%`,
            opacity: 0.3,
            pointerEvents: 'none',
          }
        }}
      >
        <Box display="flex" width="100%">
          <Box
            display="flex" alignItems="baseline" justifyContent="space-between"
            width="100%" flexWrap="wrap"
          >
            <Typography variant="h6" mb={1} whiteSpace="nowrap">
              {sectionConfig.name}
            </Typography>
            <Box sx={{ ml: '20px', wordWrap: 'break-word' }}>
              { allClosingDetailsHTML ? <SanitizedHtml html={allClosingDetailsHTML} /> : '' }

              { (hasAddressOrTime && !allClosingDetailsHTML) ?
                <>
                  <ClosingTime order={order} />
                  <ClosingAddressUi address={closingAddress} hasGranularAddress={hasGranularAddress} hasFullAddress={hasFullAddress} />
                </> : ''
              }

              { !hasAddressOrTime && !allClosingDetailsHTML ?
                <Typography fontWeight={500} sx={{ fontStyle: 'italic' }}>
                  Time and location will appear here when available
                </Typography> : ''
              }
            </Box>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <AddToCalendar event={calEvent} disabled={!hasParseableTime || !!allClosingDetailsHTML} />
        </Box>
      </Box>

    </Card>
  )
}
