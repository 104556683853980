import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Copied from the web. Not sure why this is necessary now. Maybe something to do with
// me reorganizing the routes a bit. Looks like there's a better way to handle this
// in the latest version of react router but it would require more significant refactoring.
// https://reactrouter.com/en/main/components/scroll-restoration
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    // window.scrollTo(0,0)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [pathname]);

  return null;
}
