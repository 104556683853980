import { Navigate, Route, Routes } from 'react-router-dom';
import { Auth0LoginErrorHandler } from '../logic';
import { ActiveClosingsPage, ClosingPage, LandingPage, RegisterUser, UnauthedDownloadPage, UnauthedSigningPage } from './pages';
import { useAuth0 } from '@auth0/auth0-react';
import { AppDrawer, ClosingHeader, LoadingScreen } from './shared';
import { OrderDataProvider } from './OrderDataProvider';
import { BottomNav } from './shared/BottomNav';

export const RoutesComponent = function({ drawerHeight, drawerWidth, auth0TicketId, companyIdOrderIdHash }: {
	drawerWidth: number;
	drawerHeight: number;
  auth0TicketId?: string;
  companyIdOrderIdHash?: string;
}) {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <LoadingScreen />

  return (
    <Routes>

      {/*
        Parent Routes that get rendered for all children, regardless of current route.
        Using this to wrap all routes in a data provider that can use routing
        details to get order data and make them available everywhere.
      */}
      <Route element={<OrderDataProvider />}>
        <Route element={<>
          <AppDrawer drawerWidth={drawerWidth} drawerHeight={drawerHeight} />
          <BottomNav drawerWidth={drawerWidth} drawerHeight={drawerHeight} />
        </>}>
          {/* Putting this first, in the case that someone is logged in AND they
              are using a multi signer link. :shrug:
           */}
          <Route path='/signing/:signingHash' element={<UnauthedSigningPage drawerHeight={drawerHeight}/>} />
          <Route path='/signing/:signingHash/download' element={<UnauthedDownloadPage drawerHeight={drawerHeight}/>} />

          {isAuthenticated ?
            (<>
              <Route path='/closings' element={<ActiveClosingsPage />} />

              <Route path='/closings/*' element={<ClosingHeader/>}>
                <Route path=':closingId' element={<ClosingPage />} />
                <Route path=':closingId/:section' element={<ClosingPage />} />
                <Route path=':closingId/:section/:id' element={<ClosingPage />} />
              </Route>

              <Route path='/*' element={<Navigate replace to="/closings" />} />
            </>) :
            (
              // if not authed, all routes lead here for register/login/redirection etc.
              <>
                <Route path='/*' element={<LandingPage drawerHeight={drawerHeight} />} />
              </>
            )
          }
          {/* Programatic page to navigate the user to the right : */}
          <Route path='/invite/:invitationKey' element={
            <RegisterUser auth0TicketId={auth0TicketId} companyIdOrderIdHash={companyIdOrderIdHash}/>
          }/>

          {/* Auth0 redirects back to us here.  We render nothing as the
            Auth0Provider detects the code and token query params and reads
            the appState.redirectTo
          */}
          <Route path='/login' element={<Auth0LoginErrorHandler />} />

        </Route>
      </Route>
    </Routes>
  )
}
