import { ChevronRight, MailOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import List from '@mui/material/List';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { track, useContent, useUserCancelTask } from '../../logic';
import { GetOrderResponse, PortalNotificationReference } from '../../types';
import { formatDate, getNotificationsInfo, NotificationsInfo } from '../../utils';
import { NotificationPage } from '../pages';
import { TrackerCircle } from './ClosingTrackerSection';
import { LinkWithSearchParams } from './LinkWithSearchParams';
import { SectionButtonLink } from './Section';
import CloseIcon from '@mui/icons-material/Close';
import { compact, findIndex, set } from 'lodash';
import { useOrderDataContext } from '../OrderDataProvider';
import { SectionConfig } from './ClosingPageSectionConfig';

export function NotificationsSection({ notificationsInfo, sectionConfig }: {
  notificationsInfo: NotificationsInfo;
  sectionConfig: SectionConfig;
}) {
  const { section, id } = useParams()
  const isNotificationSection = section === 'notifications'
  const countText = notificationsInfo.messagesCountText

  // Hide the notification section if we're on the closing page.
  if (sectionConfig.hideInClosingPage && !isNotificationSection) {
    return <></>
  }

  if (isNotificationSection && id !== undefined) {
    // Passing the key here causes the whole NotificationPage component to rerender
    // when the notification id changes.
    return <NotificationPage key={id}/>
  }

  return isNotificationSection ?
    <>
      { notificationsInfo.hasTasks ?
        <TaskList notificationsInfo={notificationsInfo}/>
        :
        <></>
      }
      { notificationsInfo.hasMessages ?
        <NotificationList notificationsInfo={notificationsInfo}/>
        :
        <></>
      }
      { (!notificationsInfo.hasMessages && !notificationsInfo.hasTasks) ?
        <Box sx={{ m: 1 }}>No messages or tasks found</Box>
        :
        <></>
      }
    </>
    :
    <SectionButtonLink
      title={sectionConfig.name}
      secondaryTitle={countText}
      icon={<MailOutlined />}
      to="notifications"
      searchParams={{component: 'messages'}}
    />
}


function TaskList({ notificationsInfo }: {
  notificationsInfo: NotificationsInfo;
}) {
  const uiContent = useContent()
  const { globalOrderData } = useOrderDataContext()
  const globalNotificationsInfo = globalOrderData ? getNotificationsInfo(globalOrderData?.Notifications, uiContent) : notificationsInfo
  const [realtimeNotificationsInfo, setRealtimeNotificationInfo] = useState(globalNotificationsInfo)

  function updateCompleted(globalOrderData: GetOrderResponse) {
    setRealtimeNotificationInfo(getNotificationsInfo(globalOrderData.Notifications, uiContent))
  }

  const incomplete = realtimeNotificationsInfo.incompleteTasks
  const complete = realtimeNotificationsInfo.completeTasks

  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: '500', lineHeight: '22px', m: 1 }}>
        Tasks
      </Typography>
      <Divider />
      <List sx={{ maxWidth: '800px', 'MuiList-root': { mt: 0 } }}>
        {incomplete.length ?
          <Typography sx={{ lineHeight: '22px', color: 'primary.main', m: 1, ml: 2, mt: 0 }}>New!</Typography> :
          ''
        }

        {incomplete.map((notification) => (
          <Fragment key={notification.NotificationId}>
            <TaskLink updateCompleted={updateCompleted} notification={notification} />
          </Fragment>
        ))}

        {(incomplete.length && complete.length) ? <Divider sx={{ mt: 3, mb: 3 }}/> : ''}

        {complete.map((notification) => (
          <Fragment key={notification.NotificationId}>
            <TaskLink notification={notification} />
          </Fragment>
        ))}
      </List>
      <Box sx={{m: 3}}></Box>
    </>
  )
}


function NotificationList({ notificationsInfo }: {
  notificationsInfo: NotificationsInfo;
}) {
  const notRead = notificationsInfo.unreadMessages
  const read = notificationsInfo.readMessages

  let [searchParams] = useSearchParams();
  const component = searchParams.get('component')
  const scrollToMessages = component === 'messages'

  const messagesRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if(messagesRef?.current && scrollToMessages) {
      messagesRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }, [scrollToMessages, messagesRef])

  return (
    <>
      <Typography component="span" ref={messagesRef} variant="h5"
        sx={{ fontWeight: '500', lineHeight: '22px', m: 1, scrollMarginTop: {xs: '80px', sm: '10px'}, }}
      >
        Messages
      </Typography>
      <Divider />
      <List sx={{ maxWidth: '800px', 'MuiList-root': { mt: 0 } }}>
        {notRead.length ?
          <Typography sx={{ lineHeight: '22px', color: 'primary.main', m: 1, ml: 2, mt: 0 }}>New!</Typography> :
          ''
        }

        {notRead.map((notification) => (
          <Fragment key={notification.NotificationId}>
            <NotificationLink notification={notification} />
          </Fragment>
        ))}

        {(notRead.length && read.length) ? <Divider sx={{ mt: 3, mb: 3 }}/> : ''}

        {read.map((notification) => (
          <Fragment key={notification.NotificationId}>
            <NotificationLink notification={notification} />
          </Fragment>
        ))}
      </List>
    </>
  )
}

function NotificationLink({ notification }: {
  notification: PortalNotificationReference;
}) {
  const date = formatDate({dateLike: notification.CreatedDateTime}) || ''
  const isNew = !notification.ReadReceipt

  return (
    <ListItemButton
      component={LinkWithSearchParams}
      to={notification.NotificationId}
      sx={{
        borderRadius: '20px', width: '100%', height: '69px', boxShadow: 'none',
        display: 'flex', justifyContent: 'start', pl: '15px', pr: '29px',
        fontSize: '18px', fontWeight: '400', mt: '2px', backgroundColor: 'white',
        '&:hover': { color: 'initial' },
      }}
    >
      <ListItemText
        primary={notification.NotificationTitle}
        primaryTypographyProps={{ fontWeight: isNew ? 700 : 400, noWrap: true }}
        secondary={date}
        secondaryTypographyProps={{ variant: 'teeny', letterSpacing: '0.1px', color: '#ABABAF', }}
      />
      <ListItemIcon sx={{ ml: 2, minWidth: 0, color: 'primary.main' }}>
        <ChevronRight/>
      </ListItemIcon>
    </ListItemButton>

  )
}

function TaskLink({ notification, updateCompleted }: {
  notification: PortalNotificationReference;
  updateCompleted?: Function;
}) {
  const { search } = useLocation()
  const { globalOrderData, setGlobalOrderData } = useOrderDataContext()
  const orderKey = globalOrderData?.Order?.CompanyIdOrderIdHash || ''
  const date = formatDate({dateLike: notification.CreatedDateTime}) || ''
  const [userCancelOpen, setUserCancelOpen] = useState(false);
  const { refresh: refreshUserCancel, error: userCancelError, data: userCancelSuccess } = useUserCancelTask(orderKey, notification.NotificationId, search)

  // Keep loading state in separate state because loading starts as true
  // when we don't fire useApi right away.
  // TODO: look into if we can change that ^
  const [delayedUserCancelLoading, setDelayedUserCancelLoading] = useState(false)

  const notificationList = compact(globalOrderData?.Notifications)
  const notificationIndex = findIndex(notificationList, { NotificationId: notification.NotificationId })

  // User manually cancelled from task list
  useEffect(() => {
    if (!notification.IsCancelled && userCancelSuccess) {
      set(globalOrderData || {}, `Notifications[${notificationIndex}].IsComplete`, true)
      set(globalOrderData || {}, `Notifications[${notificationIndex}].IsCancelled`, true)
      setGlobalOrderData(globalOrderData)
      updateCompleted && updateCompleted(globalOrderData)
    }

    if (userCancelError || userCancelSuccess) {
      setDelayedUserCancelLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCancelSuccess, userCancelError, notification.IsCancelled])

  // NOTE: kinda yucky to handle this on the individual tasks but it was also yucky
  // trying to handle it all from the TaskList component (perhaps where it should be)
  function onUserCancelTask() {
    setDelayedUserCancelLoading(true)
    refreshUserCancel()
    setUserCancelOpen(false)
  }

  if (userCancelError) {
    track('error', 'useUserCancelTask', {}, {
      message: JSON.stringify(userCancelError),
      component: 'TaskLink',
    })
  }


  function getTaskStatus() {
    let taskStatus = 'REQUESTED'
    if (notification.IsCancelled) {
      taskStatus = 'CANCELED'
    } else if (notification.IsComplete) {
      taskStatus = 'COMPLETED'
    }

    return `TASK ${taskStatus}`
  }

  const isSigningTask = notification.Type === 'SigningTask'
  const showUserCancelButton = !notification.IsComplete && !isSigningTask


  return (
    <>
      <ListItem
        key={`${notification.NotificationId}-${notification.NotificationTitle}`}
        sx={{
          width: '100%', height: '72px', boxShadow: 'none',
          fontSize: '18px', fontWeight: '400', mt: '2px',
        }}
        disablePadding
        secondaryAction={
          showUserCancelButton ?
            <IconButton
              size='small'
              aria-label="cancel"
              disabled={delayedUserCancelLoading}
              onClick={() => {
                setUserCancelOpen(true)
              }}
            >
              { delayedUserCancelLoading ? <CircularProgress size={20} /> : <CloseIcon fontSize='small'/> }
            </IconButton> : ''
        }
      >
        <ListItemButton
          sx={{
            borderRadius: '20px', backgroundColor: 'white',
            width: '100%', height: '100%',
            display: 'flex', justifyContent: 'space-between', pl: '15px', pr: '29px',
            '&:hover': { color: 'initial' },
          }}
          component={LinkWithSearchParams}
          to={notification.NotificationId}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <ListItemIcon sx={{ mr: 2, minWidth: 0, color: 'primary.main' }}>
              <TrackerCircle completed={notification.IsComplete || false} connector={false} cancelled={notification.IsCancelled}/>
            </ListItemIcon>
            <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
              <Typography
                variant='teeny'
                sx={{
                  letterSpacing: '0.1px', color: '#ABABAF',
                  fontWeight: 600,
                }}
              >
                {getTaskStatus()}
              </Typography>
              <Typography noWrap={true} sx={{ lineHeight: 1.2, pt: '2px', pb: '2px', fontWeight: notification.IsComplete ? 400 : 700 }}>{notification.NotificationTitle}</Typography>
              <Typography
                variant='teeny'
                sx={{
                  letterSpacing: '0.1px', color: '#ABABAF', fontWeight: 600,
                }}
              >{date}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <ListItemIcon
              sx={{
                ml: 2, minWidth: 0, color: 'primary.main',
                mr: notification.IsComplete ? 0 : 2,
              }}
            >
              <ChevronRight/>
            </ListItemIcon>
            { showUserCancelButton ? <Divider orientation='vertical' flexItem={true} sx={{ mr: 1, mt: -1, mb: -1 }} /> : ''}
          </Box>
        </ListItemButton>
      </ListItem>


      <Dialog
        key="userCancel"
        open={userCancelOpen}
      >
        <DialogTitle>
          Cancel Task
        </DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>
            Are you sure you want to cancel your "{notification.NotificationTitle}" task?
          </DialogContentText>
          <DialogContentText>
            After canceling, the task will not be available to complete for your transaction.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => setUserCancelOpen(false)}>No, keep it</Button>
          <Button variant="rounded" onClick={onUserCancelTask}>Yes, cancel it</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
