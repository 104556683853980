import { ChevronRight } from "@mui/icons-material";
import { Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useContent } from "../../logic";
import { getNotificationsInfo } from "../../utils";
import { useOrderDataContext } from "../OrderDataProvider";
import { LinkWithSearchParams } from "./LinkWithSearchParams";

export function NotificationPagination({ isTasks = false, currentNotificationId = ''}: {
  isTasks?: boolean;
  currentNotificationId?: string;
}) {
  const { closingId } = useParams()
  const { globalOrderData } = useOrderDataContext()
  const uiContent = useContent()
  const { TaskText, TasksText, MessageText, MessagesText, ClosingText } = uiContent

  if (!globalOrderData) return <></>

  const globalNotificationsInfo = getNotificationsInfo(globalOrderData.Notifications, uiContent)

  let countText
  let nextNotification
  let buttonText
  let searchParams = {}

  if (isTasks) {
    // We show this in cases that the task you're viewing isn't complete, filter that one out.
    const otherIncompleteTasks = globalNotificationsInfo.incompleteTasks.filter((task) => task.NotificationId !== currentNotificationId)
    const incompleteTasksCount = otherIncompleteTasks.length

    countText = incompleteTasksCount ? `You have ${otherIncompleteTasks.length} other ${globalNotificationsInfo.incompleteTasks.length > 1 ? TasksText : TaskText} to complete!` : ''
    nextNotification = otherIncompleteTasks[0]
    buttonText = `Next ${TaskText}`
  } else {
    const unreadMessagesCount = globalNotificationsInfo.unreadMessages.length
    countText = unreadMessagesCount ? `You have ${globalNotificationsInfo.unreadMessages.length} unread ${globalNotificationsInfo.unreadMessages.length > 1 ? MessagesText : MessageText}!` : ''
    nextNotification = globalNotificationsInfo.unreadMessages[0]
    buttonText = `Next ${MessageText}`
    searchParams = {component: 'messages'}
  }


  return (
    <>
      <Divider sx={{mt: 4, mb: 4}}/>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {!nextNotification ? <>
          <Button
            variant="contained"
            fullWidth={true}
            component={LinkWithSearchParams}
            to={`/closings/${closingId}`}
            sx={{
              mt: 1, maxWidth: '300px', borderRadius: '100px',
              '&:hover': { backgroundColor: 'primary.dark', color: 'white' },
            }}
          >
            Back to {ClosingText} details
          </Button>
        </> : <>
          <Typography fontSize="small" variant="light" >{countText}</Typography>
          <Button
            fullWidth={true}
            variant="contained"
            startIcon={<Box sx={{flex: 1, ml: 2}}></Box>}
            endIcon={<ChevronRight/>}
            component={LinkWithSearchParams}
            to={`/closings/${closingId}/notifications/${nextNotification.NotificationId}`}
            sx={{
              mt: 1, maxWidth: '300px', justifyContent: 'space-between',
              '&:hover': { backgroundColor: 'primary.dark', color: 'white' },
            }}
          >
            {buttonText}
          </Button>
          <Button
            fullWidth={true}
            component={LinkWithSearchParams}
            to={`/closings/${closingId}/notifications`}
            newSearchParams={searchParams}
            sx={{
              mt: 1, maxWidth: '300px', borderRadius: '100px',
              '&:hover': { color: 'primary.dark' },
            }}
          >
            View {isTasks ? TasksText : MessagesText}
          </Button>
        </>
        }

      </Box>
    </>
  )
}
