import { LogicAddress } from '../types'

export function emailUrl({ Email }: { Email: string }) {
  if (!Email) return null
  return `mailto:${Email}`
}

export function phoneUrl({ Phone }: { Phone: string }) {
  if (!Phone) return null
  return `tel:${Phone}`
}

export function mapUrl({ Name, Street, State }: LogicAddress) {
  const addressString = [Name, Street, State].join(', ')
  const query = encodeURIComponent(addressString)
  return `https://maps.google.com?q=:${query}`
}
