import { Autocomplete, List, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { track, useContent, useGetOrders } from '../../logic';
import { usePageTitle } from '../../logic/analytics/usePageTitle';
import { PortalOrder } from '../../types';
import { NothingHere, DoubleCloudBox, LoadingScreen, ArchivedClosingSummary } from '../shared';
import { ActiveClosingSummary } from '../shared/ActiveClosingSummary';
import { ErrorDialog } from '../shared/ErrorDialog';
import { partition, uniqBy } from 'lodash'
import { useSearchParams } from 'react-router-dom';
import { Legalese } from '../shared/Legalese';

function sortClosings(a: PortalOrder, b: PortalOrder) {
  // Sort by date. Soonest closing date first. No closing date last
  return (Date.parse(b.ClosingDateTime) || 0) - (Date.parse(a.ClosingDateTime) || 0)
}

// TODO: mem leak here? seeing warning in devtools. deferring exploration
export function ActiveClosingsPage() {
  const { loading, error, data } = useGetOrders();
  const [inputValue, setInputValue] = useState('')
  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme() as any
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { ClosingsText } = useContent()

  // NOTE: this might make more sense in an unmount useeffect cleanup function
  // when the closing page unmounts. Also, it doesn't actually update the URL.
  useEffect(() => {
    // Clear role specific search param.
    if (searchParams.has('role')) {
      searchParams.delete('role')
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }, [searchParams, setSearchParams])

  usePageTitle(ClosingsText.toUpperCase())

  if (loading) {
    return <LoadingScreen />
  }
  if (error) {
    track('error', 'useGetOrders', {}, {
      message: JSON.stringify(error),
      component: 'ActiveClosingsPage',
    })

    // @ts-ignore
    if (!error?.userErrorMessage && !error?.message) {
      // @ts-ignore
      error.userErrorMessage = `We were unable to load your ${ClosingsText}`
    }

    return <ErrorDialog error={error} />
  }

  const orders = data!.Orders

  let filteredOrders = orders.filter((order) => {
    if (!inputValue) {
      return true
    } else {
      const address = order.PropertyAddress?.Full && order.PropertyAddress?.Full.toLowerCase()
      const fileNumber = order.FileNumber && order.FileNumber.toLowerCase()
      const lowerCaseInputValue = inputValue.toLowerCase()

      const matchesAddress = address && address.includes(lowerCaseInputValue)
      const matchesFileNumber = fileNumber && fileNumber.includes(lowerCaseInputValue)

      // Handle inputs selected from the dropdown that contain both address and filenumber
      const doubleMatch = (address && lowerCaseInputValue.includes(address)) && (fileNumber && lowerCaseInputValue.includes(fileNumber))

      return matchesAddress || matchesFileNumber || doubleMatch
    }
  })

  let [activeOrders, archivedOrders] = partition(filteredOrders, ['IsArchived', false])

  const allFilterOptions = orders.map((order) => {
    return {
      address: order.PropertyAddress?.Full,
      fileNumber: order.FileNumber,
    }
  })

  const filterOptions = uniqBy(allFilterOptions, (option) => `${option.fileNumber}${option.address}`)

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} p={{ xs: '30px 12px 12px 12px', sm: '25px 20px 20px 20px' }}>
      <Box sx={{
        display: 'flex', p: '0 20px',
        justifyContent: 'space-between',
        mt: { xs: '8px', sm: '112px' },
      }}>
        <Box sx={{
          fontSize: 36,
          fontWeight: 300,
          width: { xs: 240, sm: '100%' },
          marginBottom: '32px',
          marginRight: '20px',
          lineHeight: '36px',
        }}>
          Your active {ClosingsText}
        </Box>
        <Box sx={{ mt: {xs: '-8', sm: '-37px'} }}>
          <DoubleCloudBox></DoubleCloudBox>
        </Box>
      </Box>

      { orders?.length > 4 &&
        <Autocomplete
          blurOnSelect={true}
          forcePopupIcon={true}
          freeSolo={true}
          size='small'
          options={filterOptions}
          getOptionLabel={(option) => `${option.address || ''}${option.fileNumber || ''}`}
          renderOption={(props, option) => (
            <Box sx={{padding: 1}} component='li' key={option.fileNumber} {...props}>
              <Typography fontSize='15px' fontWeight='500'>
                {option.address}
                <br/>
                {option.fileNumber ? <Typography fontSize='13px'> #{option.fileNumber}</Typography> : ''}
              </Typography>

            </Box>
          )}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            // TODO: if we want responsive filtering and performance with lots o
            // closings, maybe debounce/throttle here. Might not be necessary
            setInputValue(newInputValue);
          }}
          sx={{ width: 300, mb: 2, borderRadius: '20px',
            '.MuiAutocomplete-inputRoot': { borderRadius: '10px' },
          }}
          renderInput={(params) => <TextField {...params} label={`Filter ${ClosingsText}`} />}
        />
      }

      { activeOrders?.length ?
        (<Grid container spacing={2}>
          {activeOrders.sort(sortClosings).map(closing => (
            <Grid item xs={12} md={6} xl={4} key={closing.UserOrderKey}>
              <ActiveClosingSummary order={closing} />
            </Grid>
          ))}
        </Grid>) :
        <NothingHere><>We couldn't find any active{inputValue ? ` ${ClosingsText} that match your search` : ` ${ClosingsText}`}</></NothingHere>
      }

      { archivedOrders?.length ?
        <>
          <Typography  sx={{
            mt: 5,
            mb: 1,
            ml: 1,
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '22px',
            letterSpacing: '0.10000000149011612px',
            textAlign: 'left',
          }}>Archived {ClosingsText}</Typography>

          <List>
            {archivedOrders.sort(sortClosings).map((closing, idx) => (
              <ArchivedClosingSummary index={idx} order={closing} key={closing.UserOrderKey}></ArchivedClosingSummary>
            ))}
          </List>

        </> : ''
      }
      {isSmall ?
        <Box sx={{ mt: 'auto', pt: 2 }}>
          <Legalese></Legalese>
        </Box> : ''
      }
    </Box>
  )
}
