import { ChevronRight } from '@mui/icons-material';
import { Box, Button, List, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { compact } from 'lodash';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useContent } from '../../logic';
import { getNotificationsInfo } from '../../utils';
import { useOrderDataContext } from '../OrderDataProvider';
import { useOrganization } from '../OrganizationProvider';
import { ClosingContactsSection, ClosingDetailsSection, ClosingDocumentsSection, ClosingTracker, LinkWithSearchParams, NotificationsSection, WiringInstructionsSection } from '../shared';
import { getSectionsConfig, SectionConfig, sectionMap, SectionName } from '../shared/ClosingPageSectionConfig';
import { TasksSection } from '../shared/TasksSection';

export function MobileSectionLink({ sectionConfig }: {
  sectionConfig: SectionConfig;
}) {
  const { icon, name, secondaryText, routeTo, searchParams, type, hideInClosingPage } = sectionConfig
  const to = routeTo || type

  if (hideInClosingPage) {
    return <></>
  }

  return (
    <Button
      component={LinkWithSearchParams}
      newSearchParams={searchParams}
      to={to}
      variant="contained"
      sx={{
        borderRadius: '20px', width: '100%', height: '69px', boxShadow: 'none',
        display: 'flex', justifyContent: 'start', pl: '37px', pr: '29px',
        fontSize: '18px', fontWeight: '400', mt: '2px', backgroundColor: 'white',
        color: 'black', '&:hover': { color: 'white' }, lineHeight: '20px',
        '.MuiButton-endIcon': { ml: 'auto', color: 'primary.main' },
        '.MuiButton-startIcon': { color: 'primary.main' },
      }}
      startIcon={icon}
      endIcon={<ChevronRight/>}
      fullWidth={true}
    >
      <Box>
        {name}
        <Typography variant="light" fontSize="small" noWrap sx={{display: 'block'}}>
          {secondaryText}
        </Typography>
      </Box>
    </Button>
  )
}

function MobileSectionLinkList({ sectionsConfig }: { sectionsConfig: any[] }) {
  return (
    <List sx={{
      p: 0,
    }}>
      { sectionsConfig.map(config => (
        <Fragment key={config.type} ><MobileSectionLink sectionConfig={config}/></Fragment>
      ))}
    </List>
  )
}

export function ClosingPage() {
  const { section: sectionQueryParam, id } = useParams()
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallButNotTooSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const uiContent = useContent()

  const sectionDetails = sectionMap[sectionQueryParam as SectionName]
  const isStandaloneSection = sectionDetails?.isStandaloneSection || (isSmall && id !== undefined)
  const onlySection = (isSmall || isStandaloneSection) && sectionQueryParam

  const org = useOrganization()

  const { globalOrderData } = useOrderDataContext()
  if (!globalOrderData) return null;

  const order = globalOrderData.Order

  const notificationsInfo = getNotificationsInfo(globalOrderData.Notifications, uiContent)

  let {
    sectionsConfig,
    closingTrackerSectionConfig,
    closingDetailsSectionConfig,
  } = getSectionsConfig({ notificationsInfo, order, org })

  // let sectionNames = sectionsConfig.map(s => s.type as SectionName)

  if (onlySection) {
    sectionsConfig = sectionsConfig.filter(c => c.type === onlySection)
    // sectionNames = [onlySection as SectionName]
  }

  let sections: JSX.Element[] = []

  sectionsConfig.forEach(sectionConfig => {
    if (sectionConfig.type === 'tasks') {
      sections.push(<TasksSection
        key='tasks-section'
        notificationsInfo={notificationsInfo}
        sectionConfig={sectionConfig}
      />)
    }

    if (sectionConfig.type === 'notifications') {
      sections.push(<NotificationsSection
        key='notifications'
        notificationsInfo={notificationsInfo}
        sectionConfig={sectionConfig}
      />)
    }

    if (sectionConfig.type === 'closing-documents') {
      sections.push(
        <ClosingDocumentsSection
          key="closing-documents"
          closingDocuments={compact(globalOrderData.Downloads)}
          CompanyIdOrderIdHash={globalOrderData.Order.CompanyIdOrderIdHash}
          collapsible={isSmallButNotTooSmall}
          sectionConfig={sectionConfig}
          notificationRefs={notificationsInfo.notifications}
        />
      );
    }

    if (sectionConfig.type === 'wiring-instructions') {
      sections.push(
        <WiringInstructionsSection
          key="wiring-instructions"
          wiringInstructions={order.WiringInstructions}
          CompanyIdOrderIdHash={globalOrderData.Order.CompanyIdOrderIdHash}
          collapsible={isSmallButNotTooSmall}
          sectionConfig={sectionConfig}
          hasWiringInstructionsTask={notificationsInfo.hasWiringInstructionsTask}
          wiringInstructionsTaskRef={notificationsInfo.wiringInstructionsTaskRef}
        />
      );
    }

    if (sectionConfig.type === ('closing-contacts')) {
      sections.push(<ClosingContactsSection
        key='closing-contacts'
        contacts={compact(order.ClosingContacts)}
        collapsible={isSmallButNotTooSmall}
        sectionConfig={sectionConfig}
      />)
    }
  })

  return (
    <>
      { (isSmall && !sectionQueryParam) ?
        <MobileSectionLinkList sectionsConfig={sectionsConfig} />
        :
        <Box sx={{ flexBasis: { sm: 'auto', md: '60%' } }}>
          <Stack spacing={2}>
            {sections}
          </Stack>
        </Box>
      }

      <Stack sx={{ maxWidth: { sm: 'none', md: '400px'}, flexBasis: { sm: 'auto', md: '40%'} }} spacing={2}>
        { !onlySection && !isStandaloneSection && closingTrackerSectionConfig &&
          <ClosingTracker
            stepProgress={order.StepProgress}
            sectionConfig={closingTrackerSectionConfig}
          />
        }
        { (!isStandaloneSection && !isSmall && closingDetailsSectionConfig) ?
          <ClosingDetailsSection sectionConfig={closingDetailsSectionConfig} />
          : ''
        }
      </Stack>
    </>
  )
}
