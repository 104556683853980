import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { TrackJS } from 'trackjs'
import { useOrganization } from '../../components/OrganizationProvider';

const closingsPathRegex = /\/closings\/[a-zA-Z0-9]+/
const closingsNormalizedPath = '/closings/:id'

export const TelemetryCustomization = () => {
  const stage = process.env.REACT_APP_STAGE
  const { user, isAuthenticated } = useAuth0()
  const org = useOrganization()
  const userId = user?.sub;
  const isTrackJSInstalled = TrackJS.isInstalled()
  let location = useLocation();

  if (isTrackJSInstalled) {
    TrackJS.addMetadata('stage', stage || '')
    TrackJS.addMetadata('company_id', org.CompanyId)
  }

  useEffect(() => {
    const normalizedPath = window.location.pathname.replace(closingsPathRegex, closingsNormalizedPath)
    console.log('[ErrorTracking] navigation.  normalizedPath:', normalizedPath)

    TrackJS.console.log('navigation', {
      normalizedPath,
      fullPath: window.location.pathname + window.location.search,
      isAuthenticated,
    })

    // NOTE: Excluding other deps here to ensure that we don't log duplicate events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (userId && isTrackJSInstalled) {
      console.log('[ErrorTracking] setting userId', userId)
      TrackJS.configure({
        userId
      })
    }
  }, [userId, isTrackJSInstalled])

  return null;
}
