import { useState, useEffect } from "react";


const getBrowserVisibilityProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange";
  } else if (typeof (document as any).msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof (document as any).webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  } else {
    return "visibilitychange";
  }
}

const getBrowserDocumentHiddenProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof (document as any).msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof (document as any).webkitHidden !== "undefined") {
    return "webkitHidden";
  } else {
    return "hidden";
  }
}

const getIsDocumentVisible = () => {
  const documentHiddenProp = getBrowserDocumentHiddenProp()

  return !(document as any)[documentHiddenProp];
}


export const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(getIsDocumentVisible())
  const onVisibilityChange = () => setIsVisible(getIsDocumentVisible())

  useEffect(() => {
    const visibilityProp = getBrowserVisibilityProp()

    document.addEventListener(visibilityProp, onVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityProp, onVisibilityChange)
    }
  }, []);

  return isVisible;
}
