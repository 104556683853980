import { CheckSharp } from '@mui/icons-material';
import { SectionButtonLink } from './Section';

import { NotificationsInfo } from '../../utils';
import { SectionConfig } from './ClosingPageSectionConfig';

export function TasksSection({ notificationsInfo, sectionConfig }: {
  notificationsInfo: NotificationsInfo;
  sectionConfig: SectionConfig;
}) {

  return (
    <SectionButtonLink
      title={sectionConfig.name}
      secondaryTitle={notificationsInfo.tasksCountText}
      icon={<CheckSharp />}
      to="notifications"
    />
  )
}
