import { LogicAddress } from '../types';

export function addressOffset(address: LogicAddress) {
  return funhash(address?.Full || address?.Street || '', 100);
}

function funhash(s: string, max: number) {
  for(var i = 0, h = 0xdeadbeef; i < s.length; i++)
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
  return Math.abs((h ^ (h >>> 16)) >>> 0) % max;
}