import { Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { AccountData } from "../../types";

export function AccountVerificationTask({ onSubmit }: {
  onSubmit: (account: any) => void
}) {
  const [account, setAccount] = useState<AccountData>({
    firstName: '',
    lastName: '',
    businessName: '',
    accountNumber: '',
    routingNumber: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  })

  // DEV Helper:
  // useEffect(() => setAccount({ firstName: 'Tim', lastName: 'Doe', businessName: '', accountNumber: '91201050067', routingNumber: '122199983', address: '4026 STRATMORE AVE', city: 'YOUNGSTOWN', state: 'OH', zip: '445113548', }), [])

  const [individualOrBusiness, setIndividualOrBusiness] = useState<'individual' | 'business'>('individual')

  const handleOptionChange = (event: any) => {
    setIndividualOrBusiness(event.target.value)
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    let accountPayload: AccountData

    // The 3rd party validation api doesn't like '' or null values.
    if (individualOrBusiness === 'individual') {
      accountPayload = { ...account, businessName: undefined }
    } else {
      accountPayload = { ...account, firstName: undefined, lastName: undefined }
    }

    onSubmit(accountPayload)
  }

  const textFieldProps = {
    required: true,
    variant: "outlined",
    fullWidth: true,
    sx: {
      backgroundColor: 'white',
    },
    margin: 'normal',
    InputLabelProps: {shrink: true }
  } as const

  return (
    <form onSubmit={handleSubmit}>
      <FormControl component="fieldset">
        <RadioGroup
          row
          name="toggleOptions"
          value={individualOrBusiness}
          onChange={handleOptionChange}
        >
          <FormControlLabel value="individual" control={<Radio />} label="Individual" />
          <FormControlLabel value="business" control={<Radio />} label="Business" />
        </RadioGroup>
      </FormControl>

      {/* Conditionally render the input fields based on selected option */}
      {individualOrBusiness === 'business' ? (
        <TextField
          label="Business Name"
          value={account.businessName}
          onChange={(e) => setAccount({ ...account, businessName: e.target.value })}
          inputProps={{ maxLength: 40 }}
          {...textFieldProps}
        />
      ) : (
        <div>
          <TextField
            label="First Name"
            value={account.firstName}
            onChange={(e) => setAccount({ ...account, firstName: e.target.value })}
            inputProps={{ maxLength: 40 }}
            {...textFieldProps}
          />
          <TextField
            label="Last Name"
            value={account.lastName}
            onChange={(e) => setAccount({ ...account, lastName: e.target.value })}
            inputProps={{ maxLength: 40 }}
            {...textFieldProps}
          />
        </div>
      )}

      <TextField
        label="Account Number"
        value={account.accountNumber}
        onChange={(e) => setAccount({ ...account, accountNumber: e.target.value })}
        inputProps={{ maxLength: 17 }}
        {...textFieldProps}
      />

      <TextField
        label="ABA Routing Number"
        value={account.routingNumber}
        onChange={(e) => setAccount({ ...account, routingNumber: e.target.value })}
        inputProps={{ minLength: 9, maxLength: 9 }}
        {...textFieldProps}
      />

      <TextField
        label="Street Address"
        value={account.address}
        onChange={(e) => setAccount({ ...account, address: e.target.value })}
        inputProps={{ maxLength: 40 }}
        {...textFieldProps}
      />

      <TextField
        label="City"
        value={account.city}
        onChange={(e) => setAccount({ ...account, city: e.target.value })}
        inputProps={{ maxLength: 25 }}
        {...textFieldProps}
      />

      <TextField
        label="State"
        value={account.state}
        onChange={(e) => setAccount({ ...account, state: e.target.value })}
        inputProps={{ minLength: 2, maxLength: 2 }}
        {...textFieldProps}
      />

      <TextField
        label="Zipcode"
        value={account.zip}
        onChange={(e) => setAccount({ ...account, zip: e.target.value })}
        inputProps={{ minLength: 5, maxLength: 10 }}
        {...textFieldProps}
      />

      <Button
        type="submit"
        sx={{
          mt: 4
        }}
        variant="contained"
      >Submit</Button>
    </form>
  )
}