import { each } from 'lodash';
import { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { removeTheseParams } from '../../logic';

export const LinkWithSearchParams = forwardRef(({ children, to, newSearchParams, ...props }: {
  children: React.ReactNode;
  to: string;
  newSearchParams?: object;
}, ref?: any) => {
  let { search } = useLocation();
  const searchParams = new URLSearchParams(search)

  each(removeTheseParams, (param) => {
    searchParams.delete(param)
  })

  if (newSearchParams) {
    each(newSearchParams, (val: string, key: string) => {
      searchParams.set(key, val)
    })
  }

  search = `?${searchParams.toString()}`

  return (
    <Link to={to + search} {...props} ref={ref}>
      {children}
    </Link>
  );
});
