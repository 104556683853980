import { find } from "lodash"
import { PortalOrder } from "../types"

type DateLike = Date | string | null | undefined


const fullDateTime = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' })       // Friday, February 17, 2023 at 8:43 AM
const shortDateWithTime = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }) // 2/17/23, 8:43 AM
const dateNoTime = new Intl.DateTimeFormat('en-US', { dateStyle: 'full' })                             // Friday, February 17, 2023
const shortDateNoTime = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' })                       // 2/17/23
const timeOnly = new Intl.DateTimeFormat('en-US', { timeStyle: 'short' })                              // 8:43 AM
const timeZone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })

// Closing date parsing made special to accommodate mail in closings, which may
// have a closing time of <timeToIgnore> that we don't want to display.
// We've added the option for order.ClosingDetails.ClosingDateExcludeTime, which
// accomplishes the same thing. Leaving this in for now, for backwards compatibility.
const timeToIgnore = '12:00 AM'

function getTimeZoneString(date: Date) {
  if (!parseDate(date)) return ''
  const parts = timeZone.formatToParts(date)
  return find(parts, {type: 'timeZoneName'})?.value || ''
}

export function formatClosingDate({order, noTime, noTimeZone, shortDateTime}: {
  order: PortalOrder;
  noTime?: Boolean;
  noTimeZone?: Boolean;
  shortDateTime?: Boolean;
}) {
  const displayDate = order.ClosingDetails?.ClosingDateTimeDisplay

  if (displayDate) {
    return displayDate
  }

  const parseableDate = order.ClosingDetails?.ClosingDateTime
  const legacyDateLike = order.ClosingDateTime

  const dateLike = parseableDate || legacyDateLike || ''
  const date = parseDate(dateLike)

  if (!date) {
    return legacyDateLike || parseableDate || ''
  }

  // Sometimes isTBD is a 'false' string. Handling that case for all of these to be extra safe
  const timeIsTBD = order.ClosingDetails?.ClosingTimeIsTBD && order.ClosingDetails?.ClosingTimeIsTBD !== 'false'
  const excludeTime = (order.ClosingDetails?.ClosingDateExcludeTime && order.ClosingDetails?.ClosingDateExcludeTime !== 'false') || noTime || timeOnly.format(date) === timeToIgnore
  const excludeTimeZone = (order.ClosingDetails?.ClosingDateExcludeTimeZone && order.ClosingDetails?.ClosingDateExcludeTimeZone !== 'false') || noTimeZone

  if (timeIsTBD) {
    return `${dateNoTime.format(date)}, time to be determined`
  } else if (shortDateTime && excludeTime) {
    return shortDateNoTime.format(date)
  } else if (excludeTime) {
    return dateNoTime.format(date)
  } else if (shortDateTime) {
    return shortDateWithTime.format(date)
  } else if (excludeTimeZone) {
    return fullDateTime.format(date)
  } else {
    const tz = getTimeZoneString(date)
    const tzString = tz ? ` (${tz})` : ''
    return `${fullDateTime.format(date)}${tzString}`
  }
}


export function formatDate({dateLike, noTime, shortDateTime}: {
  dateLike: DateLike;
  noTime?: boolean;
  shortDateTime?: Boolean;
}) {
  if (!dateLike) {
    return null
  }
  const date = parseDate(dateLike)

  if (!date) {
    return undefined
  } else if (shortDateTime && noTime) {
    return shortDateNoTime.format(date)
  } else if (shortDateTime) {
    return shortDateWithTime.format(date)
  } else if (noTime){
    return dateNoTime.format(date)
  } else {
    return fullDateTime.format(date)
  }
}

export function parseDate(dateLike: DateLike) {
  const date = new Date(dateLike ?? '')
  return isNaN(+date) ? undefined : date
}

export function getCalStartDate(order: PortalOrder) {
  const parseableDate = order.ClosingDetails?.ClosingDateTime
  const parsedParseableDate = parseableDate ? parseDate(parseableDate) : null

  const displayDate = order.ClosingDetails?.ClosingDateTimeDisplay
  const parsedDisplayDate = displayDate ? parseDate(displayDate) : null

  const legacyDateLike = order.ClosingDateTime
  const parsedLegacyDate = legacyDateLike ? parseDate(legacyDateLike) : null

  if (parsedParseableDate) {
    return parsedParseableDate.toISOString()
  } else if (parsedDisplayDate) {
    return parsedDisplayDate.toISOString()
  } else  if (parsedLegacyDate) {
    return parsedLegacyDate.toISOString()
  } else {
    return ''
  }
}

export function getCalEndDate(date: Date | string, length: number = 1) {
  const startDate = parseDate(date);
  if (startDate) {
    startDate.setHours(startDate.getHours() + length)
    return startDate.toISOString();
  }
  return ''
}
