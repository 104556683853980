import { ChevronRight } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { PortalOrder } from '../../types';
import { formatClosingDate, formatDate } from '../../utils';
import { LinkWithSearchParams } from '../shared';
import { ReactComponent as House1 } from '../../../assets/house1.svg';
import { ReactComponent as House2 } from '../../../assets/house2.svg';
import { ReactComponent as House3 } from '../../../assets/house3.svg';

function getHouse(index: number) {
  const remainder = (index +1) % 3
  if (remainder === 1) {
    return <House1/>
  } else if (remainder === 2) {
    return <House2/>
  } else {
    return <House3/>
  }
}

export function ArchivedClosingSummary({order, index}: {
  order: PortalOrder;
  index: number;
}) {
  const theme = useTheme() as any
  const mainColor = theme.palette.primary.main
  const propertyAddress = order.PropertyAddress

  const date = formatClosingDate({ order, noTime: true, shortDateTime: true })
  const updatedDate = formatDate({ dateLike: order.UpdatedDate, noTime: true, shortDateTime: true })
  const displayDate = date || updatedDate
  const adjective = date ? 'Completed' : 'Last updated'

  return (
    <ListItem
      key={order.UserOrderKey}
      sx={{
        width: '100%', height: '72px', boxShadow: 'none',
        fontSize: '18px', fontWeight: '400', mt: '2px',
      }}
      disablePadding
    >
      <ListItemButton
        sx={{
          borderRadius: '10px', backgroundColor: 'white',
          width: '100%', height: '100%',
          display: 'flex', justifyContent: 'space-between', pl: '15px', pr: '20px',
          '&:hover': { color: 'initial' },
        }}
        component={LinkWithSearchParams}
        to={`/closings/${order.CompanyIdOrderIdHash}`}
        newSearchParams={{role: order.Role}}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
          <ListItemIcon sx={{
            mr: 2, minWidth: 0,
            '.circle': { fill: mainColor }
          }}>
            {getHouse(index)}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                noWrap={true}
                sx={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '18px',
                  letterSpacing: '0.10000000149011612px',
                  textAlign: 'left',
                }}
              >
                {propertyAddress.Street}
                <br/>
                {`${propertyAddress.City}, ${propertyAddress.State} ${propertyAddress.Zip}`}
              </Typography>
            }
            // primaryTypographyProps={{ fontWeight: isNew ? 700 : 400, noWrap: true }}
            secondary={displayDate ? `${adjective} ${displayDate}`: ''}
            secondaryTypographyProps={{ variant: 'teeny', letterSpacing: '0.1px', color: '#ABABAF', }}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <ListItemIcon
            sx={{
              ml: 2, minWidth: 0, color: 'primary.main',
            }}
          >
            <ChevronRight/>
          </ListItemIcon>
        </Box>
      </ListItemButton>
    </ListItem>
  )
}
