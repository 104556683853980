import { useSearchParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingScreen, ErrorDialog } from '../components/shared';
import { track } from "./analytics";

// Auth0 errors to catch and display a useful/user friendly message
const specificErrors = [
  {
    description: 'the specified account is not allowed to accept the current invitation',
    displayMessage: 'Please verify that the email your invite was sent to matches the email you are trying to create your account with and try the invite link again.',
  },
  {
    description: 'invitation not found or already used',
    displayMessage: 'This invitation has expired.  Please try requesting another invite.',
  },
  {
    messageSubstring: 'Expiration Time (exp) claim error in the ID token',
    description: '',
    displayMessage: "Secure login failed because of a time discrepency. This might be caused by an inaccurate time being configured on your device. Please check your device's time settings and try again.",
  },
]

const defaultErrorMessage = 'Please try logging in again or contact us for assistance.'


export function Auth0LoginErrorHandler() {
  const [searchParams] = useSearchParams();
  const { logout, isAuthenticated, error, } = useAuth0()

  const onTryAgain = () => {
    track('try_again', 'Auth0LoginErrorHandler');

    if (isAuthenticated) {
      logout({ returnTo: `${window.location.origin}` })
    } else {
      // Changed to landing page since we have login error cases where the request
      // invite page/drawer doesn't make sense.
      window.location.assign(window.location.origin)
    }
  };

  const auth0ErrorMessage = searchParams.get('error') || error?.message
  const errorDescription = searchParams.get('error_description')

  if (auth0ErrorMessage) {
    track('error', 'Auth0', {}, {
      message: auth0ErrorMessage,
      description: errorDescription,
      component: 'Auth0LoginErrorHandler',
      error: JSON.stringify(error),
    })
  }

  const errorMessage = specificErrors.find((e) => {
    return (e.description === errorDescription) || (e.messageSubstring && auth0ErrorMessage?.includes(e.messageSubstring))
  })?.displayMessage

  return auth0ErrorMessage ?
    <ErrorDialog onTryAgain={onTryAgain}>
      {errorMessage || defaultErrorMessage}
    </ErrorDialog> :

    // If no error. Show loading while waiting for onRedirectCallback -> #navigate
    <LoadingScreen/>
}
