import { useEffect, useState } from 'react';
import { useGetDownloadLink } from './useApi';
import { DownloadLinkType } from '../types';
import { track } from './analytics';
import { useLocation } from 'react-router-dom';

export const triggerDownload = async (url: string, FileName: string) => {
  var link = document.createElement("a");
  link.download = FileName;
  link.target = '_blank'
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Downloads the given file, when the returned triggerDownload function is
 * called.  Tracks the interaction.
 */
export const useFileDownload = ({ FileName, CompanyIdOrderIdHash, Type, DocumentBatchId, DocumentFileName }: {
  FileName: string;
  CompanyIdOrderIdHash: string;
  Type: DownloadLinkType;
  DocumentBatchId?: string;
  DocumentFileName?: string;
}) => {
  const { search } = useLocation()
  const { data, refresh, error } = useGetDownloadLink({ FileName, CompanyIdOrderIdHash, Type, search, DocumentBatchId, DocumentFileName })
  const [isDownloading, setIsDownloading] = useState(false)

  if (error) {
    track('error', 'useGetDownloadLink', {}, {
      message: JSON.stringify(error),
      component: 'useFileDownload',
      FileName,
      Type
    })

    console.log('[api] useGetDownloadLink ', error)
  }


  useEffect(() => {
    if (data && data.Url) {
      setIsDownloading(true)
      track(`Download ${Type} initialized`, FileName)

      triggerDownload(data.Url, FileName).then(() => {
        setTimeout(() => {
          setIsDownloading(false)
        }, 500);

        // NOTE: This could be misleading since getting here doesn't actually mean it succeeded
        // IMPROVE: download file in a way that allows for confirming success
        track(`Download ${Type} complete`, FileName)
      })
    }
  }, [data, FileName, Type])

  return { triggerDownload: refresh, isDownloading, setIsDownloading, error }
}
