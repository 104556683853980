import { Button, Link } from "@mui/material"
import { Box } from "@mui/system"
import { useOrganization } from "../OrganizationProvider"
import { useOrderDataContext } from "../OrderDataProvider"
import { useAuth0 } from "@auth0/auth0-react"
import { map } from "lodash"

const defaultSupportEmail = 'portal-support@closesimple.com'

export const HelpButton = () => {
  const href = useMailTo()

  return (
    <Button
      href={href}
      sx={{
        borderRadius: "100px", textTransform: 'none',
      }}
    >
      Contact us
    </Button>
  )
}

export const HelpLink = ({ children }: { children?: any }) => {
  const href = useMailTo();

  const prompt = children || 'Contact us'

  return <Link underline="hover" href={href}>{prompt}</Link>;
};

export const Help = () => {
  const href = useMailTo()

  return (
    <Box sx={{
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      width: '100%',
      textAlign: 'center',
    }}>
      Having trouble?&nbsp;
      <Link underline='hover' href={href}>
        We can help.
      </Link>

    </Box>
  )
}

function useMailTo() {
  const host = window.location.host
  const subject = encodeURIComponent(`Portal Assistance for ${host}`);

  const org = useOrganization()
  const orderData =  useOrderDataContext()

  const globalOrderData = orderData?.globalOrderData
  const CompanyDisplayName = org?.CompanyDisplayName || ''

  const email = useAuth0().user?.email || ''
  const fileNumber = globalOrderData?.Order?.FileNumber || ''
  const address = globalOrderData?.Order?.PropertyAddress?.Full || ''

  const intro = `We're here to help. Please add questions and details about your experience to this email. To the best of your knowledge, please fill out any missing information below.`

  const usefulInfo = {
    Name: '',
    "Email": email,
    "File Number": fileNumber,
    "Property Address": address,
    "Description of Problem": "",
    URL: window.location.href,
  }

  const details = map(usefulInfo, (val, key) => `${key}: ${val}`).join('\n\n')

  const body = encodeURIComponent(`${intro}\n\n\n${details}\n\n${CompanyDisplayName}`);


  return `mailto:${defaultSupportEmail}?subject=${subject}&body=${body}`
}
