import { TrackJS } from 'trackjs';


export function initializeErrorTracking() {
  const token = process.env.REACT_APP_TRACKJS_TOKEN!;

  // For now, we're going to use the same "application" for all envs
  // const application = `portal-ui-${stage}`
  // const stage = process.env.REACT_APP_STAGE
  const application = 'portal-ui-prod'

  console.log(`[ErrorTracking] initializeErrorTracking(), application: ${application}`)

  // TODO: check for global flag stored somewhere (SSM?)
  if (token) {
    TrackJS.install({
      token,
      application
    });
  }
}
