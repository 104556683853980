import { CloseRounded, PreviewRounded } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { compact, every, find, set, some } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { track, useBrowserSneezeGuard, useCompleteUpload, useContent, useCreateUploadUrl, useInAppSneezeGuard } from '../../logic';
import { GetOrderResponse, PortalOrderFileUploadType } from '../../types';
import { toTitleCase } from '../../utils';
import { useOrderDataContext } from '../OrderDataProvider';
import { HelpButton } from './Help';
import { LinkWithSearchParams } from './LinkWithSearchParams';

const mimeType = 'image/jpeg'
const resizableTypeCheck = 'image'
const maxPixelsOneDirection = 4032 // &#127926;
const bytesPerMB = 1000000
const maxImageBytes = 2 * bytesPerMB // 2 MB
const initialQuality = .9
const qualityDecrement = .1

/**
 * This limits pixel width and height to maxPixelsOneDirection and recursively
 * shrinks image quality by qualityDecrement until the image size is < maxImageBytes.
 * This is necessary because you cannot specify file size when adjusting pixels
 * and/or quality to shrink file size.
 *
 * If after setting the max pixels and quality to .1, the image is still somehow
 * larger than maxImageBytes, return it at that size.
 *
 * Returns the original full size file if there are any errors.
 *
 */
async function shrinkFile(file: File, quality = initialQuality): Promise<File> {
  let fileShrinkError

  const resized = await new Promise((resolve, reject) => {
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;

    img.onerror = function (err) {
      URL.revokeObjectURL(this.src);
      fileShrinkError = err
      resolve(file)
    };

    img.onload = function () {
      try {
        URL.revokeObjectURL(blobURL);
        const canvas = document.createElement('canvas');
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > maxPixelsOneDirection) {
            height *= maxPixelsOneDirection / width;
            width = maxPixelsOneDirection;
          }
        } else {
          if (height > maxPixelsOneDirection) {
            width *= maxPixelsOneDirection / height;
            height = maxPixelsOneDirection;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx && ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          resolve(blob)
        }, mimeType, quality)
      } catch (err) {
        fileShrinkError = err
        resolve(file)
      }
    };

  })

  console.log('[image resize] - quality: ', quality, ' - size (MB): ', (resized as Blob).size / bytesPerMB);

  const nextQuality = quality - qualityDecrement

  if (fileShrinkError) {
    track('error', 'useFileUpload', {}, {
      message: `error resizing image`,
      error: JSON.stringify(fileShrinkError),
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
    })

    // return/upload initial file if resizing errors
    return file
  } else if (((resized as Blob).size > maxImageBytes) && (nextQuality > 0)) {
    return shrinkFile(file, Math.round((nextQuality) * 10)  / 10)
  } else {
    return new File([resized as BlobPart], file.name, { type: mimeType })
  }
}

export type FileUpload = {
  file: File;
  OriginalDocumentFileName: string;
  // List of types comes across dynamically from tokens.
  DocumentType: string;
  isLoading?: boolean;
  UploadDateTime?: string;
  // Returned from create link endpoint
  DocumentFileName: string;
  DocumentId: string;
}

type CompleteFileUpload = Pick<FileUpload, "OriginalDocumentFileName" | "DocumentFileName" | "DocumentId" | "DocumentType">
export type CompleteUploadRequest = {
  CompanyIdOrderIdHash: string;
  Files: CompleteFileUpload[];
  search: string;
  notificationId?: string;
}

function getTypeName(code: string, types: PortalOrderFileUploadType[]) {
  return find(types, { Code: code })?.Label || 'Unknown type'
}

function UploadBody({ files, setFiles, isLoading, isComplete, allPrepared, globalOrderData, completeUpload, uploadInput, resetFileUploadHook }: {
  files: FileUpload[];
  isLoading: boolean;
  isComplete: boolean;
  allPrepared: boolean;
  globalOrderData: GetOrderResponse | null;
  setFiles: Function;
  completeUpload: () => void;
  uploadInput: JSX.Element;
  resetFileUploadHook: Function;
}) {
  const { closingId } = useParams()
  const { DocumentsText } = useContent()
  const [completeAttempted, setCompleteAttempted] = useState(false)
  const theFiles = files

  useEffect(() => {
    if (isLoading || allPrepared) setCompleteAttempted(false)
  }, [isLoading, allPrepared])

  let header
  if (isLoading) {
    header = ''
  } else if (isComplete) {
    header ='The following have been successfully uploaded:'
  } else if (allPrepared) {
    header = `You've selected the following document${files.length > 1 ? 's' : ''} to upload.`
  } else {
    header = `Great! Help us understand where ${files.length > 1 ? 'these' : 'this'} belong${files.length > 1 ? '' : 's'}.`
  }

  let footer
  if (isComplete) {
    footer = (
      <Typography variant="body2" sx={{ '&.MuiTypography-root': { ml: 1 } }}>
        {/* TODO: cleanup how some links are formatted. Maybe relative links or tack closingId onto the globalOrderData */}
        They can be found in your <LinkWithSearchParams to={`/closings/${closingId}/closing-documents`} newSearchParams={{component: 'uploads'}}>{toTitleCase(DocumentsText)}</LinkWithSearchParams>.
      </Typography>
    )
  }

  function onClickUploadMore(e: any) {
    // If previous uploads were completed and adding more, start fresh
    if (isComplete) resetFileUploadHook()
  }

  function onClickComplete(e: any) {
    e.preventDefault()
    if (!allPrepared) {
      setCompleteAttempted(true)
    } else {
      setCompleteAttempted(false)
      completeUpload()
    }
  }

  return [
    files.length ? (
      <>
        {isLoading && (
          <Box sx={{ pt: 0.5 }}>
            <Skeleton />
            <br/>
            <Skeleton width="60%" />
            <Skeleton width="60%" />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        )}

        <Typography variant="body2" sx={{ '&.MuiTypography-root': { ml: 1 } }}>{header}</Typography>
        {/* Always render the list if there are files or... Warning: Can't perform a React state update on an unmounted component */}
        <List>
          {theFiles.map((file, idx) => {
            const showError = completeAttempted && !file.DocumentType
            return (
              <>
                {(!isLoading && !isComplete) && <Divider/>}
                <UploadItem key={idx} idx={idx} globalOrderData={globalOrderData} file={file} setFiles={setFiles} files={files} isComplete={isComplete} isLoading={isLoading} error={showError}/>
              </>
            )
          })}
        </List>
        {footer}
      </>
    ) : <Box>No documents selected</Box>,

    // Return actions separately to allow for use in page or as dialog actions
    <>
      {!isLoading && <Button onClick={onClickUploadMore} component="label" variant={(!!files.length) ? 'rounded' : 'contained'} sx={{ mr: 1 }}>
        {!!files.length ? 'I have more to upload' : 'Add document to upload'}
        {uploadInput}
      </Button>}

      {
        (!!files.length && !isLoading && !isComplete) &&
        <Button sx={{ mr: 1 }} variant="contained" onClick={onClickComplete}>
          {`Finish uploading ${files.length} document${files.length > 1 ? 's' : ''}`}
        </Button>
      }
    </>
  ]
}

function UploadItem({ file, globalOrderData, idx, setFiles, files, isComplete, isLoading, error }: {
  file: FileUpload;
  globalOrderData: GetOrderResponse | null;
  idx: number;
  files: FileUpload[];
  setFiles: Function;
  isComplete: boolean;
  isLoading: boolean;
  error: boolean;
}) {
  const { search } = useLocation()
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [createUrlErrorOpen, setCreateUrlErrorOpen] = useState(false);

  const updateFiles = () => {
    setFiles(set([ ...files ], `${idx}`, file))
  }

  const deleteUpload = () => {
    const filesCopy = [...files]
    filesCopy.splice(idx, 1)
    setFiles(filesCopy)
    setDeleteOpen(false)
  }

  const previewImage = () => {
    window.open(URL.createObjectURL(file.file), '_blank')
  }

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    file.DocumentType = event.target.value
    updateFiles()
  };

  const handleUploadError = (error: string) => {
    track('error', 'useCreateUploadUrl', {}, {
      message: JSON.stringify(error),
      component: 'useFileUpload',
    })

    file.isLoading = false
    updateFiles()
    setCreateUrlErrorOpen(true)
  }

  const FileExtension = file.OriginalDocumentFileName.split('.').reverse()[0]
  const { data, error: createUploadUrlError } = useCreateUploadUrl({
    CompanyIdOrderIdHash: globalOrderData?.Order?.CompanyIdOrderIdHash || '',
    FileExtension,
    search,
  })

  const categories = compact(globalOrderData?.Order.FileUploadTypes)

  useEffect(() => {
    if (createUploadUrlError) {
      handleUploadError(`Error getting presigned s3 URL: ${JSON.stringify(createUploadUrlError)}`)
    }

    if (data?.Url) {
      file.DocumentFileName = data.DocumentFileName
      file.DocumentId = data.DocumentId

      fetch(data.Url, {
        method: 'PUT',
        body: file.file,
        headers: {
          'x-amz-server-side-encryption': 'AES256',
          'x-amz-acl': 'bucket-owner-full-control',
        },
      }).then(() => {
        file.isLoading = false
        updateFiles()
      }).catch((err) => {
        // DEV helper For testing with localhost
        // file.isLoading = false
        // updateFiles()

        handleUploadError(`Error uploading to s3 presigned URL: ${JSON.stringify(err)}`)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.Url, createUploadUrlError])


  if (isLoading) return null
  return (
    <>
      {isComplete ?
        (
          <>
            <ListItem>
              <ListItemText
                sx={{ wordBreak: 'break-word' }}
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '700' }}>
                      {getTypeName(file.DocumentType, categories)}
                    </Typography>
                    <Box sx={{ mb: '5px', mt: '-7px' }}>
                      <IconButton
                        key='preview'
                        title='Preview'
                        onClick={previewImage}
                      >
                        <PreviewRounded />
                      </IconButton>
                    </Box>
                  </Box>
                }
                secondary={file.OriginalDocumentFileName}
                secondaryTypographyProps={{ fontSize: '14px', fontWeight: '400', mt: '-15px'  }}
              />
            </ListItem >
          </>
        ) :
        (
          <ListItem key={`${idx}`} sx={{ pr: 2 }}>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ wordBreak: 'break-word', fontSize: '15px', mb: '15px' }}>
                    {file.OriginalDocumentFileName}
                  </Typography>
                  <Box sx={{ flexShrink: 0 }}>
                    <IconButton
                      key='preview'
                      title='Preview'
                      onClick={previewImage}
                    >
                      <PreviewRounded />
                    </IconButton>
                    <IconButton
                      key="delete"
                      title='Delete'
                      onClick={() => setDeleteOpen(!deleteOpen)}
                      sx={{ ml: 1 }}
                    >
                      <CloseRounded />
                    </IconButton>
                  </Box>
                </Box>
              }
              secondary={`Added ${file.DocumentType ? `to ${getTypeName(file.DocumentType, categories)} ` : ''}just now`}
              // disableTypography={}
              secondaryTypographyProps={{ variant: 'light', fontSize: 'x-small', mt: '-15px' }}
            />
            <ListItemSecondaryAction sx={{
              position: 'relative',
              mt: error ? 4 : 3, ml: 4,
            }}>
              <TextField
                error={error}
                helperText={error ? 'Type required' : ''}
                sx={{ width: '95%' }}
                size="small"
                select
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  displayEmpty: true,
                  renderValue: (v: any) => {
                    const label = find(categories, { Code: v })?.Label
                    return label || 'Please select one'
                  }
                }}
                InputLabelProps={{ shrink: true }}
                value={file.DocumentType}
                label="Document Type"
                required={true}
                onChange={handleTypeChange}
              >
                {categories.map(cat => (
                  <MenuItem value={cat.Code} key={cat.Code}>{cat.Label}</MenuItem>
                ))}
              </TextField>
            </ListItemSecondaryAction>
          </ListItem>
        )
      }


      <Dialog
        key="delete"
        open={deleteOpen}
      >
        <DialogTitle>
          Delete Upload
        </DialogTitle>

        <DialogContent>
          Are you sure you want to delete "{file.OriginalDocumentFileName}"?
        </DialogContent>

        <DialogActions>
          <Button variant="rounded" onClick={() => setDeleteOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={deleteUpload}>Delete</Button>
        </DialogActions>
      </Dialog>


      {/* useCreateUploadUrl error dialog */}
      <Dialog
        open={createUrlErrorOpen}
        onClose={() => deleteUpload()}
      >
        <DialogTitle>
              Sorry, something went wrong
        </DialogTitle>
        <DialogContent>
          {`There was a problem preparing "${file.OriginalDocumentFileName}" for upload. Please try again.`}
        </DialogContent>

        <DialogActions>
          <HelpButton />
          <Button onClick={() => {
            deleteUpload()
            setCreateUrlErrorOpen(false)
          }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>


      {/*
          Dialog to preview uploads in the app.
          For now, we're opening uploads in a new tab so simplify pdf and mobile
          upload preview complexity.
      */}
      {/* <Dialog
        key="preview"
        open={previewOpen}
        // sx={{ '& .MuiDialog-paper': { borderRadius: '20px', minHeight: '350px' }}}
        fullWidth={true}
        sx={{ height: '95%' }}
        disableEscapeKeyDown={false}
      >
        <DialogTitle sx={{ lineHeight: '12px' }}>
          <Typography>
            {file.OriginalDocumentFileName}
          </Typography>
          <Typography variant="light" fontSize="small" sx={{}}>
            {getTypeName(file.DocumentType, categories)}
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ margin: 1, border: `2px dashed ${theme.palette.primary.main}` }}>
          <img style={{ objectFit: 'contain', height: '100%', width: '100%' }} src={URL.createObjectURL(file.file)} alt={file.OriginalDocumentFileName} />
        </DialogContent>

        <DialogActions sx={{ mb: 1 }}>
          <Button variant="contained" onClick={() => setPreviewOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog> */}
    </>
  )
}


export function useFileUpload({ multiple, notificationId }: {
  // NOTE: multiple var is currently unused/untested
  multiple?: boolean;
  notificationId?: string;
}) {
  const { search } = useLocation()
  const { globalOrderData } = useOrderDataContext()
  const CompanyIdOrderIdHash = globalOrderData?.Order.CompanyIdOrderIdHash || ''

  const [files, setFiles] = useState<FileUpload[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const shouldPrompt = !!files.length && !isComplete
  const prompt = 'Your uploads are incomplete. Are you sure you wish to leave?'
  useInAppSneezeGuard(prompt, shouldPrompt)
  useBrowserSneezeGuard(shouldPrompt)

  const Files = files.map(f => {
    return {
      OriginalDocumentFileName: f.OriginalDocumentFileName,
      DocumentFileName: f.DocumentFileName,
      DocumentType: f.DocumentType,
      DocumentId: f.DocumentId
    }
  })

  const completeUploadRequest = useCompleteUpload({ CompanyIdOrderIdHash, Files, search, notificationId })
  const { data, error, resetState } = completeUploadRequest

  const [allPrepared, setAllPrepared] = useState(false)

  const resetFileUploadHook = () => {
    setFiles([])
    setIsLoading(false)
    setAllPrepared(false)
    setIsComplete(false)
    setIsComplete(false)
    resetState()
  }

  useEffect(() => {
    setIsLoading(some(files, {isLoading: true}) ? true : false)
    setAllPrepared(every(files, 'DocumentType') ? true : false)
  }, [files, setIsLoading])

  useEffect(() => {
    if (error) {
      track('error', 'useCompleteUpload', {}, {
        message: JSON.stringify(error),
        component: 'useFileUpload',
      })

      throw error
    }

    if (data) {
      setIsComplete(true)
      setIsLoading(false)
    }

  }, [data, error, setIsComplete, completeUploadRequest])

  async function addFile(file: File) {
    const isImageType = file.type.includes(resizableTypeCheck)
    const isImageTooLarge = file.size > maxImageBytes

    console.log('[file upload] - file name: ', file.name, ' - type: ', file.type, ' - size (MB): ', file.size / bytesPerMB)

    if (isImageType && isImageTooLarge) {
      file = await shrinkFile(file)
      console.log('[image resize] resize complete - type: ', file.type, ' - size: ', file.size / bytesPerMB)
    }


    const fileDetails: FileUpload = {
      file,
      OriginalDocumentFileName: file.name,
      isLoading: true,
      // Set later after create link response:
      DocumentFileName: '',
      DocumentId: '',
      // Set by user in dropdown:
      DocumentType: '',
    }

    // For multiple arg to work, this set prob needs to happen after all files
    // are added in #addFiles
    setFiles([...files, fileDetails])
  }

  async function addFiles(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return;
    }
    // NOTE: trying to show loading state while we load images from the input and
    // resize but setting the loading state isn't working in this handler.
    // Maybe related to: https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
    // setIsLoading(() => true)
    const newFiles = Array.from(e.target.files)
    await Promise.all(newFiles.map(addFile));
    e.target.value = ''
    // setIsLoading(() => false)
  }

  function completeUpload() {
    setIsLoading(true)
    completeUploadRequest.refresh()
  }

  // TODO: share list w/ portal-api when they're in the same repo together!
  const allowedExtensions = [
    'png',
    'jpg',
    'jpeg',
    'gif',
    'pdf',
  ]

  const accept = allowedExtensions.map(ext => `.${ext}`).join()

  const uploadInput = <input type="file" accept={accept} hidden multiple={multiple} onChange={addFiles}/>

  let [fileUploadBody, fileUploadActions] = UploadBody({
    globalOrderData,
    isLoading,
    isComplete: !!isComplete,
    allPrepared,
    files,
    setFiles,
    completeUpload,
    uploadInput,
    resetFileUploadHook,
  })

  return { files, uploadInput, fileUploadBody, fileUploadActions, isComplete, resetFileUploadHook }
}
