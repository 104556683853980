import { LoadingButton } from "@mui/lab"
import { TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { track, useCreateInvite } from "../../logic"
import { useOrganization } from "../OrganizationProvider"
import { ErrorDialog } from "./ErrorDialog"
import { Help } from "./Help"

export const RequestInvite = () => {
  const { CompanyDisplayName, PortalDomainName } = useOrganization()
  const [email, setEmail] = useState('')

  const {
    requestInvite,
    resetRequestState,
    loading, error, data
  } = useCreateInvite({ CompanyDomain: PortalDomainName })

  if (error) {
    track('error', 'useCreateInvite', {}, {
      message: JSON.stringify(error),
      component: 'RequestInvite',
      company_domain: PortalDomainName,
    })
  }

  function onErrorDialogTryAgain() {
    resetRequestState()
    // resend request
    requestInvite(email)
  }

  function onErrorDialogClose() {
    resetRequestState()
  }

  // If there's data the invite request has returned successfully and we want
  // to block the request UI and show the success text.
  return data ?
    (
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '800px', m: '0 auto', p: 2
      }}>
        <Box id="alert-dialog-title">
          <Box sx={{
            display:'flex', justifyContent: 'left', alignItems: 'center',
          }}>
            <Typography sx={{
              fontSize: 32,
              fontWeight: 300,
              lineHeight: '32px',
              letterSpacing: '-0.5px',
              mb: 6,
            }}>
            Request sent successfully</Typography>
          </Box>
        </Box>

        <Box>
          <Typography>
            Please check for an invitation email sent to {data?.UserEmail}
          </Typography>
          <br/>
          <Typography sx={{ mb: 3 }}>
            Haven't received your invitation? Please check your spam folder and also confirm that the email
            address entered matches the email at which {CompanyDisplayName} has
            previously contacted you.
          </Typography>

          <Help/>

        </Box>
      </Box>
    ) : (
      <>
        <Box sx={{
          display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '800px', m: '0 auto',
        }}>
          <Typography sx={{
            fontSize: 36,
            fontWeight: 300,
            lineHeight: '36px',
            letterSpacing: '-0.5px',
            mb: 6, ml: 3
          }}>
            Request <br/> your invitation
          </Typography>
          <Box sx={{
            width: '90%', maxWidth: '500px',
            alignSelf: 'center',
          }}>
            <form onSubmit={(e) => {e.preventDefault(); requestInvite(email)}}>
              <TextField
                required
                id="outlined-required"
                label="Email"
                sx={{ width: '100%' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                type="email"
              />
              {/* IMPROVE: look into FormHelperText */}
              <Typography sx={{
                fontSize: 16,
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
                mt: 1, ml: 1,
              }}>
                {/* TODO: add expiration language when applicable */}
                Please enter the email address at which {CompanyDisplayName} has previously
                contacted you. After we verify your information, you will receive an
                email with an invitation to create your account.
              </Typography>

              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                sx={{
                  width: '100%', height: '42px', boxShadow: 'none',
                  fontSize: '16px', fontWeight: '400', mt: 6, mb: 4,
                  bgcolor: 'primary.main', color: 'white', border: 'none',
                }}
              >
                Submit
              </LoadingButton>

              <Help/>

            </form>
          </Box>
        </Box>



        {/* CreateInvite request error dialog*/}
        {error && (
          <ErrorDialog onClose={onErrorDialogClose} onTryAgain={onErrorDialogTryAgain}>
            We had an issue processing your request. Please try again or contact
            us for assistance.
          </ErrorDialog>
        )}
      </>
    )
}
