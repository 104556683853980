import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'

export function ProgressRing({ current, total }: { 
	current: number;
	total: number;
}) {
  const size = 24
  const percent = current / total * 100

  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        sx={{
          color: "#E5E5E5",
          position: 'absolute',
          left: 0,
        }} />

      <CircularProgress
        variant="determinate"
        value={percent}
        size={size}
      />
    </Box>
  )
}
