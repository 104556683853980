import DOMPurify from 'dompurify'

// DOMPurify removes target attr by default
// https://github.com/cure53/DOMPurify/issues/317
const TEMPORARY_ATTRIBUTE = 'data-temp-href-target'

DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
  if (node.tagName === 'A') {
    const target = node.getAttribute('target')

    if (target) {
      node.setAttribute(TEMPORARY_ATTRIBUTE, target)
    }
  }
})

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE) || '')
    node.removeAttribute(TEMPORARY_ATTRIBUTE)
    if (node.getAttribute('target') === '_blank') {
      node.setAttribute('rel', 'noopener')
    }
  }
})

export function SanitizedHtml({ html }: {
  html: string;
}) {
  const __html = DOMPurify.sanitize(html)
  return <span className='sanitized-html' dangerouslySetInnerHTML={{ __html }} />
}
