import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useMemo, useState } from "react";
import makeUrls, { CalendarEvent } from "../../utils/makeCalendarUrls";
import { track } from '../../logic';

type CalendarURLs = ReturnType<typeof makeUrls>;

function CalendarButtonList({ event }: {
	event: CalendarEvent
}) {
  const urls = useMemo<CalendarURLs>(() => makeUrls(event), [event]);

  const onAddToCal = (calType: string) => {
    track('Add to Calendar', calType)
  }

  return (
    <List dense={true}>
      <ListItem>
        <ListItemButton onClick={() => onAddToCal('Apple')} download={event.name} target="_blank" rel="noopener noreferrer" divider={true} component='a' href={urls.ics}>
          <ListItemText sx={{ textAlign: 'center' }} primary="Apple" />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => onAddToCal('Google')} target="_blank" rel="noopener noreferrer" divider={true} component='a' href={urls.google}>
          <ListItemText sx={{ textAlign: 'center' }} primary="Google" />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => onAddToCal('Outlook')} download={event.name} target="_blank" rel="noopener noreferrer" divider={true} component='a' href={urls.ics}>
          <ListItemText sx={{ textAlign: 'center' }} primary="Outlook" />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => onAddToCal('Outlook Web')} target="_blank" rel="noopener noreferrer" divider={true} component='a' href={urls.outlook}>
          <ListItemText sx={{ textAlign: 'center' }} primary="Outlook for Web" />
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => onAddToCal('Yahoo')} target="_blank" rel="noopener noreferrer" divider={true} component='a' href={urls.yahoo}>
          <ListItemText sx={{ textAlign: 'center' }} primary="Yahoo" />
        </ListItemButton>
      </ListItem>
    </List>
  );
}


export function AddToCalendarDialog({ event, setAddToCalendarOpen, addToCalendarOpen }: {
  event: CalendarEvent;
  setAddToCalendarOpen: Function
  addToCalendarOpen: boolean;
}) {


  return (
    <Dialog
      open={addToCalendarOpen}
      onClose={() => setAddToCalendarOpen(false)}
      sx={{ '& .MuiDialog-paper': { borderRadius: '20px' }}}
      fullWidth={true}
      maxWidth='xs'
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: '300', fontSize: '32px' }}>
          Select your calendar
      </DialogTitle>

      <DialogContent>
        <CalendarButtonList event={event} />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setAddToCalendarOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )
};


export function AddToCalendar({ event, disabled }: {
  event: CalendarEvent
  disabled: boolean
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        disabled={disabled}
        sx={{
          display: disabled ? 'none' : 'initial',
          width: '336px', height: '40px', bgcolor: 'white', margin: 'auto',
          borderRadius: '100px',
          '&:hover': { color: 'white', backgroundColor: 'primary.dark' },
        }}
        onClick={() => setOpen(true)} variant="outlined"
      >
        Add to Calendar
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ '& .MuiDialog-paper': { borderRadius: '20px' }}}
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle sx={{ textAlign: 'center', fontWeight: '300', fontSize: '32px' }}>
            Select your calendar
        </DialogTitle>

        <DialogContent>
          <CalendarButtonList event={event} />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
