import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Link, List, SwipeableDrawer, Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { requestInviteParam, usePageTitle, useLocalStorage, loginRedirectParam, loginHintParam } from '../../logic';
import { DoubleCloudBox } from '../shared';
import { Help } from "../shared/Help";
import { Legalese } from "../shared/Legalese";
import { RequestInvite } from "../shared/RequestInvite";

export function LandingPage({ drawerHeight }: {
  drawerHeight: number;
}) {
  const { loginWithRedirect } = useAuth0();

  const [searchParams] = useSearchParams();
  const requestInviteInitially = searchParams.has(requestInviteParam)
  const hasLoginRedirect = searchParams.has(loginRedirectParam)
  const loginHint = searchParams.get(loginHintParam) || ''
  const [open, setOpen] = useState(requestInviteInitially);
  const [hasAuthenticated] = useLocalStorage('hasAuthenticated', false)


  usePageTitle('Welcome!');

  if (hasLoginRedirect) {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname + window.location.search },
      login_hint: loginHint,
    });
  }

  function showRequestInvite() {
    setOpen(true);
  }


  function onClickBigButton() {
    if (hasAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname + window.location.search },
        login_hint: loginHint,
      })
    } else {
      showRequestInvite()
    }
  }

  function onClickLittleButton() {
    if (hasAuthenticated) {
      showRequestInvite()
    } else {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname + window.location.search },
        login_hint: loginHint,
      })
    }
  }



  return (
    <>
      <Backdrop open={hasLoginRedirect} sx={{ color: "#FFFFFF" }} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          maxWidth: "400px",
          ml: "auto",
          mr: "auto",
          mt: { xs: 0, sm: `${drawerHeight}px` },
        }}
      >
        <Box
          sx={{
            display: "flex",
            pr: "20px",
            pl: "20px",
            pt: 9,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              fontSize: "56px",
              lineHeight: "54px",
              fontWeight: 300,
              minWidth: 220,
              pt: { xs: "26px", sm: "44px" },
              marginBottom: "32px",
              marginRight: "10px",
              letterSpacing: "-0.5px",
            }}
          >
            {hasAuthenticated ? "Hello, come right in!" : "Let's get started!"}
          </Box>
          <DoubleCloudBox></DoubleCloudBox>
        </Box>

        <List
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mt: 4,
          }}
        >
          <Button
            onClick={onClickBigButton}
            variant="contained"
            sx={{
              width: "90%",
              height: "42px",
              boxShadow: "none",
              fontSize: "16px",
              fontWeight: "400",
              bgcolor: "primary.main",
              color: "white",
              border: "none",
            }}
          >
            {hasAuthenticated ? "Sign in" : "Create account"}
          </Button>
          <Box
            sx={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              mb: 9,
            }}
          >
            <Box
              sx={{
                mt: "10px",
                display: "flex",
                fontSize: "14px",
              }}
            >
              {hasAuthenticated ? "First time here?" : "Been here before?"}
              &nbsp;
              <Link
                underline="hover"
                component="button"
                onClick={onClickLittleButton}
                sx={{ fontSize: "14px" }}
              >
                {hasAuthenticated ? "Create account" : "Sign in"}
              </Link>
            </Box>
          </Box>

          <Help />
          <Box sx={{ pt: 1 }}>
            <Legalese></Legalese>
          </Box>
        </List>

        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          sx={{
            ".MuiPaper-root": {
              height: `calc(100% - ${drawerHeight + 5}px)`,
              borderRadius: "30px 30px 0 0",
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              // margin to prevent hiding content if content gets too tall for drawer height
              m: `${drawerHeight + 5}px 0`,
            }}
          >
            <RequestInvite />
          </Box>
        </SwipeableDrawer>
      </Box>
    </>
  );
}
