import { createTheme } from '@mui/material/styles';
import { PortalBranding } from '../types';
import "@mui/material/styles/createPalette";

export function createAppTheme(branding: PortalBranding) {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 660, // default is 600, TC choice based on content squishiness at 600
        md: 960, // default is 900, 960 specified in MC design
        lg: 1200,
        xl: 1536,
      },
    },

    palette: {
      primary: {
        main: branding.PrimaryColor,
      },
      // TODO: secondary and common aren't working. primary is. common isn't getting
      // to components at all and secondary is still using the MUI defaults instead
      // of what's passed here.
      secondary: {
        main: '#FFFFFF',
        // contrastText: '#000000',
      },
      common: {
        backgroundGrey: '#F3F3F3',
      },
    },

    typography: {
      light: {
        color: '#ABABAF',
      },
      teeny: {
        color: '#ABABAF',
        fontSize: 10,
      },
      button: {
        textTransform: "none",
      }
    },

    components: {
      MuiButton: {
        variants: [
          // TODO: make a light button version? or include such things in the palette?
          // button seems to fit better into MUI and doesn't require taking over
          // the secondary palette, which we might want in the future.
          {
            props: { variant: 'contained' },
            style: {
              borderRadius: 100,
            }
          },
          {
            props: { variant: 'rounded' },
            style: {
              borderRadius: 100,
            }
          },
        ]
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            zIndex: 2000,
          },
        },
      },
    }
  });

  return theme;
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    rounded: true;
  }
}


declare module '@mui/material/styles' {
  interface TypographyVariants {
    light: React.CSSProperties;
    teeny: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    light?: React.CSSProperties;
    teeny?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    light: true;
    teeny: true;
  }
}

declare module '@mui/material/Stack' {
  interface Stack {
    spacing: number;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    backgroundGrey: string;
  }
}

// declare module '@mui/material/createPalette' {
//   interface IconPaletteColorOptions {
//       pdf?: string;
//       csv?: string;
//   }
//   interface IconPaletteColor {
//       pdf: string;
//       csv: string;
//   }
//   interface PaletteOptions {
//       success?: PaletteColorOptions;
//       warning?: PaletteColorOptions;
//       icon?: IconPaletteColorOptions;
//   }
//   interface Palette {
//       success: PaletteColor;
//       warning: PaletteColor;
//       icon: IconPaletteColor;
//   }
// }
