import { ChevronRight } from '@mui/icons-material'
import { Box, Button, Card, Collapse, IconButton, Typography, useTheme } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { sectionMap, SectionName } from './ClosingPageSectionConfig';
import { LinkWithSearchParams } from './LinkWithSearchParams';

export function Section({ header, title, icon, collapsible, children, secondaryAction, isSelected = false }: {
	title?: string;
	icon?: any;
	collapsible?: boolean;
	isSelected?: boolean;
	children: ReactNode;
	header?: ReactNode;
  secondaryAction?: ReactNode;
}) {
  const [expanded, setExpanded] = useState((!collapsible || isSelected))
  const toggleExpanded = () => setExpanded(prev => !prev)

  // Update expanded state when collapsible changes as a result of resizing
  useEffect(() => {
    setExpanded(!collapsible || isSelected)
  }, [ collapsible, isSelected ])

  header ??= (
    <SectionTitle
      title={title!}
      icon={icon}
      collapsible={collapsible}
      toggleExpanded={toggleExpanded}
      expanded={expanded}
      secondaryAction={secondaryAction}
    />
  )

  return (
    <Card raised sx={{ p: 2, borderRadius: '20px' }}>
      {header}
      <Collapse in={expanded} timeout="auto">
        {children}
      </Collapse>
    </Card>
  )
}

export function SectionTitle({ title, icon, collapsible, expanded = true, toggleExpanded, secondaryAction }: {
	title: string;
	icon: any
	collapsible?: boolean;
	expanded?: boolean;
	toggleExpanded?: () => void;
  secondaryAction?: ReactNode;
}) {

  const theme = useTheme() as any
  const transition = theme.transitions.create(['transform'])

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={expanded ? 2 : 0}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SectionIcon icon={icon} />

        <Typography variant="h6" ml={2} >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {secondaryAction && expanded && (
          <Box sx={{ mr: 2, ml: 2 }}>{secondaryAction}</Box>
        )}
        {collapsible ? (
          <IconButton
            onClick={toggleExpanded}
            sx={{
              marginLeft: "auto",
              transition,
              transform: expanded ? 'rotate(270deg)' : 'rotate(90deg)',
            }}
          >
            <ChevronRight />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  )
}

export function SectionIcon({ icon }: { icon: any }) {
  return (
    <Box
      sx={{
        width: 45,
        minWidth: 45,
        height: 45,
        borderRadius: 45,
        bgcolor: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
      }}
    >
      {icon}
    </Box>
  )
}

export function SectionButtonLink({ title, secondaryTitle, icon, to, searchParams}: {
  title?: string;
  secondaryTitle?: string;
  icon?: any;
  to: SectionName;
  searchParams?: object
}) {
  let too = `./${to}`

  const { pathname } = useLocation()
  const lastPathSegment = pathname.split('/').reverse()[0] as SectionName
  if (sectionMap[lastPathSegment]) {
    too = `./../${too}`
  }

  return (
    <Button
      component={LinkWithSearchParams}
      newSearchParams={searchParams}
      to={too}
      variant="contained"
      sx={{
        borderRadius: '20px', width: '100%', height: '69px',
        backgroundColor: 'white',
        color: 'inherit', '&:hover': { color: 'white' },
        '.MuiButton-endIcon': { ml: 'auto', color: 'primary.main', mr: 1 },
        '.MuiButton-startIcon': { color: 'primary.main', ml: '0' },
      }}
      startIcon={<SectionIcon icon={icon}/>}
      endIcon={<ChevronRight/>}
      fullWidth={true}
    >
      <Box sx={{ ml: 1, mt: secondaryTitle ? 1 : 0 }}>
        <Typography variant="h6" sx={{ lineHeight: '14px' }}>
          {title}
        </Typography>
        <Typography variant="light" fontSize="small" noWrap sx={{ mt: -1 }}>
          {secondaryTitle}
        </Typography>
      </Box>
    </Button>
  )
}
