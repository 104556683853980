import { Box } from '@mui/system'
import YouTube from 'react-youtube'
import { track } from '../../logic'
import { PortalVideo } from '../../types'
import { UnreachableCaseError } from '../../utils'

// DOCS: https://github.com/tjallingt/react-youtube#usage
export const VideoEmbed = ({ video }: {
  video: PortalVideo
}) => {
  switch (video.Type) {
  case 'Youtube':
    return (
      <Box sx={{
        textAlign: 'center', mt: 4,
        // Responsive iframe, SOURCE: https://stackoverflow.com/a/71392723/3344309
        '& iframe': { aspectRatio: '16/9', width: '100%', maxWidth: '600px', height: 'auto !important' }
      }}>
        <YouTube
          videoId={video.Id}
          onPlay={() => track('Play video', video.Id)}
        />
      </Box>
    )

  default:
    throw new UnreachableCaseError(video.Type, 'Unknown Video Type')
  }
}
