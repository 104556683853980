import { useMemo } from 'react'
import { useOrganization } from '../components'

const defaultUiConfig = {
  ClosingText: 'transaction',
  ClosingsText: '',
  TaskText: 'task',
  TasksText: '',
  MessageText: 'message',
  MessagesText: '',
  DocumentText: 'document',
  DocumentsText: '',
  WiringInstructionText: 'wire transfer instruction',
  WiringInstructionsText: '',
  ClosingContactText: 'closing contact',
  ClosingContactsText: '',
  TrackerText: 'status tracker',
  TrackersText: '',
  SigningDetailText: 'signing detail',
  SigningDetailsText: '',
}

/**
 * Returns ui configured content used in the ui.
 */
export function useContent() {
  const org = useOrganization()

  return useMemo(() => {
    let {
      ClosingText,
      ClosingsText,
      TaskText,
      TasksText,
      MessageText,
      MessagesText,
      DocumentText,
      DocumentsText,
      WiringInstructionText,
      WiringInstructionsText,
      ClosingContactText,
      ClosingContactsText,
      TrackerText,
      TrackersText,
      SigningDetailText,
      SigningDetailsText,
    } = Object.assign({}, defaultUiConfig, org.UiConfig?.Content)

    ClosingsText ||= ClosingText + 's'
    TasksText ||= TaskText + 's'
    MessagesText ||= MessageText + 's'
    DocumentsText ||= DocumentText + 's'
    WiringInstructionsText ||= WiringInstructionText + 's'
    ClosingContactsText ||= ClosingContactText + 's'
    TrackersText ||= TrackerText + 's'
    SigningDetailsText ||= SigningDetailText + 's'

    return {
      ClosingText,
      ClosingsText,
      TaskText,
      TasksText,
      MessageText,
      MessagesText,
      DocumentText,
      DocumentsText,
      WiringInstructionText,
      WiringInstructionsText,
      ClosingContactText,
      ClosingContactsText,
      TrackerText,
      TrackersText,
      SigningDetailText,
      SigningDetailsText,
    }
  }, [org])
}
