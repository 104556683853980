import { ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary'
import { track } from '../../logic';
import { ErrorDialog } from './ErrorDialog';

const onError = (error: Error, info: {componentStack: string}) => {
  track('error', 'error boundary', {}, {
    message: error.message,
    error: JSON.stringify(error),
    component_stack: info?.componentStack,
  })
}

// IMPROVE: this could show something behind the dialog, maybe a picture of Todd?
function ErrorFallback({error, resetErrorBoundary}: {
  error: any;
  resetErrorBoundary:  React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <ErrorDialog error={error} errorBoundaryHandler={resetErrorBoundary} />
  )
}

export const ErrorBoundary = ({ children }: {
	children: JSX.Element;
}) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={onError}
      onReset={() => {
        track('try_again', 'error boundary')
        // IMPROVE: anything clever to do here to help? At some point, reloadiing
        // might be baaaad.
        window.location.reload()
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}
