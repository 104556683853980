import { useAuth0 } from "@auth0/auth0-react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, InputAdornment, List, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {  usePageTitle, useGetUnauthedDownload, triggerDownload, track, useContent } from '../../logic';
import { getPageUrl } from "../../utils";
import { DoubleCloudBox, ErrorDialog } from '../shared';
import { Help } from "../shared/Help";
import { Legalese } from "../shared/Legalese";
import { NavLink } from "react-router-dom";
import { useOrganization } from "../OrganizationProvider";

export function UnauthedDownloadPage({ drawerHeight }: {
  drawerHeight: number;
}) {
  usePageTitle('Welcome!');

  const { signingHash = '' } = useParams()
  const { isAuthenticated } = useAuth0();
  const { CompanyDisplayName } = useOrganization()
  const theme = useTheme() as any
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { ClosingsText } = useContent()

  const {
    requestDownloadUrl,
    resetRequestState,
    loading, error, data
    // TODO: cleanup unused pageurl here and in signing component
  } = useGetUnauthedDownload(getPageUrl(), signingHash)

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [pin, setPin] = useState('')

  useEffect(() => {
    if (data?.Url && !downloadUrl) {
      setDownloadUrl(data.Url)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  function onErrorDialogClose() {
    resetRequestState()
  }

  function startDownload() {
    setIsDownloading(true)
    track(`Unauthed Download initialized`, downloadUrl)

    const filename = `${CompanyDisplayName.replaceAll('.', '_')} - e-signing documents`

    triggerDownload(downloadUrl, filename).then(() => {
      setTimeout(() => {
        setIsDownloading(false)
      }, 2000);

      // NOTE: This could be misleading since getting here doesn't reeaally mean it succeeded
      // IMPROVE: download file in a way that allows for confirming success
      track(`Unauthed download complete`, downloadUrl)
    })
  }

  return <>
    <Box sx={{
      maxWidth: '450px', ml: 'auto', mr: 'auto',
      mt: { xs: 0, sm: `${drawerHeight}px` },
    }}>
      <Box sx={{
        display: 'flex', pr: '20px', pl: '20px', pt: 3,
        justifyContent: 'space-between',
      }}>
        <Box sx={{
          fontSize: { xs: '46px', sm: '50px' },
          lineHeight: '54px',
          fontWeight: 300,
          minWidth: 220,
          pt: { xs: '26px', sm: '44px' },
          pl: 1,
          mb: 1,
          mr: '10px',
          letterSpacing: '-0.5px',
        }}>
          Download <br/>your <br/> e-signing <br/>documents
        </Box>
        <DoubleCloudBox></DoubleCloudBox>
      </Box>
      <List sx={{
        width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 3,
      }}>
        { !downloadUrl ? <>
          <Typography sx={{
            fontSize: 26,
            fontWeight: 300,
            lineHeight: '36px',
            letterSpacing: '-0.5px',
            mb: 2, ml: 4,
            alignSelf: 'start'
          }}>
            Please enter your pin
          </Typography>
          <Box sx={{
            width: '90%', maxWidth: '500px',
            alignSelf: 'center',
          }}>
            <form onSubmit={(e) => {e.preventDefault(); requestDownloadUrl(pin)}}>
              <TextField
                required
                id="outlined-required"
                label="Pin"
                sx={{ width: '100%' }}
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                autoComplete="pin"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  autoFocus: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography sx={{
                fontSize: 'smaller',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
                mt: 1, ml: 1,
              }}>
                Your pin will have accompanied the link to this page
              </Typography>

              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                sx={{
                  width: '100%', height: '42px', boxShadow: 'none',
                  fontSize: '16px', fontWeight: '400', mt: 3, mb: 6,
                  bgcolor: 'primary.main', color: 'white', border: 'none',
                }}
              >
                Submit
              </LoadingButton>
            </form>
          </Box>
        </> : <>
          <Box sx={{
            display: 'flex', flexDirection: 'column', alignItems: 'center',
            mt: 3, mb: 7, width: '90%',
          }}>
            <LoadingButton
              loading={isDownloading}
              variant='contained'
              sx={{
                width: '100%',
              }}
              onClick={startDownload}
            >
              Download documents
            </LoadingButton>
            {isAuthenticated ?
              <Button
                component={NavLink}
                to="/closings"
                sx={{
                  width: '100%',
                  mt: 1,
                }}
              >
                View your {ClosingsText}
              </Button> : ''

            }
          </Box>
        </>}

        <Help />

        {(isSmall || !isAuthenticated) ?
          <Box sx={{ pt: 1 }}>
            <Legalese></Legalese>
          </Box>
          : ''
        }
      </List>
    </Box>



    {/* ERROR DIALOG*/}
    {error && (
      // Using empty try again func to just close the dialog. In this case, trying
      // the same req again doesn't make sense, nor does reloading the page so it's
      // just a close button so they can double check their pin etc.
      <ErrorDialog onClose={onErrorDialogClose} onTryAgain={() => {}} tryAgainText="Close">
        We had an issue processing your request. Please try again or contact
        us for assistance.
      </ErrorDialog>
    )}

  </>
}
