import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
} from "@mui/material";
import { Fragment } from "react";
import { useContent } from "../../logic";
import { useParams } from "react-router-dom";
import { PortalNotification, PortalUpload } from "../../types";
import { toTitleCase } from "../../utils";
import { LinkWithSearchParams } from "./LinkWithSearchParams";
import { sortByDate, sortByName } from "../../utils/sortBy";
import { UploadFileItem } from "./ClosingDocumentsSection";
import { LoadingButton } from "@mui/lab";
import { Refresh } from "@mui/icons-material";

export function UploadsListDialog({
  uploads,
  open,
  setOpen,
  refreshList,
  loadingList,
  notification,
}: {
  uploads: PortalUpload[];
  open: boolean;
  setOpen: Function;
  refreshList: Function;
  loadingList: boolean;
  notification?: PortalNotification;
}) {
  const { TaskText, DocumentsText, ClosingText } = useContent();
  const { closingId = "" } = useParams();

  const notificationTitle = notification?.Title || notification?.Form?.DisplayName || "";
  const title = notificationTitle ? `${notificationTitle} - Documents` : `${toTitleCase(TaskText)} Documents`;

  const sortedUploads: PortalUpload[] = uploads.sort(
    (a: PortalUpload, b: PortalUpload) => {
      const dateSort = sortByDate(a.UploadDate, b.UploadDate);
      if (dateSort) return dateSort;
      return sortByName(a.OriginalDocumentName, b.OriginalDocumentName);
    }
  );

  const hasDocs = !!sortedUploads.length;

  return (
    <Dialog key="uploadsList" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <List sx={{ pt: 0 }}>
          {hasDocs &&
            sortedUploads.map((upload, i) => {
              return (
                <Fragment key={i}>
                  <UploadFileItem
                    upload={upload}
                    CompanyIdOrderIdHash={closingId}
                    notification={notification}
                  />
                  <Divider light={true} />
                </Fragment>
              );
            })}
        </List>

        {!hasDocs && (
          <>
            <DialogContentText>
              No documents found for this {TaskText}.
            </DialogContentText>
            <DialogContentText sx={{ mt: 2 }}>
              {
                loadingList
                  ? (<>{`Refreshing ${DocumentsText} list... `} <CircularProgress size={15}/></>)
                  : `Click "Refresh ${toTitleCase(DocumentsText)}" to find recent completions.`
              }
            </DialogContentText>
          </>
        )}

        <DialogContentText
          variant="body2"
          sx={{ "&.MuiTypography-root": { mt: 3 } }}
        >
          All documents uploaded for this {ClosingText} can be found in your{" "}
          <LinkWithSearchParams
            to={`/closings/${closingId}/closing-documents`}
            newSearchParams={{ component: "uploads" }}
          >
            {toTitleCase(DocumentsText)}
          </LinkWithSearchParams>
          .
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ m: 1 }}>
        <LoadingButton
          sx={{
            borderRadius: "40px",
          }}
          startIcon={<Refresh/>}
          loading={loadingList}
          variant="outlined"
          onClick={() => {
            refreshList();
          }}
        >
          Refresh {toTitleCase(DocumentsText)}
        </LoadingButton>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
