import { each } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

// Params that we know we want to remove on navigation
// e.g. component=uploads, which expands and opens the uploads list
export const removeTheseParams = [
  'component'
]

export function useNavigateWithSearchParams() {
  const navigate = useNavigate();
  let { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  return function(to: string, newParams?: object) {
    if (newParams) {
      each(newParams, (val, key) => {
        searchParams.set(key, val)
      })
    }

    each(removeTheseParams, (param) => {
      searchParams.delete(param)
    })

    search = `?${searchParams.toString()}`

    navigate(`${to}${search}`)
  }

}
