import { useState } from "react";
import { LocalStorageKeyType } from "../types";

export const getFromLocalStorage = <T,>(key: LocalStorageKeyType, initialValue: T): T => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (err) {
    console.log(err);
    return initialValue;
  }
}

export const saveToLocalStorage = <T,>(key: LocalStorageKeyType, val: T) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(val));
  } catch (err) {
    console.log(err);
  }
}

export function useLocalStorage<T>(key: LocalStorageKeyType, initialValue: T) {
  const currentValue = getFromLocalStorage(key, initialValue);
  const [storedValue, setStoredValue] = useState(currentValue);

  const setValue = (value: T) => {
    setStoredValue(value);
    saveToLocalStorage(key, value)
  };

  return [storedValue, setValue] as const;
}
