import { BottomNavigation, BottomNavigationAction, Paper, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from "react-router-dom";
import { LinkWithSearchParams } from './LinkWithSearchParams';
import { HolidayVillage, House } from '@mui/icons-material';
import { useContent } from '../../logic';
import { capitalize } from 'lodash';

export function BottomNav({ drawerWidth, drawerHeight }: {
	drawerWidth: number;
	drawerHeight: number;
}) {
  const { closingId, section } = useParams()
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { ClosingText, ClosingsText } = useContent()
  // only show this if mobile layout and you're looking at nested closing page
  if (!isSmall || !closingId) {
    return <></>
  }

  return (
    <Paper sx={{ zIndex: 101, position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={section ? '' : 'current_transaction'}
      >
        <BottomNavigationAction value="transactions" component={LinkWithSearchParams} to="/closings" label={capitalize(ClosingsText)} icon={<HolidayVillage />} />
        <BottomNavigationAction value="current_transaction" component={LinkWithSearchParams} to={`/closings/${closingId}`} label={`${capitalize(ClosingText)} Overview`} icon={<House />} />
      </BottomNavigation>
    </Paper>
  )
}
