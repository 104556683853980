import { CalendarToday, PersonOutline } from '@mui/icons-material';
import { Button, Card, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';
import { PortalOrder, StepProgress } from '../../types';
import { addressOffset, formatClosingDate } from '../../utils';
import { ProgressRing, LinkWithSearchParams } from '../shared';
import houses from '../../../assets/houses.svg';


export function ActiveClosingSummary({ order }: {
	order: PortalOrder;
}) {
  const date = formatClosingDate({order, shortDateTime: true })
  const propertyAddress = order.PropertyAddress
  const fileNumber = order.FileNumber
  const xPosAddressHash = addressOffset(propertyAddress)
  const hideDateTimeFromCard =
  order?.ClosingDetails?.HideClosingDateTimeOnClosingsPage;

  return (
    <Card raised sx={{
      borderRadius: '10px', minWidth: '312px', height: '100%',
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
    }}>
      <Box sx={{
        height: '100px',
        bgcolor: 'primary.light',
        color: 'primary.contrastText',
        flexShrink: 0,
        position: 'relative',
      }}>
        <Box
          sx={{
            height: '100px',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0, left: 0,
              height: '100px',
              width: '100%',
              backgroundRepeat: 'repeat-x',
              backgroundPosition: `${xPosAddressHash || 0}% 100%`,
              backgroundImage: `url(${houses})`,
              mixBlendMode: 'luminosity',
              opacity: 0.5,
              pointerEvents: 'none',
            },
          }}
        >
          <Typography sx={{
            p: 2, pt: 3,
            fontSize: '18px',
            fontWeight: '500',
            lineHeight: '20px',
          }}>
            {propertyAddress.Street}
            <br/>
            {`${propertyAddress.City}, ${propertyAddress.State} ${propertyAddress.Zip}`}
            {fileNumber ? <Typography fontSize="small" fontWeight={400}>#{fileNumber}</Typography> : ''}
          </Typography>
        </Box>
      </Box>

      <Stack sx={{
        flexDirection: 'row', justifyContent: 'space-between', p: 2, height: '100%',
      }}>
        <Stack>
          <ItemDetail icon={<PersonOutline color="primary" />} value={formatRole(order.Role)} />
          { date && !hideDateTimeFromCard && 
						<ItemDetail icon={<CalendarToday color="primary" />} label="Signing date" value={date} />
          }
          <CompletionItemDetail StepProgress={order.StepProgress} />
        </Stack>

        <Button
          color="primary"
          component={LinkWithSearchParams}
          to={`/closings/${order.CompanyIdOrderIdHash}`}
          newSearchParams={{role: order.Role}}
          variant="contained"
          sx={{
            flexShrink: 0,
            marginLeft: '20px',
            alignSelf: 'end',
            width: '130px', height: '39px',
            boxShadow: 'none',
            '&:hover': {
              color: 'white'
            }
          }}
        >
					Review
        </Button>
      </Stack>

    </Card>
  )
}

function ItemDetail({ icon, label, value }: {
	icon?: ReactNode;
	label?: string;
	value: string;
}) {

  const labelEl = label && (
    <Typography variant="light" fontSize="12px">
      {label}
    </Typography>
  )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
      <Box mr={1} sx={{ display: 'flex', alignItems: 'center'}}>
        {icon}
      </Box>

      <Stack sx={{ lineHeight: '10px' }}>
        {labelEl}

        <Typography>
          {value}
        </Typography>
      </Stack>
    </Box>
  )
}

function CompletionItemDetail({ StepProgress }: {
	StepProgress: StepProgress
}) {
  const { StepCount, CurrentStepName, Summary, CompletedStepCount } = StepProgress

  const icon = <ProgressRing current={CompletedStepCount} total={StepCount} />

  return (
    <ItemDetail
      icon={icon}
      label={Summary}
      value={CurrentStepName}
    />
  )
}

function formatRole(role: string) {
  if (!role) return 'Unknown';
  return role.split(/[\s_]+/)
    .map((w: string) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ')
  ;
}
