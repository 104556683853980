import { ThemeProvider } from '@mui/material/styles';
import { createContext, useContext } from 'react';
import { createAppTheme } from '../logic/theme';
import { PortalDomainOrg } from '../types';

export const OrganizationContext = createContext<PortalDomainOrg>(null as any);

export function useOrganization() {
  return useContext(OrganizationContext)
}

export function OrganizationProvider({ children, organization }: {
	children: JSX.Element;
  organization: PortalDomainOrg;
}) {
  const theme = createAppTheme(organization)

  return (
    <OrganizationContext.Provider value={organization}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </OrganizationContext.Provider>
  )
}
