import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { track } from "../../logic";
import { LoadingScreen, HelpButton } from "./";
import CloseIcon from '@mui/icons-material/Close';
import { HolidayVillage } from "@mui/icons-material";
import { NavLink } from "react-router-dom";


export function ErrorDialog({children, error, onTryAgain, onClose, errorBoundaryHandler, waitOnAuth0, tryAgainText }: {
  children?: JSX.Element | string;
  error?: any;
  onTryAgain?: Function;
  onClose?: Function;
  errorBoundaryHandler?: React.MouseEventHandler<HTMLButtonElement>;
  waitOnAuth0?: boolean;
  tryAgainText?: string;
}) {
  const [open, setOpen] = useState(true);
  const { isLoading } = useAuth0()

  let errorMessage = ''

  // Wait on errors if auth is loading to prevent error flashes during login/logout
  // Can pass in a property to skip this if showing the dialog outside of <Auth0Provider>
  if (isLoading && waitOnAuth0 !== false) {
    return <LoadingScreen />
  }

  if (typeof error === 'object') {
    errorMessage = error?.message || error?.userErrorMessage || 'An unknown error occurred'
  } else if (error) {
    // HACK: wrapping in template string until error/types are more thought out/consistent
    errorMessage = `${error}`;
  }

  function handleTryAgain() {
    if (typeof onTryAgain === 'function') {
      onTryAgain()
      setOpen(false)
      return
    }

    track('try_again', 'default error handler');
    setOpen(false)

    // default action: reload page?
    window.location.reload();
  };

  function handleClose() {
    if (onClose) onClose();
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown={true}
      sx={{
        '.MuiPaper-root': { minWidth: '330px' },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{
        display: 'flex', justifyContent: 'space-between', ml: -1
      }}>
        <Box sx={{
          display:'flex', justifyContent: 'left', alignItems: 'center', mr: 4,
        }}>
          <ErrorOutlineIcon fontSize='large' color='error' sx={{ mr: 1 }} />
          <Typography sx={{
            fontWeight: '500', lineHeight: '18px',
          }}>
            Sorry,
            something went wrong
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 6,
            top: 6,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorMessage || children}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ m: 1 }}>
        <HelpButton />
        <Button
          onClick={errorBoundaryHandler || handleTryAgain}
          sx={{
            borderRadius: "100px", textTransform: 'none', ml: 1,
          }}
        >
          {tryAgainText || 'Try again'}
        </Button>
        <Button
          component={NavLink}
          to="/closings"
          reloadDocument
          variant="contained"
          startIcon={<HolidayVillage/>}
          sx={{
            borderRadius: "100px", textTransform: 'none',
            '&:hover': { color: 'white', backgroundColor: 'primary.dark' },
          }}
        >
          Home
        </Button>
      </DialogActions>
    </Dialog>
  )
}
