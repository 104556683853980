import { BoltOutlined, Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { track, useContent, useFileDownload, useGetNotification } from '../../logic';
import { PortalNotificationReference, WiringInstructions } from '../../types';
import { SectionConfig } from './ClosingPageSectionConfig';
import { SanitizedHtml } from './SanitizedHtml';
import { Section } from './Section';
import { VideoEmbed } from './VideoEmbed';
import { useLocation, useParams } from 'react-router-dom';
import { LinkWithSearchParams } from './LinkWithSearchParams';
import { toTitleCase } from '../../utils';
import { UploadsListDialog } from './UploadsListDialog';
import { useState } from 'react';
import { LoadingScreen } from './LoadingScreen';
import { HelpLink } from './Help';


export function WiringInstructionsSection({ collapsible, wiringInstructions, CompanyIdOrderIdHash, sectionConfig, hasWiringInstructionsTask, wiringInstructionsTaskRef }: {
  collapsible?: boolean;
  wiringInstructions: WiringInstructions;
  CompanyIdOrderIdHash: string;
  sectionConfig: SectionConfig
  hasWiringInstructionsTask: boolean;
  wiringInstructionsTaskRef?: PortalNotificationReference;
}) {
  const { WiringInstructionsText } = useContent()

  let { Body, FileName, Video } = wiringInstructions

  // DEV helper:
  // Video = { Type: 'Youtube', Id: 'ek4TwC9owwY' } //@ts-ignore

  const { triggerDownload, isDownloading, setIsDownloading } = useFileDownload({
    CompanyIdOrderIdHash,
    FileName,
    Type: 'wiring-instruction',
  })

  const onClick = function() {
    setIsDownloading(true)
    triggerDownload()
  }

  return (
    <Section
      title={sectionConfig.name}
      icon={<WiringInstructionsIcon />}
      collapsible={collapsible}
    >
      {hasWiringInstructionsTask && wiringInstructionsTaskRef ? (
        <WiringInstructionTaskSection
          wiringInstructionsTaskRef={wiringInstructionsTaskRef}
          wiringInstructions={wiringInstructions}
        />
      ) : (
        <>
          {FileName && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mb: 4,
                mt: 1,
              }}
            >
              <LoadingButton
                loading={isDownloading}
                onClick={onClick}
                endIcon={<Download />}
                variant="outlined"
                sx={{
                  width: "100%",
                  fontSize: "large",
                  borderRadius: "100px",
                  maxWidth: "400px",
                }}
              >
                Download {WiringInstructionsText}
              </LoadingButton>
            </Box>
          )}

          <Typography paragraph>
            <SanitizedHtml html={Body} />
          </Typography>

          {Video && <VideoEmbed video={Video} />}
        </>
      )}
    </Section>
  );
}


export const WiringInstructionTaskSection = ({
  wiringInstructionsTaskRef,
  wiringInstructions,
}: {
  wiringInstructionsTaskRef: PortalNotificationReference;
  wiringInstructions: WiringInstructions;
}) => {
  const isTaskComplete = wiringInstructionsTaskRef.IsComplete;


  return isTaskComplete ? (
    <WiringInstructionCompleteTaskSection
      wiringInstructionsTaskRef={wiringInstructionsTaskRef}
      wiringInstructions={wiringInstructions}
    />
  ) : (
    <WiringInstructionIncompleteTaskSection
      wiringInstructionsTaskRef={wiringInstructionsTaskRef}
      wiringInstructions={wiringInstructions}
    />
  );
};



export const WiringInstructionIncompleteTaskSection = ({
  wiringInstructionsTaskRef,
  wiringInstructions,
}: {
  wiringInstructionsTaskRef: PortalNotificationReference;
  wiringInstructions: WiringInstructions;
}) => {
  const { WiringInstructionsText, TaskText } = useContent();
  const { closingId } = useParams();
  let { Body, Video, TaskIncompleteBody, TaskIncompleteButtonText } =
    wiringInstructions;
  const wiringInstructionsBody = TaskIncompleteBody || Body;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mb: 4,
          mt: 1,
        }}
      >
        <Button
          component={LinkWithSearchParams}
          to={`/closings/${closingId}/notifications/${wiringInstructionsTaskRef.NotificationId}`}
          variant="contained"
          sx={{
            width: "100%",
            fontSize: "large",
            borderRadius: "100px",
            maxWidth: "400px",
            textAlign: "center",
            "&:hover": { color: "white" },
          }}
        >
          {TaskIncompleteButtonText ? TaskIncompleteButtonText : `Complete ${toTitleCase(WiringInstructionsText)} ${toTitleCase(TaskText)}`}
        </Button>
      </Box>
      {wiringInstructionsBody ? (
        <Typography paragraph>
          <SanitizedHtml html={wiringInstructionsBody} />
        </Typography>
      ) : (
        <Typography variant="body2" paragraph>
          <strong>IMPORTANT: </strong>You will receive {WiringInstructionsText} here
          and in the tasks section of this portal. If you receive {WiringInstructionsText} from any other source, please{" "}
          <HelpLink>contact us</HelpLink> immediately.
        </Typography>
      )}

      {Video && <VideoEmbed video={Video} />}
    </>
  );
};


export const WiringInstructionCompleteTaskSection = ({
  wiringInstructionsTaskRef,
  wiringInstructions,
}: {
  wiringInstructionsTaskRef: PortalNotificationReference;
  wiringInstructions: WiringInstructions;
}) => {
  const { WiringInstructionsText } = useContent();
  const { closingId } = useParams();
  const { search } = useLocation();

  const { data, loading, error, refresh } = useGetNotification(
    closingId ?? "",
    wiringInstructionsTaskRef.NotificationId,
    search
  );

  const [downloadUploadsOpen, setDownloadUploadsOpen] = useState(false);

  if (loading && !data) {
    return <LoadingScreen />;
  } else if (error) {
    track(
      "error",
      "useGetNotification",
      {},
      {
        message: JSON.stringify(error),
        component: "WiringInstructionTaskSection",
      }
    );
  }

  const notification = data;

  let { Body, Video, TaskCompleteBody, TaskCompleteButtonText } = wiringInstructions;
  const wiringInstructionsBody = TaskCompleteBody || Body;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mb: 4,
          mt: 1,
        }}
      >
        {!notification && error && (
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant="body2" sx={{ fontWeight: '500', color: 'red' }}>There was a problem getting some of your {WiringInstructionsText} details. Click below to retry.</Typography>
            <LoadingButton
              onClick={refresh}
              loading={loading}
              variant="outlined"
              sx={{
                mt: 2,
                alignSelf: 'center',
                width: 'auto',
                borderRadius: "100px",
                maxWidth: "200px",
              }}
            >
              Retry
            </LoadingButton>
          </Box>

        )}

        {/* Download task uploads dialog */}
        {notification && <>
          <Button
            onClick={() => {
              refresh()
              setDownloadUploadsOpen(true)
            }}
            variant="outlined"
            sx={{
              width: "100%",
              fontSize: "large",
              borderRadius: "100px",
              maxWidth: "400px",
            }}
          >
            {TaskCompleteButtonText ? TaskCompleteButtonText : `Download ${toTitleCase(WiringInstructionsText)}`}
          </Button>
          <UploadsListDialog
            open={downloadUploadsOpen}
            setOpen={setDownloadUploadsOpen}
            uploads={notification?.Uploads || []}
            refreshList={refresh}
            loadingList={loading}
            notification={notification}
          />
        </>
        }
      </Box>

      {wiringInstructionsBody ? (
        <Typography paragraph>
          <SanitizedHtml html={wiringInstructionsBody} />
        </Typography>
      ) : (
        <Typography variant="body2" paragraph>
          <strong>IMPORTANT: </strong>You will receive {WiringInstructionsText} here
          and in the tasks section of this portal. If you receive {WiringInstructionsText} from any other source, please{" "}
          <HelpLink>contact us</HelpLink> immediately.
        </Typography>
      )}

      {Video && <VideoEmbed video={Video} />}
    </>
  );
};


export const WiringInstructionsIcon = () => {
  const theme = useTheme() as any

  return (
    <BoltOutlined sx={{
      stroke: 'white',
      strokeWidth: '1.5px',
      'path': { fill: theme.palette.primary.main }
    }} />
  )
}
