import { useAuth0 } from '@auth0/auth0-react';
import { ExpandLess, ExpandMore, HolidayVillage, House, Settings } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Drawer, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import List from '@mui/material/List';
import { capitalize, uniq } from 'lodash';
import React, { useState } from 'react';
import { NavLink, Outlet, useParams } from "react-router-dom";
import { track, useContent } from '../../logic';
import { humanize, toTitleCase } from '../../utils';
import { useOrderDataContext } from '../OrderDataProvider';
import { useOrganization } from '../OrganizationProvider';
import { RoleDetail } from './ClosingHeader';
import { Legalese } from './Legalese';
import { PortalDomainOrg } from '../../types';
import { SmsPreferenceStatusDialog } from './SmsPreferenceStatusDialog';

export function AppDrawer({ drawerWidth, drawerHeight }: {
	drawerWidth: number;
	drawerHeight: number;
}) {
  const org = useOrganization()
  const theme = useTheme() as any
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { isAuthenticated } = useAuth0()
  const { globalOrderData, globalOrderDataIsLoading, setGlobalOrderData } = useOrderDataContext()
  const { closingId } = useParams()
  const { ClosingsText, ClosingText } = useContent()

  const [notificationsOpen, setNotificationsOpen] = useState(false)

  let { RoleOptions = [] } = globalOrderData || {}

  const multipleRoles = RoleOptions?.length > 1

  const showNotificationsMenuItem = !!globalOrderData?.Order.SmsPreferenceStatus?.phoneNumber

  const topNav = (
    <Box
      component="nav"
    >
      <Drawer
        variant="permanent"
        anchor='top'
        sx={{
          '& .MuiDrawer-paper': {
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxSizing: 'border-box',
            width: '100%',
            height: drawerHeight,
            bgcolor: 'white',
            borderBottom: 'none',
          },
        }}
        open
      >
        <Toolbar sx={{
          height: '100%',
          width: '100%',
          ml: '69px',
          p: '5px',
        }}>
          <CompanyLogo org={org} />
        </Toolbar>
        {isAuthenticated ?
          <Box sx={{ flex: 1, textAlign: 'right' }} >
            <HamburgerMenu></HamburgerMenu>
          </Box> :
          <Box sx={{ flex: 1, minWidth: '69px', }} ></Box>
        }
      </Drawer>
    </Box>
  )

  const leftNav = (
    <>
      <Box
        component="nav"
        sx={{
          width: drawerWidth,
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          variant="permanent"
          anchor='left'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& .MuiDrawer-paper': {
              border: 'none',
              width: drawerWidth,
              // padding: '0 10px',
              boxSizing: 'border-box',
              bgcolor: 'primary.main',
            },
          }}
          open
        >
          <Box sx={{
            backgroundColor: 'white',
            borderBottomLeftRadius: '20px',
          }}>
            <Toolbar sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '35px 0',
              height: '80px',
            }}>
              <CompanyLogo org={org} />
            </Toolbar>

          </Box>
          <Box sx={{ backgroundColor: 'white' }}>
            <Box sx={{
              p: '0 10px', textAlign: 'center', backgroundColor: 'primary.main',
              borderTopRightRadius: '20px',
            }}>
              <List sx={{ mb: 3 }}>
                <Button
                  component={NavLink}
                  to="/closings"
                  variant="contained"
                  sx={{
                    width: '100%', height: '39px', boxShadow: 'none !important',
                    display: 'flex', justifyContent: 'space-between', color: '#FFFFFF',
                    fontSize: '16px', fontWeight: '400', mt: '40px', border: '1px solid #FFFFFF',
                    '&.active': { bgcolor: 'white', border: 'none', color: '#000000', '.MuiButton-endIcon': { color: 'primary.main' },  },
                    '&:hover': { bgcolor: 'primary.light', color: 'white', border: 'none', '.MuiButton-endIcon': { color: 'white' }, },
                    '.MuiButton-endIcon': { color: '#FFFFFF' },
                  }}
                  endIcon={<HolidayVillage/>}
                  end={true}
                >
                  {capitalize(ClosingsText)}
                </Button>

                {closingId &&
                  <Button
                    component={NavLink}
                    to={`/closings/${closingId}`}
                    variant="contained"
                    sx={{
                      width: '100%', height: '39px', boxShadow: 'none !important',
                      display: 'flex', justifyContent: 'space-between', color: '#FFFFFF',
                      fontSize: '16px', fontWeight: '400', mt: 2, border: '1px solid #FFFFFF',
                      '&.active': { bgcolor: 'white', border: 'none', color: '#000000', '.MuiButton-endIcon': { color: 'primary.main' },  },
                      '&:hover': { bgcolor: 'primary.light', color: 'white', border: 'none', '.MuiButton-endIcon': { color: 'white' }, },
                      '.MuiButton-endIcon': { color: '#FFFFFF' },
                    }}
                    endIcon={<House/>}
                    end={true}
                  >
                    {capitalize(ClosingText)} Overview
                  </Button>
                }
              </List>
              <List>
                {(isAuthenticated && multipleRoles) ?
                  <Box sx={{mt: 1}}>
                    <RoleDetail globalOrderData={globalOrderData} />
                  </Box> : ''
                }

                {showNotificationsMenuItem ?
                  <Box sx={{mt: 1}}>
                    <Button
                      onClick={() => setNotificationsOpen(true)}
                      endIcon={<Settings/>}
                      variant='rounded'
                      sx={{
                        width: '100%', height: '39px', boxShadow: 'none !important',
                        display: 'flex', justifyContent: 'space-between', color: '#FFFFFF',
                        fontSize: '16px', fontWeight: '400', mt: 2, border: '1px solid #FFFFFF',
                        '&:hover': { bgcolor: 'primary.light', color: 'white', border: 'none', '.MuiButton-endIcon': { color: 'white' }, },
                      }}
                    >
                      Notifications
                    </Button>
                  </Box> : ''
                }
              </List>

              {isAuthenticated ? <>
                <Divider sx={{ mt: 6, borderColor: '#F3F3F3' }} />
                <Box sx={{ width: '100%' }}>
                  <LogoutButton />
                </Box>
                <Divider sx={{borderColor: '#F3F3F3'}}/>
              </> : ''}

            </Box>
          </Box>
          <Box sx={{ mt: 'auto', pb: 2 }}>
            <Legalese textColor='white'></Legalese>
          </Box>
        </Drawer>
      </Box>

      {/* DIALOGS */}
      { globalOrderData ? <SmsPreferenceStatusDialog open={notificationsOpen} setOpen={setNotificationsOpen}/> : '' }
    </>
  )

  return isSmall || !isAuthenticated ?
    <>
      {topNav}
      <Box sx={{ flexGrow: 1 }}>
        <Outlet context={{ globalOrderData, globalOrderDataIsLoading, setGlobalOrderData }}/>
      </Box>
    </> :

    <>
      {leftNav}
      <Box sx={{ flexGrow: 1 }}>
        <Outlet context={{ globalOrderData, globalOrderDataIsLoading, setGlobalOrderData }}/>
      </Box>
    </>
}

function HamburgerMenu() {
  const { globalOrderData } = useOrderDataContext()
  const { Role = '', RoleOptions = [] } = globalOrderData || {}


  const { closingId } = useParams()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [roleExpanded, setRoleExpanded] = useState(false)
  const {setLogoutOpen, LogoutConfirmDialog} = useLogoutConfirm()

  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const showNotificationsMenuItem = !!globalOrderData?.Order.SmsPreferenceStatus?.phoneNumber

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setRoleExpanded(false)
  };

  return (
    <>
      <IconButton
        size='large'
        aria-label="menu"
        sx={{ m: '0 5px' }}
        onClick={handleClick}
      >
        <MenuIcon sx={{ width: '35px', height: 'auto', color: 'primary.main' }} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiPaper-root': {
            minWidth: '150px',
            borderRadius: '20px',
          }
        }}
      >
        {RoleOptions.length > 1 ? [
          <MenuItem
            key='role-label'
            onClick={() => setRoleExpanded(v => !v)}
            sx={{ justifyContent: 'space-between' }}
          >
            {toTitleCase(humanize(Role))}
            <ListItemIcon sx={{ justifyContent: 'right' }}>
              {roleExpanded ? <ExpandLess/> : <ExpandMore/>}
            </ListItemIcon>
          </MenuItem>,

          uniq(RoleOptions).map((role) => (
            <MenuItem
              key={role}
              disabled={role === Role}
              sx={{
                display: roleExpanded ? 'flex' : 'none',
                justifyContent: 'center',
                color: role === Role ? 'white' : 'inherit',
                '&.Mui-disabled': { backgroundColor: 'primary.light', opacity: 1 }
              }}
              onClick={() => {
                (window as Window).location = `/closings/${closingId}?role=${role}`
              }}
            >
              {toTitleCase(humanize(role))}
            </MenuItem>
          )),
          <Divider key='divider-roles' sx={{ borderColor: 'primary.main' }} />
        ]: ''}

        {showNotificationsMenuItem ?
          [
            <MenuItem
              key='notifications-button' sx={{ justifyContent: 'space-between'}}
              onClick={() => setNotificationsOpen(true)}
            >
              Notifications
              <ListItemIcon sx={{ justifyContent: 'right' }}>
                <Settings fontSize='small' />
              </ListItemIcon>
            </MenuItem>,
            <Divider key='divider-notifications' sx={{ borderColor: 'primary.main' }} />
          ] : ''
        }

        <MenuItem key='logout-button' sx={{ justifyContent: 'center'}} onClick={() => {
          setLogoutOpen(true)
          handleClose()
        }}>Logout</MenuItem>
      </Menu>


      {/* DIALOGS */}
      { globalOrderData ? <SmsPreferenceStatusDialog open={notificationsOpen} setOpen={setNotificationsOpen}/> : '' }
      {LogoutConfirmDialog}

    </>
  )
}

function LogoutButton() {
  const {setLogoutOpen, LogoutConfirmDialog} = useLogoutConfirm()

  return (
    <>
      <Button onClick={() => setLogoutOpen(true)} sx={{
        color: 'white', width: 'inherit', p: 2, pt: 1, pb: 1,
        '&:hover': { backgroundColor: 'primary.light', color: 'white' },
      }}>
        Logout
      </Button>

      {LogoutConfirmDialog}
    </>
  )
}

function useLogoutConfirm() {
  const [logoutOpen, setLogoutOpen] = useState(false)
  const { logout } = useAuth0()

  const doLogout = () => {
    track('Logout', 'Manual logout');
    logout({ returnTo: `${window.location.origin}` })
  }

  return {
    setLogoutOpen,
    LogoutConfirmDialog: (
      <Dialog
        key="logout"
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
      >
        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={() => setLogoutOpen(false)}>No, I'll stay</Button>
          <Button variant="rounded" onClick={doLogout}>Yes, logout</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

function CompanyLogo({ org }: {
	org: PortalDomainOrg;
}) {
  return (
    <Button
      component={NavLink}
      to="/closings"
      role="img"
      aria-label={`logo for ${org.CompanyDisplayName}`}
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url("${org.LogoUrl}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'contain',
        '&:hover': {
          backgroundColor: 'initial',
        }
      }}
    ></Button>
  )
}
