export function getScopes(MfaRequired?: boolean) {
  if (MfaRequired) {
    return 'enroll remove:authenticators read:authenticators'
  } else {
    return ''
  }
}

export function getAudience(MfaRequired?: boolean) {
  if (MfaRequired) {
    return `https://${process.env.REACT_APP_AUTH0_DOMAIN_FOR_MFA_CLAIM!}/mfa/`
  } else {
    return `https://${process.env.REACT_APP_AUTH0_DOMAIN_FOR_MFA_CLAIM!}/api/v2/`
  }
}