import { Box, SvgIcon } from "@mui/material";
import { ReactComponent as Cloud } from '../../../assets/cloud.svg';

export const DoubleCloudBox = () => (
  <Box sx={{
    width: { xs: '100px', sm: '145px'},
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  }}>
    <SvgIcon component={Cloud} viewBox="12 -1 24 24" sx={{
      stroke: '#E5E5E5', alignSelf: 'end', height: 'auto', width: { xs: '32px', sm: '45px' },
      mb: '10px',
      'path': { fill: '#E5E5E5' },
    }}/>
    <SvgIcon component={Cloud} viewBox="12 -1 24 24" sx={{
      stroke: '#E5E5E5', alignSelf: 'start', height: 'auto', width: { xs: '77px', sm: '110px' },
      'path': { fill: '#E5E5E5' },
    }}/>
  </Box>
)
