import { Box, Button, Checkbox, FormControlLabel, FormGroup, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { track, useCompleteMfaEnrollment, useStartMfaEnrollment, useUpdateSmsPreferenceStatus } from '../logic';
import { Help } from './shared';
import { SmsPreferenceStatus } from '../types';

export function MfaEnrollmentPrompt({ onSuccess, redactedPhoneNumber, orderKey, SmsPreferenceStatus }: {
  redactedPhoneNumber?: string;
  onSuccess: () => void;
  orderKey: string;
  SmsPreferenceStatus: SmsPreferenceStatus;
}) {
  const [code, setCode] = useState('')
  const [oobCode, setOobCode] = useState('')
  const [oobChannel, setOobChannel] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  let { search } = useLocation()

  // NOTE: This doesn't work here so using default "transactions"
  // const { ClosingsText } = useContent()

  const [consentState, setConsentState] = useState({
    phoneNumber: SmsPreferenceStatus?.phoneNumber || '',
    consent: SmsPreferenceStatus?.consent ?? true
  });

  const startMfaRequest = useStartMfaEnrollment({ orderKey, search, oobChannel })
  const completeMfaRequest = useCompleteMfaEnrollment({ oobCode, code, orderKey, search })
  const updateSmsPreferenceStatus = useUpdateSmsPreferenceStatus({ orderKey, search, consentState})

  const drawerHeight = 80

  const open = true

  const requestCode = () => startMfaRequest.refresh()

  const completeEnrollment = (e: any) => {
    e.preventDefault()
    completeMfaRequest.refresh()
  }

  const selectOobChannel = (oobChannel: string) => {
    setOobChannel(oobChannel)
    requestCode()
  }

  const submitCommPreferences = () => updateSmsPreferenceStatus.refresh()

  const handleCommSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentState((prev) => ({
      ...prev,
      consent: event.target.checked,
    }))
  };

  // Handle start enrollment response
  useEffect(() => {
    const oobCode = startMfaRequest.data?.oobCode
    const error = startMfaRequest.error

    console.log('startMfaRequest')

    if (oobCode) {
      setOobCode(oobCode)
    }

    if (error) {
      const userErrorMessage = (error as any).userErrorMessage || 'Unknown error'
      setErrorMessage(userErrorMessage)

      track('error', 'useStartMfaEnrollment', {}, {
        message: JSON.stringify(error),
        component: 'MfaEnrollmentPrompt',
      })
    }
  }, [startMfaRequest])

  // Handle complete enrollment response
  useEffect(() => {
    const { data, error } = completeMfaRequest
    if (data) {
      onSuccess()
    }

    if (error) {
      let userErrorMessage = (error as any).userErrorMessage || 'Unknown error'
      if (userErrorMessage === 'Invalid binding_code.') {
        userErrorMessage = 'Invalid code. Please check your code or resend code below.'
      }

      setErrorMessage(userErrorMessage)

      track('error', 'useCompleteMfaEnrollment', {}, {
        message: JSON.stringify(error),
        component: 'MfaEnrollmentPrompt',
      })
    }
  }, [completeMfaRequest, onSuccess])

  useEffect(() => {
    if (updateSmsPreferenceStatus.error) {
      track('error', 'useUpdateSmsPreferenceStatus', {}, {
        message: 'error saving sms prefs',
        component: 'MfaEnrollmentPrompt',
        error: JSON.stringify(updateSmsPreferenceStatus.error),
      })
    }
  }, [updateSmsPreferenceStatus])

  return <SwipeableDrawer
    anchor='bottom'
    open={open}
    onOpen={() => console.log('MfaEnrollmentPrompt: onOpen')}
    onClose={() => console.log('MfaEnrollmentPrompt: onClose')}
    sx={{
      '.MuiPaper-root': {
        height: `calc(100% - ${drawerHeight + 5}px)`,
        borderRadius: '30px 30px 0 0',
      }
    }}
  >
    <form onSubmit={completeEnrollment}>
      <Box sx={{
        height: '100%', maxWidth: '400px',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        flexDirection: 'column',
        // margin to prevent hiding content if content gets too tall for drawer height
        m: `${drawerHeight + 5}px auto`,
        pl: '10px', pr: '10px'
      }}>

        { oobChannel ?
          <>
            <strong>For your security, we need to verify this is you.</strong>
            <br />

            Please enter the code sent to {redactedPhoneNumber}.

            <br />
            <br />

            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Enter Verification Code"
              value={code}
              onChange={e => setCode(e.currentTarget.value)}
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
              required
            />

            <Button
              variant="contained" sx={{ m: 2 }}
              type="submit"
            >
              Submit Verification Code
            </Button>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Button onClick={requestCode} variant="text" sx={{ mb: 2, whiteSpace: 'nowrap' }}>
                Resend code
              </Button>

              <Help />
            </Box>
          </>
          :
          <>
            <Box>
              <strong>For your security, we need to verify this is you.</strong>
              <br/>
              A verification code will be sent to {redactedPhoneNumber}.
            </Box>

            {SmsPreferenceStatus?.phoneNumber ?
              <>
                <Box sx={{ p: 2, mt: 3, mb: 2, ml: 1, mr: 1, backgroundColor: '#F3F3F3', borderRadius: '10px', }}>
                  <FormGroup sx={{}}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={consentState.consent} onChange={handleCommSettingsChange}/>
                      }

                      label={<Typography variant='body2'>I'd also like to receive text message updates about my transactions at {consentState.phoneNumber}</Typography>}
                    />
                  </FormGroup>
                </Box>
              </> : <></>
            }

            <Box mb={1} mt={2}>
              <Typography sx={{
                fontSize: '14px',
                lineHeight: '16px',
                letterSpacing: '0.5px',
              }}>
                How do you want to receive the code?
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Button
                onClick={() => {
                  selectOobChannel('sms')
                  submitCommPreferences()
                }}
                variant="contained"
                sx={{ mr: 1 }}
              >
                Text message
              </Button>

              <Button
                onClick={() => {
                  selectOobChannel('voice')
                  submitCommPreferences()
                }}
              >
                Voice call
              </Button>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1 }}>


              <Help />
            </Box>
          </>
        }

        {/* <RequestInvite /> */}
      </Box>
    </form>
  </SwipeableDrawer>
}
