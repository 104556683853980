import { Box, SvgIcon, Typography, useTheme } from '@mui/material';
import { ReactComponent as NothingFound } from '../../../assets/nothing-found.svg';

export const NothingHere = ({ msg, children }: {
  msg?: string
  children?: JSX.Element;
}) => {
  const theme = useTheme() as any
  const color = theme.palette.primary.main


  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', textAlign: 'center',
      alignItems: 'center', justifyContent: 'center', mt: '80px',
    }}>
      <SvgIcon component={NothingFound}
        viewBox="0 0 48 48"
        sx={{
          animation: 'myOrbit 6s linear infinite',
          height: 'auto', width: '70px', mb: '20px',
          '.smile-background': { fill: '#F3F3F3' },
          '.face': { fill: color },
          '.ring': { fill: color },
        }}
      />
      <Typography sx={{ fontSize: 'larger' }}>
        {msg || children}
      </Typography>
    </Box>
  )
}
