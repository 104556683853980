import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, SelectChangeEvent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { GetOrderResponse } from '../../types';
import houses from '../../../assets/houses.svg';
import { addressOffset, formatClosingDate, getCalStartDate, getCalEndDate, humanize, toTitleCase } from '../../utils';
import { useContent, useNavigateWithSearchParams, usePageTitle } from '../../logic';
import { LoadingScreen, AddToCalendarDialog, SanitizedHtml } from './';
import { useEffect, useState } from 'react';
import { uniq } from 'lodash';
import { useOrderDataContext } from '../OrderDataProvider';
import { ClosingAddressUi, ClosingDetailsHeader, ClosingTime } from './ClosingDetailsSection'

export const ADDRESS_CHARS_REGEX = /[a-zA-Z0-9]+/


export function RoleDetail({ globalOrderData }: {
  globalOrderData: GetOrderResponse | null;
}) {
  const { closingId } = useParams()
  let { Role, RoleOptions = [] } = globalOrderData || {}

  if (!Role) return null;

  function onChangeRole(e: SelectChangeEvent) {
    (window as Window).location = `/closings/${closingId}?role=${e.target.value}`
  }

  return (
    <Select
      size='medium'
      label='Role'
      sx={{
        textAlign: 'left',
        color: '#FFFFFF',
        height: '39px',
        borderRadius: '30px',
        width: '100%',
        mt: '5px',
        '.MuiSelect-select': { pl: 2, pr: 2 },
        '.MuiSelect-icon': { color: '#FFFFFF' },
        '.MuiOutlinedInput-notchedOutline': { borderColor: '#FFFFFF', },

        '&:hover': {
          bgcolor: 'primary.light', color: 'white', border: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 'none' },
        },
      }}
      // autoWidth={true}
      value={Role}
      defaultValue={Role}
      onChange={onChangeRole}
    >
      {uniq(RoleOptions).map((role) => {
        return <MenuItem key={role} value={role}>{toTitleCase(humanize(role))}</MenuItem>
      })}
    </Select>
  )
}


export function ClosingHeader() {
  const { section } = useParams()
  const theme = useTheme() as any
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const onlySection = isSmall && section
  const { globalOrderData, globalOrderDataIsLoading, setGlobalOrderData } = useOrderDataContext()
  const [addToCalendarOpen, setAddToCalendarOpen] = useState(false)

  let [searchParams] = useSearchParams();
  const component = searchParams.get('component')
  const isSigningDetailsLink = component === 'signing-details'
  const isAddToCalLink = component === 'add-to-cal'
  const { SigningDetailsText } = useContent()


  const hasParseableTime = globalOrderData ? !!getCalStartDate(globalOrderData.Order) : false

  const openSigningDetails = isSigningDetailsLink
  const openAddToCal = hasParseableTime && isAddToCalLink

  const [closingDetailsExpanded, setClosingDetailsExpanded] = useState(openSigningDetails || openAddToCal);
  const [closingDetailsDialogOpen, setClosingDetailsDialogOpen] = useState(openSigningDetails);

  usePageTitle(globalOrderData?.Order?.PropertyAddress?.Street)

  useEffect(() => {
    if (openSigningDetails && !closingDetailsExpanded) {
      setClosingDetailsExpanded(true)
    }
    if (openSigningDetails && !closingDetailsDialogOpen) {
      setClosingDetailsDialogOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSigningDetails])

  useEffect(() => {
    if (openAddToCal && !addToCalendarOpen) {
      setAddToCalendarOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAddToCal])

  // TODO: any better way to handle lack of globalOrderData?
  if (globalOrderDataIsLoading || !globalOrderData) {
    return <LoadingScreen />
  }

  const order = globalOrderData!.Order
  const propertyAddress = order.PropertyAddress
  const closingAddress = order.ClosingAddress
  const fileNumber = order.FileNumber

  const hasFullAddress = ADDRESS_CHARS_REGEX.test(closingAddress.Full || '')

  const hasGranularAddress =
    ADDRESS_CHARS_REGEX.test(closingAddress.Street || '') &&
    ADDRESS_CHARS_REGEX.test(closingAddress.City || '') &&
    ADDRESS_CHARS_REGEX.test(closingAddress.State || '') &&
    ADDRESS_CHARS_REGEX.test(closingAddress.Zip || '')
  ;

  const time = formatClosingDate({ order })
  const calStartDate = getCalStartDate(order)

  const allClosingDetailsHTML = order.ClosingDetails?.AllClosingDetailsHTML
  const hasAnyDetails = (hasFullAddress || hasGranularAddress) || time || allClosingDetailsHTML

  const propertyAddressName = propertyAddress?.Street || propertyAddress?.Full

  const calEvent = {
    name: propertyAddressName ? `Signing - ${propertyAddressName}` : 'Signing',
    details: '',
    location: closingAddress?.Full,
    startsAt: calStartDate,
    endsAt: getCalEndDate(calStartDate),
  }

  const xPosAddressHash = addressOffset(propertyAddress)

  const mobileSectionOnly = (
    <Box height='100%' p='0'>
      <Box sx={{
        borderBottomLeftRadius: '20px',
        display: 'flex', alignItems: { xs: 'initial', sm: 'center' }, padding: 2, bgcolor: 'primary.main',
        color: 'primary.contrastText', borderRadius: '0 0 0 20px',
        height: '92px',
      }}>
        <BackButton />

        <Box pl={3}>
          <div style={{ fontSize: '1.13rem', lineHeight: '20px', fontWeight: 500 }}>
            <Typography sx={{
              fontSize: '18px',
              fontWeight: '500',
              lineHeight: '20px',
            }}>
              {propertyAddress.Street}
              <br/>
              {`${propertyAddress.City}, ${propertyAddress.State} ${propertyAddress.Zip}`}
            </Typography>
            {fileNumber ? <Typography fontSize="small" fontWeight={300}>#{fileNumber}</Typography> : ''}
          </div>
        </Box>
      </Box>
      <Box sx={{ height: 'auto', backgroundColor: 'primary.main' }}>
        <Stack
          direction='column' spacing={2} height='100%'
          sx={{
            p: 2, borderLeft: 'none',
            backgroundColor: '#F3F3F3', borderTopRightRadius: '20px',
          }}
        >
          <Outlet context={{ globalOrderData, globalOrderDataIsLoading, setGlobalOrderData }}/>
        </Stack>
      </Box>
    </Box>
  )

  // NOTE: this header is still using the old blend mode (see mixBlendMode elsewhere)
  // because it doesn't have text over the top to require more opacity in the background.
  // We could change it in the future if we want it to look more consistent. I think I
  // prefer the extra contrast in the background here.
  const mobile = (
    <Box height='100%' p='0'>
      <Box sx={{
        backgroundImage: `url(${houses})`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: `${xPosAddressHash || 0}% 100%`,
        borderBottomLeftRadius: '20px', flexDirection: 'column',
        display: 'flex', padding: 2, bgcolor: 'primary.main',
        color: 'primary.contrastText', borderRadius: '0 0 0 20px',
        backgroundBlendMode: 'luminosity',
      }}>
        <Box sx={{ display: 'flex' }}>
          <BackButton />

          <Box
            sx={{ pl: 3, width: '100%' }}
          >
            <div style={{ fontSize: '1.13rem', lineHeight: '20px', fontWeight: 500 }}>
              <Typography sx={{
                fontSize: '18px',
                fontWeight: '500',
                lineHeight: '20px',
              }}>
                {propertyAddress.Street}
                <br/>
                {`${propertyAddress.City}, ${propertyAddress.State} ${propertyAddress.Zip}`}
              </Typography>
              {fileNumber ? <Typography fontSize="small" fontWeight={300}>#{fileNumber}</Typography> : ''}            </div>
          </Box>

        </Box>

        <ClosingDetailsHeader
          event={calEvent} setAddToCalendarOpen={setAddToCalendarOpen} addToCalendarOpen={addToCalendarOpen}
          order={globalOrderData!.Order} closingDetailsExpanded={closingDetailsExpanded}
          setClosingDetailsExpanded={setClosingDetailsExpanded} time={time}
          hasParseableTime={hasParseableTime} hasGranularAddress={hasGranularAddress} hasFullAddress={hasFullAddress}
        />

      </Box>
      <Box sx={{ height: 'auto', backgroundColor: 'primary.main' }}>
        <Stack
          direction='column' spacing={2} height='100%'
          sx={{
            p: 2, borderLeft: 'none',
            backgroundColor: '#F3F3F3', borderTopRightRadius: '20px',
          }}
        >
          <Outlet context={{ globalOrderData, globalOrderDataIsLoading, setGlobalOrderData }}/>
        </Stack>
      </Box>
    </Box>
  )

  const desktop = (
    <>
      <Box height='100%' p='30px 0 0 20px'>
        <Box sx={{
          display: 'flex', alignItems: 'center', padding: 2, bgcolor: 'primary.main',
          color: 'primary.contrastText', borderRadius: { xs: '0 0 0 20px', sm: '20px 0 0 0'},
          height: '92px',
        }}>
          <BackButton />

          <Box pl={3} >
            <div style={{ fontSize: '1.13rem', lineHeight: '20px', fontWeight: 500 }}>
              <Typography sx={{
                fontSize: '18px',
                fontWeight: '500',
                lineHeight: '20px',
              }}>
                {propertyAddress.Street}
                <br/>
                {`${propertyAddress.City}, ${propertyAddress.State} ${propertyAddress.Zip}`}
              </Typography>
              {fileNumber ? <Typography mb={'-5px'} fontSize="small" fontWeight={300}>#{fileNumber}</Typography> : ''}
            </div>
          </Box>
          <Box sx={{ height: '90px', display: 'flex', ml: 'auto', flexBasis: '40%', maxWidth: '270px'  }}>
            {hasAnyDetails ?
              <Button
                sx={{
                  height: '40px', bgcolor: 'white', margin: 'auto', flexGrow: 2,
                  borderRadius: '100px',
                  '&:hover': { bgcolor: 'primary.dark', color: 'white', border: 'none' },
                }}
                onClick={() => setClosingDetailsDialogOpen(true)} variant="outlined"
              >
                <Box sx={{  }}>{toTitleCase(SigningDetailsText)}</Box>
              </Button> : ''
            }

            {hasParseableTime ?
              <AddToCalendarDialog
                event={calEvent}
                setAddToCalendarOpen={setAddToCalendarOpen}
                addToCalendarOpen={addToCalendarOpen}
              /> : ''
            }


          </Box>
        </Box>
        <Box sx={{ height: '100%', backgroundColor: 'primary.main' }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }} spacing={2} height='100%'
            sx={{
              p: 2, borderLeft: '1px solid #CECECE',
              backgroundColor: '#F3F3F3', borderTopRightRadius: '0',
            }}
          >
            <Outlet context={{ globalOrderData, globalOrderDataIsLoading, setGlobalOrderData }}/>
          </Stack>
        </Box>
      </Box>

      <Dialog
        open={closingDetailsDialogOpen}
        sx={{ '& .MuiDialog-paper': { borderRadius: '20px', minWidth: '350px' }}}
        onClose={() => setClosingDetailsDialogOpen(false)}
      >
        <DialogTitle>{toTitleCase(SigningDetailsText)}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between', mt: 3,
              textAlign: 'center', ml: 'auto', mr: 'auto',
            }}
          >
            <Box sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'left',
              '.sanitized-html': { textAlign: 'left' },
            }}>
              {allClosingDetailsHTML ?
                <SanitizedHtml html={allClosingDetailsHTML} /> :
                <>
                  <ClosingTime order={order} />
                  <ClosingAddressUi address={closingAddress} hasGranularAddress={hasGranularAddress} hasFullAddress={hasFullAddress} />
                </>
              }
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{m: 1}}>
          <Button variant="rounded" onClick={() => setClosingDetailsDialogOpen(false)}>Close</Button>
          <Button
            variant="contained"
            onClick={() => {
              setClosingDetailsDialogOpen(false)
              setAddToCalendarOpen(true)
            }}
            sx={{
              display: (hasParseableTime && !allClosingDetailsHTML) ? 'initial' : 'none'
            }}
          >
            Add to calendar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )

  return isSmall
    ? onlySection ? mobileSectionOnly : mobile
    : desktop
  ;
}


export function BackButton() {
  const navigateWithSearchParams = useNavigateWithSearchParams();
  const { pathname } = useLocation()
  // const goBack = () => navigate('../')
  const goBack = () => {
    // TODO: based on size/layout the back button should maybe function differently
    // Right now, if you're in a layout that displays all sections and your url
    // is closings/<id>/<section>, the back button just strips the section which
    // doesn't actually change the page. Could check size/etc and slice an extra?
    const parentPathname = pathname.split('/').slice(0, -1).join('/')
    navigateWithSearchParams(parentPathname)
  }

  return (
    <Button
      startIcon={<ChevronLeft />}
      onClick={goBack}

      sx={{
        width: 40,
        minWidth: 40,
        height: 41,
        background: 'white',
        ml: '-16px',
        mt: { xs: '-4px', sm: '18px' },
        borderRadius: '0px 10px 10px 0px',
        '&:hover': { backgroundColor: 'primary.light', color: 'primary.contrastText' },
        '.MuiButton-startIcon': { marginRight: '0px'},
      }}
    />
  )
}
