import { Box, Divider, Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { compact, find, findIndex, set } from 'lodash';
import { track, useGetNotification } from "../../logic";
import { LoadingScreen } from "../shared";
import { formatDate, taskTypes } from "../../utils";
import { SanitizedHtml } from "../shared/SanitizedHtml";
import { PortalTaskComponent } from '../shared/PortalTaskComponent';
import { useOrderDataContext } from "../OrderDataProvider";
import { NotificationPagination } from "../shared/NotificationPagination";
import { GetOrderResponse } from "../../types";
import { useEffect } from "react";


export function NotificationPage() {
  const { closingId, id } = useParams()
  const { search } = useLocation()
  const { data, loading, error, refresh } = useGetNotification(closingId ?? '', id ?? '', search)
  const { globalOrderData, setGlobalOrderData } = useOrderDataContext()

  useEffect(() => {
    // On mount, update the read receipt for the messages list to stay up to date
    setGlobalOrderData((prev: GetOrderResponse) => {
      const globalOrderData = { ...prev }
      const notificationList = compact(globalOrderData?.Notifications)
      const notificationIndex = findIndex(notificationList, { NotificationId: id })
      set(globalOrderData || {}, `Notifications[${notificationIndex}].ReadReceipt`, true)
      return globalOrderData
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (loading && !data) {
    return <LoadingScreen />
  } else if (error) {
    track('error', 'useGetNotification', {}, {
      message: JSON.stringify(error),
      component: 'NotificationPage',
    })

    throw error
  }

  const notificationList = compact(globalOrderData?.Notifications)
  const notificationData = find(notificationList, { NotificationId: id })
  const notificationIndex = findIndex(notificationList, { NotificationId: id })

  const notification = data!
  const date = formatDate({dateLike: notificationData?.CreatedDateTime}) || ''

  const { Form } = notification

  const showTaskComponent = taskTypes.includes(notification.Type)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography variant="h5" sx={{ fontWeight: '500', lineHeight: '22px', m: 1, mb: 2, wordBreak: 'break-word' }}>
        {notification.Title || Form?.DisplayName || ''}
      </Typography>
      <Divider />
      <Typography variant="light" fontSize="small" sx={{ mt: 2, mb: 3, ml: 1 }}>
        {date}
      </Typography>
      {!!notification.Content && (
        <Typography sx={{ pl: 1, pr: 1, wordBreak: 'break-word', pb: 2 }}>
          <SanitizedHtml html={notification.Content} />
        </Typography>
      )}

      {
        showTaskComponent ?

          <Box sx={{ m: 1 }}>
            <PortalTaskComponent
              notificationIndex={notificationIndex}
              form={Form}
              notification={notification}
              order={globalOrderData!.Order}
              refreshNotification={refresh}
              loadingNotification={loading}
            />
          </Box>

          :

          // Convenience button for next unread message. If there's a form/task, the forms component handles this separately
          <NotificationPagination />
      }

    </Box>
  )
}
