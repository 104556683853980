import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, FormGroup } from "@mui/material";
import { useOrderDataContext } from "../OrderDataProvider";
import { useEffect, useState } from "react";
import { track, useContent, useUpdateSmsPreferenceStatus } from "../../logic";
import { useLocation, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { set } from "lodash";
import { GetOrderResponse } from "../../types";

export function SmsPreferenceStatusDialog({ open, setOpen }: {
  open: boolean;
  setOpen: Function;
}) {
  const { globalOrderData, setGlobalOrderData } = useOrderDataContext()
  const { ClosingsText } = useContent()
  const { closingId } = useParams()
  const { search } = useLocation()

  const [consentState, setConsentState] = useState({
    phoneNumber: globalOrderData?.Order.SmsPreferenceStatus?.phoneNumber || '',
    consent: globalOrderData?.Order.SmsPreferenceStatus?.consent ?? true
  });

  const { data, loading, error, refresh } = useUpdateSmsPreferenceStatus({ orderKey: closingId || '', search, consentState})

  useEffect(() => {
    if (error) {
      track('error', 'useUpdateSmsPreferenceStatus', {}, {
        message: 'error saving sms prefs',
        component: 'SmsPreferenceStatusDialog',
        error: JSON.stringify(error),
      })
    }
  }, [error])

  useEffect(() => {
    if (data) {
      setGlobalOrderData((prev: GetOrderResponse) => {
        const globalOrderData = { ...prev }
        set(globalOrderData || {}, `Order.SmsPreferenceStatus.consent`, consentState.consent)
        return globalOrderData
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setGlobalOrderData])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentState((prev => {
      return {
        ...prev,
        consent: event.target.checked,
      }
    }));
  };

  const handleSave = () => {
    refresh()
    setOpen(false)
  };

  return (
    <Dialog
      key="userCancel"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }} variant='h6'>
          SMS Settings
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={consentState.consent} onChange={handleChange} />
            }
            label={`I'd like to receive text message updates about my ${ClosingsText} at ${consentState.phoneNumber}`}
          />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button variant="rounded" onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleSave}>Save</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
