import {
  ContentCopy,
  EmailOutlined,
  MailOutline,
  PeopleOutline,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import List from "@mui/material/List";
import { track } from "../../logic";
import { LogicContact } from "../../types";
import { emailUrl, phoneUrl } from "../../utils";
import { Section, SectionConfig } from "../shared";
import { useState } from "react";

export function ClosingContactsSection({
  collapsible,
  contacts,
  sectionConfig,
}: {
  collapsible?: boolean;
  contacts: LogicContact[];
  sectionConfig: SectionConfig;
}) {
  return (
    <Section
      title={sectionConfig.name}
      icon={<PeopleOutline />}
      collapsible={collapsible}
    >
      <List>
        {contacts.map((contact, index) => (
          <ClosingContactsRow contact={contact} key={index} />
        ))}
      </List>
    </Section>
  );
}

function ClosingContactsRow({ contact }: { contact: any }) {
  const email = emailUrl(contact);
  const phone = phoneUrl(contact);

  const [emailAnchorEl, setEmailAnchorEl] = useState<null | HTMLElement>(null);
  const [phoneAnchorEl, setPhoneAnchorEl] = useState<null | HTMLElement>(null);
  const emailOpen = Boolean(emailAnchorEl);
  const phoneOpen = Boolean(phoneAnchorEl);

  function sendEvent(type: string) {
    track(
      `Click contact`,
      `${contact.Title} | ${type}`,
      {},
      {
        contact_title: contact.Title,
        communication_type: type,
      }
    );
  }

  const handleEmailClose = () => {
    setEmailAnchorEl(null);
  };

  const handlePhoneClose = () => {
    setPhoneAnchorEl(null);
  };

  const handleEmailClick = (event: React.MouseEvent<HTMLElement>) => {
    setEmailAnchorEl(event.currentTarget as HTMLElement);
  };

  const handlePhoneClick = (event: React.MouseEvent<HTMLElement>) => {
    setPhoneAnchorEl(event.currentTarget as HTMLElement);
  };

  return (
    <>
      <Divider />
      <ListItem>
        <ListItemText primary={contact.FullName} secondary={contact.Title} />
        <IconButton
          edge="end"
          aria-label="Open Email Menu"
          sx={{ mr: 1 }}
          onClick={handleEmailClick}
          disabled={!email}
        >
          <EmailOutlined />
        </IconButton>
        <Menu
          anchorEl={emailAnchorEl}
          open={emailOpen}
          onClose={handleEmailClose}
        >
          <MenuItem sx={{ pointerEvents: "none" }}>{contact.Email}</MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(contact.Email);
              handleEmailClose();
            }}
          >
            <ListItemIcon>
              <ContentCopy />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
          </MenuItem>
          <MenuItem
            component="a"
            href={email || " "}
            onClick={() => {
              sendEvent("email");
              handleEmailClose();
            }}
          >
            <ListItemIcon>
              <MailOutline />
            </ListItemIcon>
            <ListItemText>Email</ListItemText>
          </MenuItem>
        </Menu>

        <IconButton
          edge="end"
          aria-label="Open Phone Menu"
          disabled={!phone}
          onClick={handlePhoneClick}
        >
          <PhoneOutlined />
        </IconButton>
        <Menu
          anchorEl={phoneAnchorEl}
          open={phoneOpen}
          onClose={handlePhoneClose}
        >
          <MenuItem sx={{ pointerEvents: "none" }}>{contact.Phone}</MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(contact.Phone);
              handlePhoneClose();
            }}
          >
            <ListItemIcon>
              <ContentCopy />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
          </MenuItem>
          <MenuItem
            component="a"
            href={phone || " "}
            onClick={() => {
              sendEvent("phone");
              handlePhoneClose();
            }}
          >
            <ListItemIcon>
              <PhoneOutlined />
            </ListItemIcon>
            <ListItemText>Call</ListItemText>
          </MenuItem>
        </Menu>
      </ListItem>
    </>
  );
}
