import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { MouseEvent, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

const niceList = [
  'www.closesimple.com',
  'closesimple.us.idkit.com',
]

/**
 * Wrapper component to listen to all click events and if it's on an external
 * link, show an interstitial dialog informing the user they are leaving the
 * site.
 */
export function ExternalLinkCapturer({ children }: {
  children: JSX.Element
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [externalUrl, setExternalUrl] = useState<URL>()

  function onClick(e: MouseEvent) {
    // Handle if the user clicked on a <span> inside of an <a>
    const link = (e.target as HTMLElement)?.closest('a')
    const href = link?.getAttribute('href') ?? ''

    if (link && shouldWarn(href)) {
      e.preventDefault()
      setIsDialogOpen(true)
      setExternalUrl(new URL(href))
    }
  }

  function shouldWarn(href: string) {
    try {
      const url = new URL(href)
      return url.host !== window.location.host && !niceList.includes(url.host) && url.protocol !== 'mailto:'
    } catch { // Wasn't a complete url, e.g. `href="/internal/link"`
      return false
    }
  }

  function navigateToExternalUrl() {
    window.open(externalUrl, '_blank')?.focus()
    closeDialog()
  }

  function closeDialog() {
    setIsDialogOpen(false)
  }

  return (
    <div onClick={onClick}>
      {children}
      <Dialog open={isDialogOpen}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>
          You are leaving the secure portal

          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          Clicking continue will open a new tab on an external website:

          <Box sx={{ margin: 2 }}>
            {externalUrl?.host}
          </Box>

          <DialogActions>
            <Button
              onClick={closeDialog}
              sx={{
                mr: 1
              }}
            >
              Stay here
            </Button>

            <Button
              onClick={navigateToExternalUrl}
              variant='contained'
            >
              Continue
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}
