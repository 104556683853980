import { Download, ExpandMore, FileUpload, FolderOutlined, Refresh } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import List from '@mui/material/List';
import { Box } from '@mui/system';
import { compact, each, find } from 'lodash';
import { Fragment, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { track, useContent, useFileDownload, useGetUploads, useNavigateWithSearchParams } from '../../logic';
import { AttachmentDownload, PortalNotification, PortalNotificationReference, PortalOrderFileUploadType, PortalUpload } from '../../types';
import { formatDate, toTitleCase } from '../../utils';
import { useOrganization } from '../OrganizationProvider';
import { Section } from '../shared/Section';
import { useOrderDataContext } from '../OrderDataProvider';
import { useFileUpload } from './useFileUpload';
import { SectionConfig } from './ClosingPageSectionConfig';
import { sortByDate, sortByName } from '../../utils/sortBy';


const uploadTypeMap: Record<string, string> = {
  'CSForm_': 'Completed Form',
  'CSSignedForm_': 'E-Signed Form',
  'Completion_Cert': 'E-Sign Completion Certificate',
  'esign_policy_consent': 'E-Sign Policy Consent',

  // dropbox sign specific
  'Audit Trail': 'E-Sign Audit Trail',
}

// These are docs created through form and e-signing tasks. The file names are not
// useful to the end user
const typesToIgnoreFileName = [
  'CSForm_',
  'CSSignedForm_',
  'Completion_Cert',
  'esign_policy_consent',
]

function getWiringInstructionsType(code: string, filename: string, WiringInstructionsText: string) {
  const wiringInstructionsUploadTypeMap: Record<string, string> = {
    ...uploadTypeMap,
    'CSForm_': WiringInstructionsText,
    'CSSignedForm_': WiringInstructionsText,
  }

  let displayType = wiringInstructionsUploadTypeMap[code]

  // Esign related docs all have the same type so override some based on filename
  each(wiringInstructionsUploadTypeMap, (val, key) => {
    if (filename.includes(key)) displayType = val
  })

  return displayType
}

// code = DocumentType, filename = DocumentFileName
function getTypeName(code: string, filename: string, types: PortalOrderFileUploadType[], notificationType: string, WiringInstructionsText: string) {
  // type exactly matches a type code defined in admin token.portal_data_file_upload_types
  let labelFromAdminToken = find(types, { Code: code })?.Label

  // filename includes a code defined in admin token.portal_data_file_upload_types
  // Esign related docs share the same type and filenames have UUIDs included so
  // we can't count on an exact match.
  each(types, (type) => {
    if (filename.includes(type.Code)) {
      labelFromAdminToken = type.Label
    }
  })

  // Prioritize token defined labels
  if (labelFromAdminToken) {
    return labelFromAdminToken
  }

  let displayType

  if (notificationType === 'WiringInstructionsTask') {
    displayType = getWiringInstructionsType(code, filename, WiringInstructionsText)
  } else {
    // Default labels we defined for form and esigning docs
    displayType = uploadTypeMap[code]

    // Esign related docs all have the same type so override some based on filename
    each(uploadTypeMap, (val, key) => {
      if (filename.includes(key)) displayType = val
    })
  }



  return displayType || 'Unknown type'
}

function getFileName(upload: PortalUpload) {
  if (typesToIgnoreFileName.includes(upload.DocumentType)) {
    return ''
  } else {
    return upload.OriginalDocumentName
  }
}

export function ClosingDocumentsSection({ collapsible, closingDocuments, CompanyIdOrderIdHash, sectionConfig, notificationRefs }: {
  collapsible?: boolean;
  closingDocuments: AttachmentDownload[] | undefined;
  CompanyIdOrderIdHash: string;
  sectionConfig: SectionConfig;
  notificationRefs: PortalNotificationReference[];
}) {
  const { pathname } = useLocation()
  const navigateWithSearchParams = useNavigateWithSearchParams()
  const org = useOrganization()
  const fileUploadsEnabled = org.FileUploads === 'enabled'
  const { closingId, section, id } = useParams()
  const theme = useTheme() as any
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isClosingDocSection = section === 'closing-documents'
  const isDocUpload = isClosingDocSection && id === 'upload'
  const [open, setOpen] = useState(isDocUpload && !isSmall);

  const { uploadInput, fileUploadBody, fileUploadActions, resetFileUploadHook, isComplete, files } = useFileUpload({})

  useEffect(() => {
    setOpen(isDocUpload && !isSmall)

    // reset the files hook if we navigate away. minimizes potential odd states
    if (!isDocUpload && files.length) {
      resetFileUploadHook()
    }
  }, [isDocUpload, isSmall, setOpen, resetFileUploadHook, files])

  const handleUploadClick = (e: any) => {
    if (isComplete) {
      // reset for a fresh start if we've already completed uploads and click to add more
      resetFileUploadHook()
    }

    if (!isDocUpload) {
      navigateWithSearchParams(`/closings/${closingId}/closing-documents/upload`)
    }

    if (isSmall) {
      e.preventDefault()
    } else {
      setOpen(true)
    }
  }

  const onUploadCancel = (e: any) => {
    e.preventDefault()
    setOpen(false)
    const parentPathname = pathname.split('/').slice(0, -1).join('/')
    navigateWithSearchParams(parentPathname)
  }

  const uploadButton = <Button onClick={handleUploadClick} component="label">Upload{uploadInput}</Button>

  return (
    <>
      {isSmall && isDocUpload ? (
        <>
          <Typography
            variant="h5"
            sx={{ fontWeight: "500", lineHeight: "22px", m: 1 }}
          >
            Upload
          </Typography>
          <Divider />
          {fileUploadBody}
          <br />
          {fileUploadActions}
        </>
      ) : (
        <Section
          title={sectionConfig.name}
          icon={<FolderOutlined />}
          collapsible={collapsible}
          isSelected={isClosingDocSection}
          secondaryAction={fileUploadsEnabled && uploadButton}
        >
          <ClosingDocumentsList
            closingDocuments={closingDocuments}
            CompanyIdOrderIdHash={CompanyIdOrderIdHash}
            fileUploadsEnabled={fileUploadsEnabled}
            handleUploadClick={handleUploadClick}
            notificationRefs={notificationRefs}          />
        </Section>
      )}

      <Dialog
        open={open}
        onClose={onUploadCancel}
        sx={{
          "& .MuiDialog-paper": { borderRadius: "20px", minHeight: "350px" },
        }}
        fullWidth={true}
      >
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "300", fontSize: "32px" }}
        >
          Upload
        </DialogTitle>

        <DialogContent>{fileUploadBody}</DialogContent>

        <DialogActions sx={{ mb: 1 }}>
          {fileUploadActions}
          <Button variant="rounded" onClick={onUploadCancel}>
            {isComplete ? "I'm done" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function ClosingDocumentsList({ closingDocuments, CompanyIdOrderIdHash, fileUploadsEnabled, handleUploadClick, notificationRefs }: {
  closingDocuments: AttachmentDownload[] | undefined;
  CompanyIdOrderIdHash: string;
  fileUploadsEnabled: boolean;
  handleUploadClick: MouseEventHandler;
  notificationRefs: PortalNotificationReference[];
}) {
  let [searchParams] = useSearchParams();
  let { search } = useLocation()
  const component = searchParams.get('component')
  const { globalOrderData } = useOrderDataContext()

  const orderDataRole = globalOrderData?.Role
  const currentRoleParam = searchParams.get("role");

  if (!currentRoleParam) {
    searchParams.set("role", orderDataRole || '');
    search = `?${searchParams.toString()}`
  }

  const { data, error, loading, refresh } = useGetUploads({CompanyIdOrderIdHash, search})
  const uploads = data?.Uploads || []
  const [expanded, setExpanded] = useState(false)
  const uploadsRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if(uploadsRef?.current && component === 'uploads') {
      setExpanded(true)
      uploadsRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }, [component, uploadsRef, loading])

  if (error) {
    track('error', 'useGetUploads', {}, {
      message: JSON.stringify(error),
      component: 'ClosingDocumentsList',
    })
  }

  if (!closingDocuments?.length) {
    closingDocuments = []
  }

  // NOTE: This is maybe kinda gross but I like an excuse to use reduce :simple-smile:
  let filteredDocs:AttachmentDownload[] = closingDocuments.reduce((prev: any, curr: AttachmentDownload) => {
    const currFileName = curr.FileName
    const prevDoc = prev[currFileName]

    if (prevDoc) {
      if ((Date.parse(curr.LastModifiedDateTime) || 0) > (Date.parse(prevDoc.LastModifiedDateTime) || 0)) {
        prev[currFileName] = curr
      }
    } else {
      prev[currFileName] = curr
    }

    return prev

    // TODO: typescript things. Rushing and not wanting to dig into it but I know
    // theres some fancy business to set the reduce prev object literal to...
    // key: limit to strings from AttachmentDownload.FileNames from closingDocuments
    // val: AttachmentDownload
  }, {} as any)

  filteredDocs = Object.values(filteredDocs)

  const sortedDocs:AttachmentDownload[] = filteredDocs.sort((a: AttachmentDownload, b: AttachmentDownload) => {
    const dateSort = sortByDate(a.LastModifiedDateTime, b.LastModifiedDateTime)
    if (dateSort) return dateSort
    return sortByName(a.FileName, b.FileName)
  })

  const sortedUploads:PortalUpload[] = uploads.sort((a: PortalUpload, b: PortalUpload) => {
    const dateSort = sortByDate(a.UploadDate, b.UploadDate)
    if (dateSort) return dateSort
    return sortByName(a.OriginalDocumentName, b.OriginalDocumentName)
  })

  function onClickExpand(e: any) {
    e.preventDefault()
    if (!expanded && !loading) refresh()
    setExpanded(!expanded)
  }

  function onClickRefresh(e: any) {
    e.preventDefault()
    setExpanded(true)
    if (!loading) refresh()
  }

  return (
    <>
      {!closingDocuments.length ?
        <Box sx={{ m: 1 }}>No documents at this time</Box> :
        <List>
          {sortedDocs.map((file, i) => (
            <Fragment key={i}>
              <Divider light={true} sx={{ mr: 1, ml: 1 }}/>
              <FileItem file={file} CompanyIdOrderIdHash={CompanyIdOrderIdHash} />
            </Fragment>
          ))}
        </List>
      }

      {fileUploadsEnabled &&
        <>
          <Divider />
          <Accordion
            expanded={expanded}
            disableGutters={true}
            sx={{ boxShadow: 'none', '&.Mui-disabled': { backgroundColor: 'inherit'} }}
          >
            <AccordionSummary
              ref={uploadsRef}
              onClick={(e: any) => e.preventDefault()}
              expandIcon={<IconButton onClick={onClickExpand}><ExpandMore/></IconButton>}
              sx={{
                scrollMarginTop: {xs: '70px', sm: '0px'},
                '&:hover:not(.Mui-disabled)': { cursor: 'default' },
                '.MuiAccordionSummary-content': { justifyContent: 'space-between'},
              }}
            >
              {!!error ?
                <Typography variant="body2" sx={{ fontWeight: '500', color: 'red' }}>There was a problem getting your uploads and task documents. Try clicking <Refresh fontSize="small" /> to retry.</Typography> :
                <Typography variant="body2" sx={{ fontWeight: '500', color: 'primary.main' }}>Uploads &amp; Task Documents</Typography>
              }
              <AccordionActions sx={{ height: '24px', alignSelf: 'center' }}>
                <IconButton onClick={handleUploadClick} >
                  <FileUpload />
                </IconButton>
                <IconButton onClick={onClickRefresh} >
                  {loading ? <CircularProgress size={24} /> : <Refresh />}
                </IconButton>
              </AccordionActions>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0, pl: 0 }}>
              <List>
                {uploads.length ?
                  sortedUploads.map((upload, i) => {
                    const notificationRef = notificationRefs.find((ref) => ref.NotificationId === upload.NotificationId)
                    return (
                      <Fragment key={i}>
                        <Divider light={true}/>
                        <UploadFileItem
                          upload={upload}
                          CompanyIdOrderIdHash={CompanyIdOrderIdHash}
                          notificationRef={notificationRef}
                          includeNotificationTitle={true}
                        />
                      </Fragment>
                    )
                  }) :
                  <Box sx={{ml: 2}}>None found. Try clicking <Refresh fontSize="small" /> to find recent uploads and task documents.</Box>
                }
              </List>
            </AccordionDetails>
          </Accordion>
        </>
      }
    </>
  )
}

function FileItem({ file, CompanyIdOrderIdHash }: {
  file: AttachmentDownload;
  CompanyIdOrderIdHash: string;
}) {
  const { triggerDownload, isDownloading, setIsDownloading } = useFileDownload({
    CompanyIdOrderIdHash,
    FileName: file.FileName,
    Type: 'document',
  })

  const date = formatDate({dateLike: file.LastModifiedDateTime}) || ''
  const stepTitle = file.StepTitle || ''

  const onClick = function() {
    setIsDownloading(true)
    triggerDownload()
  }

  let displayName

  try {
    // decode can throw and this display only name doesn't seem worth that risk
    // could track down where we encode these instead of this... :(
    displayName = decodeURIComponent(file.FileName)
  } catch (error) {
    displayName = file.FileName
  }

  return (
    <ListItem sx={{ pl: 0, pr: 0 }}>
      <ListItemButton disabled={isDownloading} onClick={onClick} sx={{ pt: 0, pb: 0, minHeight: '66px' }}>
        <ListItemText
          sx={{ wordBreak: 'break-word' }}
          primary={displayName}
          secondary={<> {stepTitle} <br/> {date} </>}
          disableTypography={!stepTitle && !date}
          secondaryTypographyProps={{ variant: 'light', fontSize: 'x-small', ml: 1, }}
        />
        <ListItemIcon sx={{ minWidth: '40px', pl: 2 }}>
          { isDownloading ? <CircularProgress size={25} /> : <Download /> }
        </ListItemIcon>
      </ListItemButton>
    </ListItem >
  )
}

export function UploadFileItem({ upload, CompanyIdOrderIdHash, notificationRef, notification, includeNotificationTitle }: {
  upload: PortalUpload;
  CompanyIdOrderIdHash: string;
  notification?: PortalNotification;
  notificationRef?: PortalNotificationReference;
  includeNotificationTitle?: boolean;
}) {
  const { globalOrderData } = useOrderDataContext()
  const categories = compact(globalOrderData?.Order.FileUploadTypes) || []
  const date = formatDate({dateLike: upload.UploadDate}) || ''
  const { WiringInstructionsText } = useContent();

  const { triggerDownload, isDownloading, setIsDownloading } = useFileDownload({
    CompanyIdOrderIdHash,
    FileName: upload.DocumentFileNameForUpload,
    Type: 'upload',
    DocumentBatchId: upload.DocumentBatchId,
    DocumentFileName: upload.DocumentFileName,
  })


  const onClick = function() {
    setIsDownloading(true)
    triggerDownload()
  }

  function getPrimaryTypography() {
    const notificationTitle = notificationRef?.NotificationTitle || notification?.Title || 'Manual Upload'
    const notificationType = notification?.Type || notificationRef?.Type || ''
    const displayType = getTypeName(upload.DocumentType, upload.DocumentFileName, categories, notificationType, toTitleCase(WiringInstructionsText))

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        {includeNotificationTitle && (
          <Box>
            {notificationTitle}&nbsp;-&nbsp;
          </Box>
        )}
        <Box>
          {displayType}
        </Box>
      </Box>
    )

  }

  function getSecondaryTypography() {
    const filename = getFileName(upload)

    const lines = [
      filename ? filename : null,
      date ? date : null,
    ].filter(Boolean)

    return <>
      {lines.map((line, i) => <Fragment key={i}>{line}<br/></Fragment>)}
    </>
    // return filename ? <> {filename} <br/> {date} </> : <> {date} <br/></>
  }

  return (
    <ListItem>
      <ListItemButton disabled={isDownloading} onClick={onClick} sx={{ pt: 0, pb: 0, pl: 1, pr: 1, minHeight: '66px' }}>
        <ListItemText
          sx={{ wordBreak: 'break-word' }}
          primaryTypographyProps={{ fontSize: 'default' }}
          primary={getPrimaryTypography()}
          secondary={getSecondaryTypography()}
          secondaryTypographyProps={{ variant: 'light', fontSize: 'x-small' }}
        />
        <ListItemIcon sx={{ minWidth: '40px', pl: 2 }}>
          { isDownloading ? <CircularProgress size={25} /> : <Download /> }
        </ListItemIcon>
      </ListItemButton>
    </ListItem >
  )
}
