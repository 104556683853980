import { DoneSharp, InfoOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import List from '@mui/material/List';
import { useState } from 'react';
import cloud from '../../../assets/cloud.svg';
import { PortalStep, StepProgress } from '../../types';
import { formatDate } from '../../utils';

export function ClosingTracker({ stepProgress, sectionConfig }: {
	stepProgress: StepProgress;
  sectionConfig: { name: string };
}) {
  const { Steps: steps, Summary } = stepProgress

  const theme = useTheme() as any
  const color = theme.palette.primary.main

  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const openHelp = () => setIsHelpOpen(true)
  const closeHelp = () => setIsHelpOpen(false)

  return (
    <Card raised sx={{
      p: 2, position: 'relative', borderRadius: '20px', display: 'flex',
      flexDirection: 'column', flexShrink: 0,
    }}>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', height: '60px'
      }}>
        <Box sx={{
          display: 'flex', flexDirection: 'column', pl: 2
        }}>
          <Typography mr="auto" variant="h6" lineHeight="22px">
            {sectionConfig.name}
          </Typography>

          <Typography variant="caption">
            {Summary}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', width: '70px', justifyContent: 'space-between',
        }}>
          <IconButton onClick={openHelp}
            sx={{ mt: '-15px', mr: '-60px',
              '&:hover': {
                backgroundColor: 'initial',
                svg: {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)', borderRadius: '45px', outline: '5px solid', outlineColor: 'rgba(0, 0, 0, 0.04)'
                }
              }
            }}
          >
            <InfoOutlined />
          </IconButton>
          <img src={cloud} alt="cloud" style={{
            width: '50px',
            height: '22px',
            // position: 'absolute',
            // top: '53px',
            // right: '23px'
          }}/>
        </Box>
      </Box>

      <List>
        {steps.map((milestone, i) => (
          <TrackerListItem milestone={milestone} steps={steps} index={i} key={i} />
        ))}
      </List>

      {/* <Timeline>
				{milestones.map(milestone => (
					<TimelineItem key={milestone.Name}>
						<TimelineOppositeContent maxWidth={0} />
						<TimelineSeparator>
							<TimelineDot>
								<TrackerCircle completed={completedMilestones.includes(milestone)} />
							</TimelineDot>
							<TimelineConnector sx={{ minHeight: 1, transform: 'scaleY(7)' }} />
						</TimelineSeparator>
						<TimelineContent>{milestone.Name}</TimelineContent>
					</TimelineItem>
				))}
			</Timeline> */}

      <Box sx={{ position: 'absolute', bottom: '-8px', right: '15px' }}>
        <TreesSvg color={color} />
      </Box>

      <TrackerHelpDialog open={isHelpOpen} onClose={closeHelp} />
    </Card>
  )
}

function TrackerListItem({ milestone, steps, index }: {
  milestone: PortalStep
  steps: PortalStep[]
  index: Number
}) {
  return (
    <ListItem key={milestone.DisplayName}>
      <Box mr={1}>
        <TrackerCircle
          completed={!!milestone.TimeCompleted}
          connector={index !== steps.length-1}
        />
      </Box>
      <ListItemText
        primary={milestone.DisplayName}
        secondary={formatDate({dateLike: milestone.TimeCompleted})}
        secondaryTypographyProps={{ variant: 'light', fontSize: 'x-small' }}
        sx={{
          '& .MuiListItemText-primary': {
            paddingRight: '18px',
          }
        }}
      />
    </ListItem>
  )
}

function TrackerHelpDialog({ open, onClose }: {
	open: boolean;
	onClose: () => void;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
					What am I looking at here?
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Great question! This is a list of all the steps required to complete your transaction. We will update this timeline as your transaction progresses.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>

  )
}

export function TrackerCircle({ completed, connector, cancelled }: {
	completed: boolean;
	connector: boolean;
	cancelled?: boolean;
}) {
  return (
    <>
      <Box
        sx={{
          width: 45,
          minWidth: 45,
          height: 45,
          borderRadius: 45,
          border: '2px solid #EBEDEE',
          bgcolor: completed ? '#EBEDEE' : 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'primary.main',
          position: 'relative',
          // TODO: store in theme
          zIndex: 100,
        }}
      >
        {(completed && !cancelled) ? <DoneSharp /> : ''}
        {cancelled ? <CloseIcon /> : ''}
        {connector ?
          <Divider sx={{
            width: 2,
            height: 60,
            bgcolor: '#EBEDEE',
            position: 'absolute',
            bottom: -60,
            left: '50%',
            // transform: 'rotate(90deg)',
          }} />
          : <></>
        }
      </Box>
    </>
  )
}



function TreesSvg({ color }: { color: string }) {
  return (
    <svg width="36" height="71" viewBox="0 0 36 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.6923 71V38.2308" stroke="#2B0083" strokeWidth="4" strokeMiterlimit="10" />
      <path fill={color} d="M19.3846 40.9615C19.3846 29.1544 23.1058 0 27.6923 0C32.2789 0 36 29.1544 36 40.9615C36 43.1343 35.1248 45.218 33.5668 46.7544C32.0088 48.2907 29.8957 49.1538 27.6923 49.1538C25.489 49.1538 23.3759 48.2907 21.8179 46.7544C20.2599 45.218 19.3846 43.1343 19.3846 40.9615Z" />
      <path d="M8.30774 71V38.2308" stroke="#020006" strokeWidth="4" strokeMiterlimit="10" />
      <path fill={color} d="M0 40.9615C0 29.1544 3.72115 0 8.30769 0C12.8942 0 16.6154 29.1544 16.6154 40.9615C16.6154 43.1343 15.7401 45.218 14.1821 46.7544C12.6241 48.2907 10.511 49.1538 8.30769 49.1538C6.10436 49.1538 3.99126 48.2907 2.43326 46.7544C0.875266 45.218 0 43.1343 0 40.9615Z" />
    </svg>
  )
}
