import { Link, Box, Typography, useTheme } from "@mui/material";
import termsOfUse  from '../../../assets/termsOfUse.pdf'
const privacyPolicyUrl = 'https://www.closesimple.com/privacy-policy'

export function Legalese({ textColor }: {
  textColor?: string;
}) {
  // const [termsOpen, setTermsOpen] = useState(false)
  const theme = useTheme() as any
  const mainColor = theme.palette.primary.main

  return (
    <>
      <Box sx={{
        display: 'flex', flexDirection: 'row', color: textColor || mainColor, fontSize: '14px',
        justifyContent: 'center'
      }}>
        <Link underline="hover" sx={{ color: 'inherit' }} href={privacyPolicyUrl} target="_blank">Privacy Policy</Link>
        <Typography sx={{ml: 1, mr: 1}}>&bull;</Typography>
        <Link underline="hover" sx={{ color: 'inherit'}} href={termsOfUse} target="_blank">Terms of Use</Link>
        {/* IMPROVE: convert PDF to html and include in page instead of opening new tab */}
        {/* <Link sx={{ color: 'inherit'}} onClick={() => setTermsOpen(true)}>Terms of Use</Link> */}
      </Box>

      {/* <Dialog
        open={termsOpen}
        onClose={() => {setTermsOpen(false)}}
        fullWidth={true}
      >
        <DialogContent>
          <Box
            component='img'
            src={termsOfUse}
            sx={{ width: '100%'}}
          >

          </Box>

        </DialogContent>
      </Dialog> */}
    </>
  )
}
